import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setJurorTab } from "../../../../redux/slices/participantsSlice";
import { RootState } from "../../../../redux/types";
import { trialTeamTabButtons } from "../../../../utils/constants";
import { CaseTrialTeamProps, ConfigureTabProps } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";
import { JuryBox, TrialTeamStatistics, TrialTeamTab } from "../../TrialTeam";

const CaseTrialTeam: React.FC<CaseTrialTeamProps> = () => {
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(trialTeamTabButtons[0]);
  const { activeJurorTab } = useSelector((state: RootState) => state.participants);
  const dispatch: Dispatch = useDispatch();

  useEffect(() => {
    if (activeJurorTab) {
      const tab = trialTeamTabButtons.find((t) => t.value === activeJurorTab?.value);
      if (tab) setActiveTab(tab);
    }
  }, [activeJurorTab]);

  const onTabChange = useCallback(
    (tab: ConfigureTabProps) => {
      setActiveTab(tab);
      dispatch(setJurorTab(tab));
    },
    [dispatch]
  );

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case trialTeamTabButtons[0]:
        return <TrialTeamTab />;
      case trialTeamTabButtons[1]:
        return <JuryBox />;
      case trialTeamTabButtons[2]:
        return <TrialTeamStatistics />;
      default:
        return null;
    }
  };

  return (
    <div className='d-flex h-100 flex-column'>
      <TabButtons
        tabs={trialTeamTabButtons}
        activeTab={activeTab || trialTeamTabButtons[0]}
        onTabChange={onTabChange}
      />
      {renderActiveTabContent()}
    </div>
  );
};

export default CaseTrialTeam;
