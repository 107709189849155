import { ColDef } from "ag-grid-community";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchAllAccountsFromServer } from "./helpers";
import { BreadCrumbs, TabButtons } from "../../../components";
import AgGrid from "../../../components/AgGrid";
import NavigationWrapper from "../../../components/NavigationWrapper";
import WrapperComponent from "../../../components/WrapperComponent";
import { RootState } from "../../../redux/types";
import { baseAccountsColumns, providerTabs, TITLES } from "../../../utils/constants";
import useSocket from "../../../utils/hooks/sockets";
import { ConfigureTabProps } from "../../../utils/types";

import "./styles.accounts.scss";

const Accounts = () => {
  const { loading, caseProviderList, participantProviderList } = useSelector((state: RootState) => state.accounts);
  const user = useSelector((state: RootState) => state.users.user);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps>(providerTabs[0]);

  const [pageSize] = useState<number>(1000);
  const fetchedRef = useRef(false);

  const { socketEmit } = useSocket();

  useEffect(() => {
    fetchAllAccountsFromServer(socketEmit, fetchedRef, user?.id || "", 1, pageSize);
  }, [pageSize, socketEmit, user?.id]);

  // Column definitions for the AgGrid
  const AccountsListColumns: ColDef[] = useMemo(() => {
    if (activeTab.value === providerTabs[0].value) {
      return baseAccountsColumns.filter((col) => col.field !== "caseName");
    } else if (activeTab.value === providerTabs[1].value) {
      return baseAccountsColumns.filter((col) => col.field !== "participantFullName");
    }
    return baseAccountsColumns;
  }, [activeTab]);
  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const renderProvidersList = useCallback(
    (tab: ConfigureTabProps) => {
      switch (tab) {
        case providerTabs[0]:
          return (
            <WrapperComponent title={providerTabs[0]?.label}>
              <div className='d-flex' style={{ height: "65vh" }}>
                <AgGrid loading={loading} columnDefs={AccountsListColumns} rowData={participantProviderList} />
              </div>
            </WrapperComponent>
          );
        case providerTabs[1]:
          return (
            <WrapperComponent title={providerTabs[1]?.label}>
              <div className='d-flex' style={{ height: "65vh" }}>
                <AgGrid loading={loading} columnDefs={AccountsListColumns} rowData={caseProviderList} />
              </div>
            </WrapperComponent>
          );
      }
    },
    [AccountsListColumns, caseProviderList, loading, participantProviderList]
  );

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs breadcrumbItems={[{ text: "Provide Query Logs", url: location.pathname }]} />

      <NavigationWrapper title={TITLES.PROVIDER_QUERY_LOGS} goBack={false} customClass='custom-nav-container'>
        <TabButtons tabs={providerTabs} activeTab={activeTab} onTabChange={handleTabChange} />
        {renderProvidersList(activeTab)}
      </NavigationWrapper>
    </div>
  );
};

export default Accounts;
