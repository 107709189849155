import { FormikValues, useFormikContext } from "formik";
import { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { getReactionStatus } from "./helper";
import { RootState } from "../../../redux/store";
import { URLS } from "../../../utils/constants";
import { getProfileImageUrl } from "../../../utils/helpers";
import { ConfigureTabProps, CustomizableFormProps, FormField } from "../../../utils/types";
import DraggableInputField from "../../FormConfigurator/components/DraggableInputField";
import ImageAttachment from "../../ImageAttachment";
import SortIcons from "../../SortIcons";
import "./styles.CustomizableForm.scss";

export const CustomizableForm = ({
  isConfigureMode,
  tab,
  moveField,
  handleRemoveField,
  onMoveDown,
  onMoveUp,
  onFormValuesChange,
  profileImage,
  handleReactions,
  handleImage,
  isReacted = false
}: CustomizableFormProps) => {
  const { participantFieldReaction } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const { values, handleChange, setFieldValue, errors } = useFormikContext<unknown>();
  const isNotCreateCasePage = location.pathname !== "/page/create_case";
  const isSkillsTab = tab.label === "Skills";

  useEffect(() => {
    if (onFormValuesChange) {
      onFormValuesChange(values as FormikValues);
    }
    return () => {};
  }, [values, onFormValuesChange]);

  const onReactionClick = useCallback(
    (tab: ConfigureTabProps, field: FormField, reaction: boolean | null) => {
      const payload = {
        field: { name: field?.name || field?.field || "", label: field?.label },
        tabSection: { name: tab?.value, label: tab?.label },
        isLiked: reaction
      };
      if (reaction === null) {
        handleReactions?.(payload);
      }
      if (reaction) {
        handleReactions?.(payload);
      } else {
        handleReactions?.(payload);
      }
    },
    [handleReactions]
  );

  return (
    <div>
      <div className={`${!isConfigureMode ? "d-flex flex-row flex-wrap" : ""}`}>
        {isSkillsTab ? (
          <div className='col-12 d-flex flex-row align-items-center gap-3'>
            <textarea
              name='skills'
              placeholder='Enter all skills'
              className='w-100 form-control'
              value={(values as { skills?: string }).skills || ""}
              disabled
              rows={6}
            />
          </div>
        ) : (
          tab.content &&
          Array.isArray(tab.content) &&
          tab.content.length > 0 &&
          tab.content.map((field: FormField, index: number) => {
            const isFirstIndex = index === 0 || tab.content[index - 1]?.isFixed || false;
            const isLastIndex = index === tab.content.length - 1;
            const val = field?.name || field?.field || "";
            const fieldValue = (values as FormikValues)[val] ?? field.value;
            const error = (errors as FormikValues)[val] ?? "";

            return (
              <div
                key={`${field.id}-${index}-${tab.content.length}`}
                className={`d-flex flex-row align-items-center gap-1 ${
                  isConfigureMode ? "col-12 w-100" : "col-12 col-sm-12 col-xl-6 col-xxl-6 py-1 pr-2 "
                }`}
              >
                {/* also add check if tab is basic then show image and route / page  is not create_case */}
                {index === 0 && tab.label === "Basic" && isNotCreateCasePage && (
                  <div className='flex-lg-wrap mr-1'>
                    {isConfigureMode ? (
                      <ImageAttachment
                        isEdit
                        onFileChange={handleImage || (() => {})}
                        maxFiles={1}
                        acceptVideos={false}
                        initialImageUrl={profileImage ? `${URLS.IMAGE_BASE_URL}/images/${profileImage}` : ""}
                      />
                    ) : profileImage ? (
                      <div className='customTab-image-container'>
                        <img src={getProfileImageUrl(profileImage)} alt='Profile' className='customTab-profile-image' />
                      </div>
                    ) : (
                      <ImageAttachment onFileChange={handleImage || (() => {})} maxFiles={1} acceptVideos={false} />
                    )}
                  </div>
                )}
                <div className='d-flex w-100 flex-grow-1'>
                  <DraggableInputField
                    field={{ ...field, value: fieldValue, error: error }}
                    values={values as FormikValues}
                    currentIndex={index}
                    moveField={moveField}
                    isInputField={true}
                    isConfigureMode={isConfigureMode}
                    onChange={handleChange}
                    setFieldValue={setFieldValue}
                    onSwitchChange={field.isSwitch ? () => {} : undefined}
                    isReacted={isReacted}
                    onDisLikeClick={() => onReactionClick(tab, field, false)}
                    onLikeClick={() => onReactionClick(tab, field, true)}
                    fieldReactionValue={getReactionStatus(participantFieldReaction, tab, field, selectedCase!)}
                  />
                </div>
                {isConfigureMode && !field.isFixed && (
                  <div className='d-flex flex-row align-items-center pt-2 gap-2'>
                    <span
                      className='cursor-pointer'
                      onClick={() => {
                        handleRemoveField(field);
                      }}
                    >
                      <i className='fa-regular fa-trash-can h-px-17 w-px-17 color-red '></i>
                    </span>
                    <SortIcons
                      onMoveDown={() => {
                        if (onMoveDown) onMoveDown(field);
                      }}
                      onMoveUp={() => {
                        if (onMoveUp && !tab.content[index - 1]?.isFixed) onMoveUp(field);
                      }}
                      isFirstIndex={isFirstIndex}
                      isLastIndex={isLastIndex}
                    />
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};
