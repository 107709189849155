// helper.ts

import { FormikValues } from "formik";
import { toast } from "react-toastify";
import { v4 as uuidv4, validate as isUUID } from "uuid";
import * as Yup from "yup";
import { SOCKET_EVENTS } from "../../../../utils/constants";
import { inputFieldsArray } from "../../../../utils/constants/formFieldsJson";
import { formatPhoneNumber } from "../../../../utils/helpers";
import {
  ConfigureTabProps,
  IFormConfiguratorType,
  SelectedParticipantProps,
  SelectedCaseProps,
  User,
  ParticipantDetails,
  Note,
  BasicInfoField,
  IFormSectionType,
  SocketEmitFunction,
  FormField,
  LegalAndOtherRecordsValues,
  ClientInformationFormValues
} from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const initialValues: ClientInformationFormValues = {
  firstName: "",
  lastName: "",
  nickname: "",
  age: "",
  sex: "",
  gender: "",
  pronouns: "",
  preferredTitle: "",
  occupation: "",
  educationLevel: "",
  address: "",
  addressAdditional: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  children: false,
  childrenText: "",
  childSupport: false,
  childSupportText: "",
  relationshipStatusOption: "",
  relationshipStatus: "",
  additionalInformation: "",
  timeZone: ""
};

export const demographicsValidationSchema = Yup.object().shape({
  ethnicity: Yup.string().optional(),
  economicStatus: Yup.string().optional(),
  occupation: Yup.string().optional(),
  workplace: Yup.string().optional(),
  anyBankruptcy: Yup.boolean(),
  anyBankruptcyText: Yup.string(),
  estimatedHouseholdIncome: Yup.string().optional(),
  estimatedNetWorth: Yup.string().optional(),
  lengthOfResidence: Yup.string().optional(),
  homeOwnershipStatus: Yup.string().optional()
});

export const initialDemographicsValues = {
  ethnicity: "",
  economicStatus: "",
  occupation: "",
  workplace: "",
  anyBankruptcy: false,
  anyBankruptcyText: "",
  estimatedHouseholdIncome: "",
  estimatedNetWorth: "",
  lengthOfResidence: "",
  homeOwnershipStatus: ""
};

export const legalAndOtherRecordsValidationSchema = Yup.object().shape({
  legal_other_records: Yup.string().optional(),
  licenses_or_credentials: Yup.string().optional(),
  academic_records: Yup.string().optional(),
  criminal_records: Yup.string().optional(),
  testimony_transcripts: Yup.string().optional(),
  prior_verdicts_or_settlements: Yup.string().optional(),
  witness_protection_program: Yup.string().optional(),
  no_contact_or_restraining_order: Yup.string().optional(),
  additional_information: Yup.string().notRequired(),
  prior_statements_or_reports: Yup.string().optional(),
  // Switch checks are not required, but we still include them for completeness
  prior_verdicts_or_settlements_check: Yup.boolean(),
  witness_protection_program_check: Yup.boolean(),
  no_contact_or_restraining_order_check: Yup.boolean(),
  prior_statements_or_reports_check: Yup.boolean()
});

export const initialLegalAndOtherRecordsValues: LegalAndOtherRecordsValues = {
  legal_other_records: "",
  licenses_or_credentials: "",
  academic_records: "",
  criminal_records: "",
  testimony_transcripts: "",
  prior_verdicts_or_settlements: "",
  witness_protection_program: "",
  no_contact_or_restraining_order: "",
  additionalInformation: "",
  prior_statements_or_reports: "",
  // Switch checks
  prior_verdicts_or_settlements_check: false,
  witness_protection_program_check: false,
  no_contact_or_restraining_order_check: false,
  prior_statements_or_reports_check: false
};

export const socialMediaValidationSchema = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  facebook: Yup.string().url("Invalid URL"),
  reddit: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  website_blog_urls: Yup.string().url("Invalid URL").optional(),
  linkedin: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL")
});

export const initialSocialMediaValues = {
  twitter: "",
  facebook: "",
  reddit: "",
  tiktok: "",
  website_blog_urls: "",
  linkedin: "",
  instagram: "",
  youtube: ""
};
export const getFormikInitialValues = (fields: FormField[]): Record<string, unknown> => {
  const initialValues: Record<string, unknown> = {};
  fields.forEach((field: FormField) => {
    const val = field.name || field.field || "";
    switch (field.type) {
      case "text":
      case "string":
      case "textarea":
        initialValues[val] = field.value || "";
        break;
      case "number":
        initialValues[val] = field.value || 0;
        break;
      case "date":
        initialValues[val] = field.value || null;
        break;
      case "boolean":
      case "switch":
        initialValues[val] = field.value || false;
        break;
      case "array":
        initialValues[field.name] = field.value || [null];
        break;
      default:
        initialValues[val] = field.value || null;
    }
  });
  return initialValues;
};

const excludedFields = ["_id", "participantId", "__v", "createdAt", "updatedAt", "profileImage"];

export const initializeTabs = (
  data: SelectedParticipantProps,
  selectedConfigurator: IFormConfiguratorType | null,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  setExcludedFields: React.Dispatch<React.SetStateAction<{ field: FormField; section: string }[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const details = data.participantDetails;
  const list: ConfigureTabProps[] = [];
  const excludedContent: { field: FormField; section: string }[] = [];

  const isObjectId = (id: string): boolean => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(id);
  };

  const getBestId = (existingId: string | undefined, newId: string | undefined): string => {
    if (existingId && isObjectId(existingId)) return existingId;
    if (newId && isObjectId(newId)) return newId;
    if (existingId && !isUUID(existingId)) return existingId;
    if (newId && !isUUID(newId)) return newId;
    return existingId || newId || uuidv4();
  };

  const findOrCreateTab = (label: string, value: string, id?: string): ConfigureTabProps => {
    const existingTab = list.find(
      (tab) => tab.label?.toLowerCase() === label?.toLowerCase() && tab.value?.toLowerCase() === value?.toLowerCase()
    );
    if (existingTab) {
      existingTab.id = getBestId(existingTab.id, id);
      return existingTab;
    } else {
      const newTab: ConfigureTabProps = {
        id: id || uuidv4(),
        label,
        value,
        content: []
      };
      list.push(newTab);
      return newTab;
    }
  };

  // Process participant details first
  details?.forEach((section: ParticipantDetails) => {
    const sectionKeys = Object.keys(section).filter((key) => !excludedFields.includes(key));
    sectionKeys.forEach((sectionKey) => {
      if (Array.isArray(section[sectionKey]) && section[sectionKey].length > 0) {
        const content: FormField[] = (section[sectionKey] as FormField[])
          .map((field) => processField(field, sectionKey, excludedFields, excludedContent))
          .filter((field): field is FormField => field !== null);

        const tab = findOrCreateTab(
          (sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)).replace(/_/g, " "),
          sectionKey
        );
        tab.content = [...tab.content, ...content];
      }
    });
  });

  // Process configurator sections and append them to the end of the list
  if (selectedConfigurator && selectedConfigurator?.sections) {
    selectedConfigurator.sections.forEach((section: IFormSectionType) => {
      const tab = findOrCreateTab(section.label, section.name, section._id || section.id);
      tab.content = [...tab.content];
    });
  }
  // Set the updated list of tabs, excluded fields, and the active tab
  setTabs(list);
  setExcludedFields(excludedContent);
  setActiveTab(list[0]);
};
const processField = (
  field: FormField,
  sectionKey?: string,
  excludedFields?: string[],
  excludedContent?: { field: FormField; section: string }[]
): FormField | null => {
  if (excludedFields?.includes(field.name || field?.field || "")) {
    excludedContent?.push({ field: field as FormField, section: sectionKey || "" });
    return null;
  }

  let valueOriginal: string | Date | string[] | null = null;
  if (typeof field.value === "string" || Array.isArray(field.value)) {
    valueOriginal = field.value;
  } else if (typeof field.value === "number") {
    valueOriginal = field.value.toString();
  } else if (typeof field.value === "boolean") {
    valueOriginal = field.value ? "true" : "false";
  } else if (field.value && typeof field.value === "object" && "toISOString" in field.value) {
    valueOriginal = new Date(field.value as Date).toISOString();
  }

  const commonFields: FormField = {
    ...field,
    id: field.id ? String(field.id) : undefined,
    label: field.label || "",
    isMandatory: field.isMandatory ?? false,
    name: field.name || field?.field || "",
    placeholder: field.placeholder || "",
    isFixed: field.isFixed ?? true,
    field: field.field || field.name || "",
    originalValue: valueOriginal,
    type: field.type || "text",
    value: valueOriginal,
    isCustom: field.isCustom ?? true,
    isSwitch: field.isSwitch ?? false,
    disabled: field.disabled ?? false,
    dropdownList: field.dropdownList || []
  };

  // Handle special cases for alternativeNames and skills
  const val = field.name || field?.field;
  if (val === "alternativeNames" || val === "skills") {
    return {
      ...commonFields,
      type: "textArea",
      value: processSpecialField(commonFields),
      rows: 10,
      disabled: true
    };
  }

  return commonFields;
};

const processSpecialField = (field: FormField): string | string[] => {
  const value = field.value;
  if (Array.isArray(value)) {
    if (isNameObjectArray(value)) {
      // TypeScript now knows value is an array of objects with firstName, middleName, and lastName
      return value.map((item: { firstName?: string; middleName?: string; lastName?: string }) =>
        `${item.firstName || ""} ${item.middleName || ""} ${item.lastName || ""}`.trim()
      );
    } else if (isStringArray(value)) {
      // TypeScript now knows value is a string array
      return value;
    } else if (Array.isArray(value[0])) {
      // TypeScript now knows value is a nested array of strings
      return (value as string[][]).map((subArray: string[]) => subArray.join(", "));
    }
  }

  return "";
};

const isStringArray = (value: unknown): value is string[] =>
  Array.isArray(value) && value.every((item) => typeof item === "string");

const isNameObjectArray = (value: unknown): value is { firstName?: string; middleName?: string; lastName?: string }[] =>
  Array.isArray(value) &&
  value.every(
    (item) =>
      typeof item === "object" && item !== null && ("firstName" in item || "middleName" in item || "lastName" in item)
  );

export const initializeInputFields = (
  configurator: IFormConfiguratorType | null,
  data: SelectedParticipantProps | null,
  tabs: ConfigureTabProps[],
  setInputFieldsData: React.Dispatch<React.SetStateAction<FormField[]>>
): void => {
  let fields: FormField[] = [];

  // Process configurator data if available
  if (configurator && configurator.sections) {
    fields = configurator.sections.flatMap((section) =>
      section.content.map((field) => ({
        ...field,
        value: field.value || "",
        section: section.name
      }))
    );
  }

  // Process participant details if available
  if (data?.participantDetails && Array.isArray(data.participantDetails)) {
    const details: ParticipantDetails[] = data.participantDetails;
    const participantFields: FormField[] = details.flatMap((detail: ParticipantDetails) =>
      Object.entries(detail).flatMap(([sectionName, sectionFields]) => {
        if (Array.isArray(sectionFields)) {
          return sectionFields.map(
            (field): FormField => ({
              ...field,
              name: field.field || field.name,
              value: field.value || "",
              section: sectionName,
              type: field.type || "text",
              isMandatory: field.isMandatory || false,
              isCustom: field.isCustom || false,
              label: field.label || field.name || ""
            })
          );
        }
        return [];
      })
    );

    // Merge configurator fields and participant fields
    fields = [...fields, ...participantFields];
  }

  // Remove duplicate fields, prioritizing configurator fields
  const uniqueFields = Array.from(new Map(fields.map((field) => [field.name || field?.field, field])).values());

  // Filter out fields that are already in tabs
  const usedFieldNames = new Set([
    ...tabs.flatMap((tab) => tab?.content?.map((field) => field.name || field?.field)),
    ...excludedFields
  ]);
  const mergedArray = [...uniqueFields, ...inputFieldsArray];
  const filteredFields = mergedArray.filter((field) => !usedFieldNames.has(field.name || field?.field));
  const formattedFields = filteredFields.map((field): FormField => {
    let formattedValue: string | string[] | Date | null;

    if (typeof field.value === "string") {
      const val: string = field.name || field?.field || "";

      formattedValue =
        val && val.length > 0 && val.toLowerCase().includes("phone") ? formatPhoneNumber(field.value) : field.value;
    } else if (field.value instanceof Date) {
      formattedValue = field.value;
    } else if (Array.isArray(field.value)) {
      formattedValue = field.value;
    } else if (typeof field.value === "number") {
      formattedValue = field.value.toString();
    } else if (typeof field.value === "boolean") {
      formattedValue = field.value.toString();
    } else {
      // Handle undefined, null, or any other unexpected types
      formattedValue = null;
    }

    return {
      ...field,
      value: formattedValue,
      isMandatory: field.isMandatory ?? false,
      type: field.type || "text"
      // Add any other required properties with default values
    } as FormField;
  });
  setInputFieldsData(formattedFields);
};

type FieldValue = string | number | boolean | Date | null;
type SchemaShape = Record<string, Yup.AnySchema<FieldValue>>;

export const getFormikValidationSchema = (fields: FormField[]): Yup.ObjectSchema<Record<string, FieldValue>> => {
  const schemaFields: SchemaShape = {};
  fields.forEach((field: FormField) => {
    let schema: Yup.AnySchema = Yup.mixed();
    switch (field.type) {
      case "text":
      case "textarea":
        schema = Yup.string();
        break;
      case "number":
        schema = Yup.number();
        break;
      case "date":
        schema = Yup.date();
        break;
      case "boolean":
      case "switch":
        schema = Yup.boolean();
        break;
    }
    if (field.isMandatory) {
      schema = schema.required(`${field.label} is required`);
    }
    const val = field.name || field.field || field.label;
    schemaFields[val] = schema;
  });
  return Yup.object().shape(schemaFields);
};

export const buildValues = (tabs: ConfigureTabProps[]): Record<string, unknown> =>
  tabs
    .flatMap((item) => {
      // Ensure each field in content is treated as FormField
      return (item.content as FormField[]).map(
        (field) =>
          ({
            ...field,
            isMandatory: field.isMandatory ?? false // Provide a default value for isMandatory
          }) as FormField
      );
    })
    .reduce<Record<string, unknown>>((acc, node) => {
      acc[node.name] = node.value;
      return acc;
    }, {});

export const buildParticipantDetails = (tabs: ConfigureTabProps[]): Record<string, FormField[]> =>
  tabs.reduce((acc: Record<string, FormField[]>, item) => {
    acc[item.value] =
      item?.content?.map((node) => {
        let value: string | Date | string[] | null = null;

        if (typeof node.value === "string" || node.value instanceof Date || Array.isArray(node.value)) {
          value = node.value;
        } else if (typeof node.value === "number" || typeof node.value === "boolean") {
          value = String(node.value);
        }

        return {
          ...node,
          value,
          isMandatory: node.isMandatory ?? false // Ensure isMandatory is always boolean
        } as FormField;
      }) || {};
    return acc;
  }, {});

export const fetchParticipantDetails = (
  updatedParticipant: SelectedParticipantProps,
  mergedDetails: Record<string, FormField[]>
): ParticipantDetails => {
  const participantDetails: ParticipantDetails = {
    participantId: updatedParticipant?.participantId ?? "",
    ...Object.entries(mergedDetails).reduce(
      (acc, [key, value]) => {
        return {
          ...acc,
          [key]: value as BasicInfoField[]
        };
      },
      {} as Omit<ParticipantDetails, "participantId">
    )
  };
  return participantDetails;
};

export const getFilteredNotes = (
  selectedParticipant: SelectedParticipantProps,
  selectedCase: SelectedCaseProps
): Note[] => {
  const caseParticipant = selectedParticipant?.caseParticipants?.[0];
  return (
    selectedParticipant?.notes
      ?.filter((note) => {
        if (caseParticipant?.participantType === "candidate") {
          return (
            note.createdAt != null &&
            note.caseId === selectedCase?.id &&
            note?.caseParticipantId?.participantType === "candidate"
          );
        } else if (
          caseParticipant?.participantType !== "candidate" &&
          (caseParticipant?.participantType === "witness" || caseParticipant?.participantType === "expert")
        ) {
          return note.createdAt != null && note?.caseParticipantId?.participantType !== "candidate";
        } else {
          return note.createdAt != null;
        }
      })
      .sort((a, b) => {
        const dateA = new Date(a.createdAt!).getTime();
        const dateB = new Date(b.createdAt!).getTime();
        return dateB - dateA;
      }) || []
  );
};

export const createParticipantDetailsPayload = (
  selectedParticipant: SelectedParticipantProps,
  user: User,
  caseId?: string,
  participantId?: string
) => {
  return {
    ...selectedParticipant,
    caseId: caseId || "",
    participantId: participantId || "",
    userId: user.id || "",
    companyId: user.company?.id || "",
    eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS,
    type: SOCKET_EVENTS.PARTICIPANT_AND_DETAILS_UPDATED,
    requestId: uuidv4()
  };
};

export const emitUpdateParticipantDetails = (socketEmit: SocketEmitFunction, payload: SelectedParticipantProps) => {
  try {
    if (socketEmit) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        payload,
        ({ success, message }: { success: boolean; message: string }) => {
          if (success) toast.success(message);
        },
        ({ success, message }: { success: boolean; message: string }) => {
          if (!success) toast.error(message);
        }
      );
    }
  } catch (error) {
    if (error instanceof Error) console.log("emitSaveParticipantDetails error: ", error?.message);
  }
};

export const mergeParticipantValues = (values: FormikValues, participant: SelectedParticipantProps) => {
  return Object.keys(values).reduce(
    (updatedParticipant, key) => {
      if (key in updatedParticipant) {
        updatedParticipant[key] = values[key];
      }
      return updatedParticipant;
    },
    { ...participant }
  );
};

export const formatPhoneValue = (value: string): string => {
  return value.toLowerCase().includes("phone") ? formatPhoneNumber(value) : value;
};

export const moveField = (
  tabs: ConfigureTabProps[],
  activeTabValue: string,
  dragIndex: number,
  hoverIndex: number,
  field: FormField
): ConfigureTabProps[] => {
  return tabs.map((tab) => {
    if (tab.value === activeTabValue) {
      const content = Array.isArray(tab.content) ? tab.content : [];

      const newContent = [...content];
      newContent.splice(dragIndex, 1);
      newContent.splice(hoverIndex, 0, field);

      // Ensure all fields have isMandatory as a boolean
      const processedContent = newContent.map((item) => ({
        ...item,
        isMandatory: item.isMandatory ?? false
      }));

      // Type assertion to satisfy TypeScript
      return {
        ...tab,
        content: processedContent as typeof tab.content
      };
    }
    return tab;
  });
};

export const removeField = (
  tabs: ConfigureTabProps[],
  activeTabValue: string,
  fieldName: string
): ConfigureTabProps[] => {
  return tabs.map((tab) => {
    if (tab.value === activeTabValue) {
      return {
        ...tab,
        content: tab?.content
          ?.filter((field) => (field.name || field?.field) !== fieldName)
          .map((field) => ({
            ...field,
            type: field.type || "text", // Provide a default value for type
            isMandatory: field.isMandatory ?? false // Ensure isMandatory is always boolean
          })) as FormField[] // Type assertion
      };
    }
    return tab;
  });
};

export const addFieldToInputFieldsData = (inputFieldsData: FormField[], newField: FormField): FormField[] => {
  return [...inputFieldsData, newField];
};

export const filterInputFieldsData = (inputFieldsData: FormField[], updatedTab: ConfigureTabProps): FormField[] => {
  const tabFieldNames = new Set(updatedTab?.content?.map((field) => field.name || field?.field));
  return inputFieldsData.filter((field) => !tabFieldNames.has(field.name || field?.field));
};
