import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { selectSelectedCaseId } from "./casesSlices";
import { MEMBER_TYPES, trialTeamTabButtons } from "../../utils/constants";
import {
  ConfigureTabProps,
  FieldReaction,
  Participant,
  ParticipantsState,
  SelectedParticipantProps
} from "../../utils/types";
import { RootState } from "../store";

const initialState: ParticipantsState = {
  loading: true,
  trialLoading: true,
  candidates: [],
  witnesses: [],
  participants: [],
  experts: [],
  trialTeam: [],
  selectedParticipant: null,
  activeParticipantTab: "candidates",
  activeJurorTab: trialTeamTabButtons[0],
  participantFieldReaction: [],
  likedFieldsCount: 0,
  dislikedFieldsCount: 0,
  likedFieldLabels: [],
  dislikedFieldLabels: [],
  dismissedJurors: [],
  jurors: [],
  alternativeJurors: []
};

export const fetchParticipantsWithCaseCheck = createAsyncThunk<void, Participant[], { state: RootState }>(
  "participants/fetchWithCaseCheck",
  async (participants, { dispatch, getState }) => {
    const selectedCaseId = selectSelectedCaseId(getState());
    const candidatesList: SelectedParticipantProps[] = [];
    const expertsList: SelectedParticipantProps[] = [];
    const witnessList: SelectedParticipantProps[] = [];

    participants.forEach((participant: Participant) => {
      const participantCaseId = participant.caseParticipants?.[0]?.caseId;

      if (participantCaseId === selectedCaseId) {
        if (participant.caseParticipants?.[0]?.participantType === MEMBER_TYPES[0]) {
          witnessList.push(participant as SelectedParticipantProps);
        }
        if (participant.caseParticipants?.[0]?.participantType === MEMBER_TYPES[1]) {
          expertsList.push(participant as SelectedParticipantProps);
        }
        if (participant.caseParticipants?.[0]?.participantType === MEMBER_TYPES[2]) {
          candidatesList.push(participant as SelectedParticipantProps);
        }
      }
    });

    // Dispatch the respective actions to update the state
    if (candidatesList.length > 0) {
      dispatch(fetchCandidates(candidatesList));
    } else {
      dispatch(fetchCandidates([]));
    }
    if (expertsList.length > 0) {
      dispatch(fetchExperts(expertsList));
    } else {
      dispatch(fetchExperts([]));
    }
    if (witnessList.length > 0) {
      dispatch(fetchWitnesses(witnessList));
    } else {
      dispatch(fetchWitnesses([]));
    }
  }
);

const participantsSlice = createSlice({
  name: "participants",
  initialState,
  reducers: {
    fetchWitnesses: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.witnesses = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;

          const participantFieldReactions =
            updatedParticipant &&
            updatedParticipant?.caseParticipants &&
            updatedParticipant?.caseParticipants?.length > 0
              ? (updatedParticipant.caseParticipants[0]?.fieldReactions ?? [])
              : [];
          state.participantFieldReaction = participantFieldReactions;
          if (participantFieldReactions && participantFieldReactions.length > 0) {
            participantFieldReactions.forEach((reaction: FieldReaction) => {
              const label = reaction.fieldLabel || reaction.fieldName;

              if (reaction.isLiked === true) {
                state.likedFieldsCount++;
                state.likedFieldLabels.push(label as string);
              } else if (reaction.isLiked === false) {
                state.dislikedFieldsCount++;
                state.dislikedFieldLabels.push(label as string);
              }
            });
          }
        }
      }
      state.loading = false;
    },
    fetchExperts: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.experts = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;

          const participantFieldReactions =
            updatedParticipant &&
            updatedParticipant?.caseParticipants &&
            updatedParticipant?.caseParticipants?.length > 0
              ? (updatedParticipant.caseParticipants[0]?.fieldReactions ?? [])
              : [];
          state.participantFieldReaction = participantFieldReactions;
          if (participantFieldReactions && participantFieldReactions.length > 0) {
            participantFieldReactions.forEach((reaction: FieldReaction) => {
              const label = reaction.fieldLabel || reaction.fieldName;

              if (reaction.isLiked === true) {
                state.likedFieldsCount++;
                state.likedFieldLabels.push(label as string);
              } else if (reaction.isLiked === false) {
                state.dislikedFieldsCount++;
                state.dislikedFieldLabels.push(label as string);
              }
            });
          }
        }
      }
      state.loading = false;
    },
    fetchCandidates: (state, action: PayloadAction<SelectedParticipantProps[]>) => {
      state.candidates = action.payload;
      if (state.selectedParticipant) {
        const updatedParticipant = action.payload.find((p) => p.id === state.selectedParticipant?.id);
        if (updatedParticipant) {
          state.selectedParticipant = updatedParticipant;

          const participantFieldReactions =
            updatedParticipant &&
            updatedParticipant?.caseParticipants &&
            updatedParticipant?.caseParticipants?.length > 0
              ? (updatedParticipant.caseParticipants[0]?.fieldReactions ?? [])
              : [];
          state.participantFieldReaction = participantFieldReactions;
          if (participantFieldReactions && participantFieldReactions.length > 0) {
            participantFieldReactions.forEach((reaction: FieldReaction) => {
              const label = reaction.fieldLabel || reaction.fieldName;

              if (reaction.isLiked === true) {
                state.likedFieldsCount++;
                state.likedFieldLabels.push(label as string);
              } else if (reaction.isLiked === false) {
                state.dislikedFieldsCount++;
                state.dislikedFieldLabels.push(label as string);
              }
            });
          }
        }
      }
      state.loading = false;
    },
    addCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = [{ ...action.payload }, ...state.candidates];
    },
    deleteCandidate: (state, action: PayloadAction<Participant>) => {
      state.candidates = state.candidates.filter((candidate) => candidate.email !== action.payload.email);
    },
    addWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = [{ ...action.payload }, ...state.witnesses];
    },
    deleteWitness: (state, action: PayloadAction<Participant>) => {
      state.witnesses = state.witnesses.filter((witness) => witness.firstName !== action.payload.firstName);
    },
    addParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = [{ ...action.payload }, ...state.participants];
    },
    deleteParticipant: (state, action: PayloadAction<Participant>) => {
      state.participants = state.participants.filter(
        (participant) => participant.firstName !== action.payload.firstName
      );
    },
    addExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = [{ ...action.payload }, ...state.experts];
    },
    deleteExpert: (state, action: PayloadAction<Participant>) => {
      state.experts = state.experts.filter((expert) => expert.firstName !== action.payload.firstName);
    },
    setSelectedParticipant: (
      state,
      action: PayloadAction<Partial<SelectedParticipantProps> | Partial<Participant> | null>
    ) => {
      if (action.payload === null) {
        state.selectedParticipant = null;
      } else {
        state.selectedParticipant = {
          ...state.selectedParticipant,
          ...action.payload
        } as SelectedParticipantProps;
      }
      const participantFieldReaction =
        action.payload && action.payload?.caseParticipants && action.payload?.caseParticipants?.length > 0
          ? (action.payload.caseParticipants[0]?.fieldReactions ?? [])
          : [];
      state.participantFieldReaction = participantFieldReaction;
      if (participantFieldReaction && participantFieldReaction.length > 0) {
        participantFieldReaction.forEach((reaction: FieldReaction) => {
          const label = reaction.fieldLabel || reaction.fieldName;

          if (reaction.isLiked === true) {
            state.likedFieldsCount++;
            state.likedFieldLabels.push(label as string);
          } else if (reaction.isLiked === false) {
            state.dislikedFieldsCount++;
            state.dislikedFieldLabels.push(label as string);
          }
        });
      }
    },
    clearSelectedParticipant: (state) => {
      state.selectedParticipant = null;
      state.participantFieldReaction = [];
    },

    setTrialTeamParticipants: (state, action: PayloadAction<Participant[] | []>) => {
      state.trialTeam = action.payload;
      state.trialLoading = false;
    },
    setJuryBox: (state, action: PayloadAction<Participant[] | []>) => {
      state.jurors = action.payload;
    },
    setDismissedJurors: (state, action: PayloadAction<Participant[] | []>) => {
      state.dismissedJurors = action.payload;
    },
    setAlternativeJurors: (state, action: PayloadAction<Participant[] | []>) => {
      state.alternativeJurors = action.payload;
    },
    setParticipantTab: (state, action: PayloadAction<string>) => {
      state.loading = true;
      state.activeParticipantTab = action.payload;
      state.loading = false;
    },
    setJurorTab: (state, action: PayloadAction<ConfigureTabProps>) => {
      state.trialLoading = true;
      state.activeJurorTab = action.payload;
      state.trialLoading = false;
    }
  }
});

const participantsPersistConfig = {
  key: "participants",
  storage,
  whitelist: ["selectedParticipant", "trialTeam", "participantFieldReaction"] // only selectedParticipant will be persisted
};
const persistedParticipantsReducer = persistReducer(participantsPersistConfig, participantsSlice.reducer);
// Exporting the actions
export const {
  addCandidate,
  deleteCandidate,
  addWitness,
  deleteWitness,
  addParticipant,
  deleteParticipant,
  addExpert,
  deleteExpert,
  fetchCandidates,
  fetchExperts,
  fetchWitnesses,
  setSelectedParticipant,
  clearSelectedParticipant,
  setTrialTeamParticipants,
  setParticipantTab,
  setDismissedJurors,
  setAlternativeJurors,
  setJuryBox,
  setJurorTab
} = participantsSlice.actions;

// Exporting the reducer
export default persistedParticipantsReducer;
