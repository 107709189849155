/**
 * @file formConfiguratorSlice.ts
 * @description Redux slice for managing form configurator state
 */

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FormConfiguratorState, IFormConfiguratorType, IFormFieldType, IFormSectionType } from "../../utils/types";

/**
 * @constant
 * @description Initial state for the form configurator slice
 */
const initialState: FormConfiguratorState = {
  configurator: [],
  selectedConfigurator: null,
  totalConfigurator: 0,
  isLoading: true,
  error: null
};

/**
 * @constant
 * @description Redux slice for form configurator management
 */
const formConfiguratorSlice = createSlice({
  name: "formConfigurator",
  initialState,
  reducers: {
    /**
     * @action setLoading
     * @description Sets the loading state
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<boolean>} action - Action containing the loading state
     */
    setLoading: (state: FormConfiguratorState, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },

    /**
     * @action setError
     * @description Sets the error message
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<string | null>} action - Action containing the error message
     */
    setError: (state: FormConfiguratorState, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },

    /**
     * @action setAllConfigurator
     * @description Sets all form configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormConfigurator[]>} action - Action containing all configurator
     */
    setAllConfigurator: (state: FormConfiguratorState, action: PayloadAction<IFormConfiguratorType[]>) => {
      state.configurator = action.payload;
      state.isLoading = false;
      state.error = null;
      if (state.selectedConfigurator) {
        const modifiedConfigurator = action.payload.find((c) => c._id === state.selectedConfigurator?._id);

        if (modifiedConfigurator) {
          state.selectedConfigurator = modifiedConfigurator;
        }
      } else {
        state.selectedConfigurator = action?.payload?.[0];
      }
    },

    /**
     * @action setSelectedConfigurator
     * @description Sets the selected form configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormConfigurator | null>} action - Action containing the selected configurator
     */
    setSelectedConfigurator: (state: FormConfiguratorState, action: PayloadAction<IFormConfiguratorType | null>) => {
      state.selectedConfigurator = action.payload;
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action setTotalConfigurator
     * @description Sets the total number of configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<number>} action - Action containing the total number of configurator
     */
    setTotalConfigurator: (state: FormConfiguratorState, action: PayloadAction<number>) => {
      state.totalConfigurator = action.payload;
    },

    /**
     * @action addConfigurator
     * @description Adds a new configurator to the state
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormConfigurator>} action - Action containing the new configurator
     */
    addConfigurator: (state: FormConfiguratorState, action: PayloadAction<IFormConfiguratorType>) => {
      state.configurator.push(action.payload);
      state.totalConfigurator += 1;
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action updateConfigurator
     * @description Updates an existing configurator in the state
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormConfiguratorType>} action - Action containing the updated configurator
     */
    updateConfigurator: (state: FormConfiguratorState, action: PayloadAction<IFormConfiguratorType>) => {
      const index: number = state.configurator.findIndex((c: IFormConfiguratorType) => c.id === action.payload.id);
      if (index !== -1) {
        state.configurator[index] = action.payload;
        if (state.selectedConfigurator?.id === action.payload.id) {
          state.selectedConfigurator = action.payload;
        }
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action deleteConfigurator
     * @description Deletes a configurator from the state
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<string>} action - Action containing the ID of the configurator to delete
     */
    deleteConfigurator: (state: FormConfiguratorState, action: PayloadAction<string>) => {
      state.configurator = state.configurator.filter((c: IFormConfiguratorType) => c.id !== action.payload);
      state.totalConfigurator -= 1;
      if (state.selectedConfigurator?.id === action.payload) {
        state.selectedConfigurator = null;
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action addSection
     * @description Adds a new section to the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormSectionType>} action - Action containing the new section
     */
    addSection: (state: FormConfiguratorState, action: PayloadAction<IFormSectionType>) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        state.selectedConfigurator.sections.push(action.payload);
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action updateSection
     * @description Updates an existing section in the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<IFormSectionType>} action - Action containing the updated section
     */
    updateSection: (state: FormConfiguratorState, action: PayloadAction<IFormSectionType>) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        const index: number = state.selectedConfigurator.sections.findIndex(
          (s: IFormSectionType) => s.id === action.payload.id
        );
        if (index !== -1) {
          state.selectedConfigurator.sections[index] = action.payload;
        }
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action deleteSection
     * @description Deletes a section from the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<string>} action - Action containing the ID of the section to delete
     */
    deleteSection: (state: FormConfiguratorState, action: PayloadAction<string>) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        state.selectedConfigurator.sections = state.selectedConfigurator.sections.filter(
          (s: IFormSectionType) => s.id !== action.payload
        );
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action addField
     * @description Adds a new field to a section in the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<{ sectionId: string; field: IFormFieldType }>} action - Action containing the section ID and the new field
     */
    addField: (state: FormConfiguratorState, action: PayloadAction<{ sectionId: string; field: IFormFieldType }>) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        const section: IFormSectionType | undefined = state.selectedConfigurator.sections.find(
          (s: IFormSectionType) => s.id === action.payload.sectionId
        );
        if (section) {
          section.content.push(action.payload.field);
        }
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action updateField
     * @description Updates an existing field in a section of the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<{ sectionId: string; field: IFormFieldType }>} action - Action containing the section ID and the updated field
     */
    updateField: (
      state: FormConfiguratorState,
      action: PayloadAction<{ sectionId: string; field: IFormFieldType }>
    ) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        const section: IFormSectionType | undefined = state.selectedConfigurator.sections.find(
          (s: IFormSectionType) => s.id === action.payload.sectionId
        );
        if (section) {
          const index: number = section.content.findIndex((f: IFormFieldType) => f.id === action.payload.field.id);
          if (index !== -1) {
            section.content[index] = action.payload.field;
          }
        }
      }
      state.isLoading = false;
      state.error = null;
    },

    /**
     * @action deleteField
     * @description Deletes a field from a section in the selected configurator
     * @param {FormConfiguratorState} state - Current state
     * @param {PayloadAction<{ sectionId: string; fieldId: string }>} action - Action containing the section ID and field ID to delete
     */
    deleteField: (state: FormConfiguratorState, action: PayloadAction<{ sectionId: string; fieldId: string }>) => {
      if (state.selectedConfigurator && Array.isArray(state.selectedConfigurator.sections)) {
        const section: IFormSectionType | undefined = state.selectedConfigurator.sections.find(
          (s: IFormSectionType) => s.id === action.payload.sectionId
        );
        if (section) {
          section.content = section.content.filter((f: IFormFieldType) => f.id !== action.payload.fieldId);
        }
      }
      state.isLoading = false;
      state.error = null;
    }
  }
});

export const {
  setLoading,
  setError,
  setAllConfigurator,
  setSelectedConfigurator,
  setTotalConfigurator,
  addConfigurator,
  updateConfigurator,
  deleteConfigurator,
  addSection,
  updateSection,
  deleteSection,
  addField,
  updateField,
  deleteField
} = formConfiguratorSlice.actions;

export default formConfiguratorSlice.reducer;
