import { Dispatch } from "@reduxjs/toolkit";
import { CellClickedEvent, ColDef, ICellRendererParams } from "ag-grid-community";
import { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { handleNewCompanySave, fetchAllCompaniesFromServer, handleDeleteCompany, handleUpdateCompany } from "./helpers";
import { ActionButton, BreadCrumbs } from "../../../components";
import { AddFieldValueModal } from "../../../components/AddFieldValueModal";
import AgGrid from "../../../components/AgGrid";
import ActionsRenderer from "../../../components/AgGrid/ActionGridRenderer";
import NavigationWrapper from "../../../components/NavigationWrapper";
import WrapperComponent from "../../../components/WrapperComponent";
import { setSelectedCompany } from "../../../redux/slices/companiesSlices";
import { setCompanyUser } from "../../../redux/slices/companyUsersSlice";
import { AppDispatch, RootState } from "../../../redux/types";
import {
  ACTION_COLUMN_STYLE,
  baseCompaniesNameColumns,
  BUTTON_TITLE,
  FORM_PLACEHOLDERS
} from "../../../utils/constants";
import routes from "../../../utils/constants/routes";
import useSocket from "../../../utils/hooks/sockets";
import { Company } from "../../../utils/types";

const Companies = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const { companiesList, loading } = useSelector((state: RootState) => state.companies);
  const user = useSelector((state: RootState) => state.users.user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompanyState] = useState<{ companyId: string; name: string } | null>(null);
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const fetchedRef = useRef(false);
  const { socketEmit } = useSocket();

  useEffect(() => {
    fetchAllCompaniesFromServer(socketEmit, fetchedRef, user?.id || "");
  }, [socketEmit, user?.id]);

  // Toggle modal visibility
  const toggleModal = useCallback(() => {
    setIsUpdate(false);
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const handleNewLabel = useCallback(
    (label: string, actionType: string) => {
      if (actionType === "update" && selectedCompany) {
        handleUpdateCompany(label, selectedCompany?.companyId, socketEmit, toggleModal, user?.id || "");
      } else {
        handleNewCompanySave(label, socketEmit, toggleModal, user?.id || "");
      }
    },
    [selectedCompany, socketEmit, toggleModal, user?.id]
  );

  // Edit company placeholder
  const handleView = useCallback(
    (data: {
      companyName: string;
      companyId: string;
      id: string;
      firstName: string;
      lastName: string;
      email: string;
    }) => {
      navigate(routes.COMPANY_DETAILS, { state: { companyData: data } });
      dispatch(
        setCompanyUser({
          ...data,
          userId: data
        })
      );
      dispatch(setSelectedCompany({ companyName: data.companyName, id: data.id }));
    },
    [dispatch, navigate]
  );

  const handleEdit = useCallback(
    (data: { companyName: string; id: string }) => {
      setIsUpdate(true);
      setSelectedCompanyState({ companyId: data.id, name: data.companyName });
      dispatch(setSelectedCompany({ companyName: data.companyName, id: data.id } as Company));
      setIsModalOpen(true); // Open the modal
    },
    [dispatch]
  );

  // Column definitions for the AgGrid
  const CompaniesListColumns: ColDef[] = useMemo(
    () => [
      {
        ...baseCompaniesNameColumns[0], // First column
        cellClass: "clickable-cell", // Add class for clickable cells
        onCellClicked: (params: CellClickedEvent) => handleView(params.data)
      },
      ...baseCompaniesNameColumns.slice(1).map((col) => ({
        ...col
      })),
      {
        ...ACTION_COLUMN_STYLE,
        cellRendererFramework: (params: ICellRendererParams) => (
          <ActionsRenderer
            data={params.data}
            onDelete={() => handleDeleteCompany(socketEmit, params.data, user?.id || "")}
            onEdit={() => handleEdit(params.data)}
            onView={() => handleView(params.data)}
          />
        )
      }
    ],
    [handleEdit, handleView, socketEmit, user?.id]
  );

  const formattedCompaniesList = useMemo(
    () =>
      companiesList.map((company) => ({
        ...company,
        createdAt: company.createdAt
          ? new Date(company.createdAt).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit"
            })
          : "N/A"
      })),
    [companiesList]
  );

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs breadcrumbItems={[{ text: "Companies", url: location.pathname }]} />

      <NavigationWrapper
        title={BUTTON_TITLE.COMPANIES}
        goBack={false}
        headerChild={
          <ActionButton title={BUTTON_TITLE.ADD_NEW_COMPANY} onClick={toggleModal} className='save-button bg-blue' />
        }
      >
        <WrapperComponent>
          <div className='d-flex' style={{ height: "65vh" }}>
            <AgGrid loading={loading} columnDefs={CompaniesListColumns} rowData={formattedCompaniesList} />
          </div>
        </WrapperComponent>

        {isModalOpen && (
          <AddFieldValueModal
            fieldLabel={isUpdate ? BUTTON_TITLE.EDIT_COMPANY : BUTTON_TITLE.ADD_NEW_COMPANY}
            fieldPlaceholder={FORM_PLACEHOLDERS.CompanyName}
            isOpen={isModalOpen}
            toggle={toggleModal}
            onSubmit={handleNewLabel}
            initialValue={selectedCompany?.name}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          />
        )}
      </NavigationWrapper>
    </div>
  );
};

export default Companies;
