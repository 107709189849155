import React, { useCallback, useMemo } from "react";
import { RangeSliderProps } from "../../utils/types";
import "./RangeSlider.scss";

const RangeSlider: React.FC<RangeSliderProps> = ({ value = 0, min, max, trackColor, onClick, isDisabled = false }) => {
  const getEmoji = useCallback(() => {
    if (value <= 20)
      return (
        <span
          className={`icon-red ${isDisabled ? "disabled" : "cursor-pointer"}`}
          onClick={!isDisabled ? onClick : undefined}
        >
          <i className='fa-light fa-face-frown'></i>
        </span>
      );

    if (value < 50)
      return (
        <span
          className={`icon-orange ${isDisabled ? "disabled" : "cursor-pointer"}`}
          onClick={!isDisabled ? onClick : undefined}
        >
          <i className='fa-light fa-face-meh'></i>
        </span>
      );
    return (
      <span
        className={`icon-green ${isDisabled ? "disabled" : "cursor-pointer"}`}
        onClick={!isDisabled ? onClick : undefined}
      >
        <i className='fa-light fa-face-smile'></i>
      </span>
    );
  }, [value, onClick, isDisabled]);

  const fetchTrackColor = useMemo(() => {
    if (value <= 20) return "var(--color-btn-danger)"; // Red for low values
    if (value < 50) return "var(--color-orange)"; // Orange for mid values
    return "var(--color-primary-light)";
  }, [value]);

  const trackStyle = useMemo(
    () => ({
      background: `linear-gradient(90deg, ${trackColor || fetchTrackColor} ${value}%, #c7c9c9 ${value}%)`,
      borderRadius: "10px"
    }),
    [trackColor, fetchTrackColor, value]
  );

  return (
    <div
      className={`range-slider-container ${isDisabled ? "disabled" : "cursor-pointer"}`}
      onClick={!isDisabled ? onClick : undefined}
    >
      <span
        className={`emoji-icon ${isDisabled ? "disabled" : "cursor-pointer"}`}
        onClick={!isDisabled ? onClick : undefined}
      >
        {getEmoji()}
      </span>
      <input
        className={`range-slider-input ${isDisabled ? "disabled" : "cursor-pointer"}`}
        style={trackStyle}
        type='range'
        value={value}
        min={min}
        max={max}
        disabled
      />
    </div>
  );
};

export default RangeSlider;
