import { AxiosInstance, PSL } from "@digitalworkflow/psl_web";
import { URLS } from "./constants";

let axiosInstance: AxiosInstance | null | undefined;

/**
 * Initializes the Axios instance with optional token authentication.
 *
 * @param {string} [token] - Optional token to set for authorization.
 * @returns {Promise<void>} - Resolves when the Axios instance is initialized, rejects on error.
 * @throws {Error} - Throws an error if initialization fails.
 */
async function initializeAxiosInstance(token?: string): Promise<void> {
  try {
    // If a token is provided, set it in the Axios instance
    if (token && token.length > 0) {
      PSL.SetServerAuthKey(`Bearer ${token}`);
    } else {
      PSL.SetServerAuthKey("");
    }

    if (PSL.GetEnvironment().includes("local")) {
      axiosInstance = await PSL.GetAxiosInstance(URLS.PRIMARY_URL);
    } else {
      // Get the Axios instance with the specified base URL
      axiosInstance = await PSL.GetAxiosInstance("anerva");
      console.log("Axios instance", axiosInstance);
    }
    // Check if the Axios instance was successfully created
    if (!axiosInstance) {
      throw new Error("Failed to initialize Axios instance");
    }

    console.log("Axios instance initialized successfully.");
  } catch (error) {
    console.error("Error initializing Axios instance:", error instanceof Error ? error.message : error);
    throw new Error("Failed to initialize Axios instance");
  }
}

async function getInstance(token?: string): Promise<AxiosInstance> {
  await initializeAxiosInstance(token);
  if (!axiosInstance) {
    throw new Error("Axios instance initialization failed.");
  }

  return axiosInstance;
}

export default getInstance;
