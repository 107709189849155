import { Dispatch } from "@reduxjs/toolkit";
import { ColDef, DndSourceOnRowDragParams, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import AlternativeJurors from "./AlternativeJurors";
import DismissedJurors from "./DismissedJurors";
import JurorCard from "./JurorCard";
import { setSelectedParticipant } from "../../../../redux/slices/participantsSlice";
import { AppDispatch, RootState } from "../../../../redux/types";
import { ACTION_COLUMN_STYLE, baseJurorColumns, SOCKET_EVENTS, STRINGS } from "../../../../utils/constants";
import routes from "../../../../utils/constants/routes";
import useSocket from "../../../../utils/hooks/sockets";
import { Participant, PersonInfo } from "../../../../utils/types";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import ConfirmationModal from "../../../ConfirmationModal";
import PersonInfoModal from "../../../PersonInfoModal";
import StrikeJurorModal from "../../../StrikeJurorModal";
import { kickOutParticipant } from "../helper";
import CollapsibleButton from "./CollapsibleButton";

import "./styles.juryBox.scss";

const JuryBox: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const [openStrikeModal, setOpenStrikeModal] = useState<boolean>(false);
  const [jurorsData, setJurorsData] = useState<Participant[]>([]);
  const [selectedJurors, setSelectedJurors] = useState<Participant[]>([]);
  const [dismissedJurorsList, setDismissedJurors] = useState<Participant[]>([]);
  const [alternativeJurorsList, setAlternativeJurors] = useState<Participant[]>([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedJuror, setSelectedJuror] = useState<Participant | null>(null);
  const [isTableCollapsed, setIsTableCollapsed] = useState(false);

  const { trialTeam, dismissedJurors, jurors, alternativeJurors, trialLoading } = useSelector(
    (state: RootState) => state.participants
  );
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const user = useSelector((state: RootState) => state.users.user);
  const { socket, socketEmit } = useSocket();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (trialTeam) setJurorsData(trialTeam);
    if (dismissedJurors) setDismissedJurors(dismissedJurors);
    if (jurors) setSelectedJurors(jurors);
    if (alternativeJurors) setAlternativeJurors(alternativeJurors);
  }, [dismissedJurors, jurors, trialTeam, alternativeJurors]);

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const updateAllJurors = useCallback(
    (jurors: Participant[]) => {
      const payload = {
        type: SOCKET_EVENTS.MODIFY_ALL_CASE_TRIAL_PARTICIPANT,
        eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
        userId: user?.id,
        caseId: selectedCase?.id,
        jurors: jurors.map((juror) => ({
          userId: typeof juror.userId === "string" ? juror.userId : juror.userId?._id || "",
          caseParticipantId: juror.caseParticipants?.[0]?.id,
          seatNumber: juror.seatNumber,
          isDismissed: juror.isDismissed,
          isAlternative: juror.isAlternative
        })),
        requestId: uuidv4()
      };

      if (socket) {
        socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (data) => {
          console.log("Jurors Updated", data);
        });
      }
    },
    [selectedCase?.id, socket, socketEmit, user?.id]
  );

  const reorderJurors = useCallback((jurors: Participant[]) => {
    return jurors.map((juror, index) => ({
      ...juror,
      seatNumber: index + 1,
      position: `Juror ${index + 1}`,
      order: index + 1
    }));
  }, []);

  const addJurorToBox = useCallback(
    (juror: Participant) => {
      if (selectedJurors.length >= 12) {
        toast.error("You can only add up to 12 jurors in the jury box.");
        return;
      }

      setSelectedJurors((prev) => {
        if (!prev.some((j) => j.id === juror.id)) {
          const newJurors = [...prev, { ...juror, isDismissed: false, isAlternative: false }];
          const reorderedJurors = reorderJurors(newJurors);
          updateAllJurors(reorderedJurors);
          return reorderedJurors;
        }
        return prev;
      });

      setJurorsData((prev) => prev.filter((j) => j.id !== juror.id));
      setDismissedJurors((prev) => prev.filter((j) => j.id !== juror.id));
      setAlternativeJurors((prev) => prev.filter((j) => j.id !== juror.id));
    },
    [selectedJurors, updateAllJurors, reorderJurors]
  );

  const removeJurorFromBox = useCallback(
    (juror: Participant) => {
      setSelectedJurors((prev) => {
        const updatedJurors = prev.filter((j) => j.id !== juror.id);
        const reorderedJurors = reorderJurors(updatedJurors);
        updateAllJurors(reorderedJurors);
        return reorderedJurors;
      });

      const dismissedJuror = {
        ...juror,
        seatNumber: undefined,
        position: undefined,
        order: undefined,
        isDismissed: true,
        isAlternative: false
      };
      setDismissedJurors((prev) => [...prev, dismissedJuror]);
      updateAllJurors([dismissedJuror]);
    },
    [updateAllJurors, reorderJurors]
  );

  const handleDropOnCard = useCallback(
    (draggedJuror: Participant, targetJuror: Participant) => {
      setSelectedJurors((prev) => {
        const draggedIndex = prev.findIndex((j) => j.id === draggedJuror.id);
        const targetIndex = prev.findIndex((j) => j.id === targetJuror.id);

        if (draggedIndex === -1 || targetIndex === -1) return prev;

        const updatedJurors = [...prev];
        const [removed] = updatedJurors.splice(draggedIndex, 1);
        updatedJurors.splice(targetIndex, 0, removed);

        const reorderedJurors = reorderJurors(updatedJurors);
        updateAllJurors(reorderedJurors);
        return reorderedJurors;
      });
    },
    [updateAllJurors, reorderJurors]
  );

  const handleDismissJuror = useCallback(
    (juror: Participant) => {
      setSelectedJurors((prev) => {
        const updatedJurors = prev.filter((j) => j.id !== juror.id);
        const reorderedJurors = reorderJurors(updatedJurors);
        updateAllJurors(reorderedJurors);
        return reorderedJurors;
      });

      const dismissedJuror = {
        ...juror,
        seatNumber: undefined,
        position: undefined,
        order: undefined,
        isDismissed: true,
        isAlternative: false
      };
      setDismissedJurors((prev) => [...prev, dismissedJuror]);
      updateAllJurors([dismissedJuror]);
    },
    [updateAllJurors, reorderJurors]
  );

  const handleRestoreJuror = useCallback(
    (juror: Participant) => {
      setDismissedJurors((prev) => prev.filter((j) => j.id !== juror.id));
      const restoredJuror = {
        ...juror,
        seatNumber: undefined,
        position: undefined,
        order: undefined,
        isDismissed: false,
        isAlternative: false
      };
      setJurorsData((prev) => [...prev, restoredJuror]);
      updateAllJurors([restoredJuror]);
    },
    [updateAllJurors]
  );

  const handleAddAlternativeJuror = useCallback(
    (juror: Participant) => {
      if (alternativeJurorsList.length < 3) {
        const newAlternativeJuror = {
          ...juror,
          isAlternative: true,
          isDismissed: false,
          seatNumber: undefined,
          position: undefined,
          order: undefined
        };
        setAlternativeJurors((prev) => [...prev, newAlternativeJuror]);
        setJurorsData((prev) => prev.filter((j) => j.id !== juror.id));
        setSelectedJurors((prev) => prev.filter((j) => j.id !== juror.id));
        updateAllJurors([newAlternativeJuror]);
      } else {
        toast.error("You can only add up to 3 jurors in the alternative juror.");
      }
    },
    [alternativeJurorsList, updateAllJurors]
  );

  const handleRemoveAlternativeJuror = useCallback(
    (juror: Participant) => {
      setAlternativeJurors((prev) => prev.filter((j) => j.id !== juror.id));
      const restoredJuror = { ...juror, isAlternative: false, isDismissed: false, seatNumber: undefined };
      setJurorsData((prev) => [...prev, restoredJuror]);
      updateAllJurors([restoredJuror]);
    },
    [updateAllJurors]
  );

  const onRowDrag = (params: DndSourceOnRowDragParams<Participant>) => {
    const rowNode = params.rowNode;
    const e = params.dragEvent;
    const jsonObject = {
      ...(rowNode.data ?? {}),
      grid: "JURY_BOX",
      operation: "Drag Juror",
      jurorId: rowNode.data?.id,
      selected: rowNode.isSelected(),
      source: "AG_GRID"
    };
    const jsonData = JSON.stringify(jsonObject);
    e?.dataTransfer?.setData("application/json", jsonData);
    e?.dataTransfer?.setData("text/plain", jsonData);
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "JUROR",
    drop: (item: Participant & { source: string }) => {
      if (item.source === "DISMISSED") {
        // handleRestoreJuror(item);
      } else {
        addJurorToBox(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }));

  const JurorColumns: ColDef[] = [
    {
      headerName: "",
      rowDrag: true,
      dndSource: true,
      dndSourceOnRowDrag: onRowDrag
    },
    ...baseJurorColumns,
    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          kickOut={true}
          editable={false}
          add={true}
          deletable={false}
          data={params.data}
          onKickOut={() => {
            setSelectedJuror(params.data);
            setIsConfirmationModalOpen(true);
          }}
          onEdit={() => {
            addJurorToBox(params.data);
          }}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            handleViewPersonInfo(params.data);
            navigate(routes.PARTICIPANT_DETAILS, {
              state: { participantData: params.data, isTrial: true, from: location.pathname }
            });
          }}
        />
      )
    }
  ];

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent, dropTarget: "juryBox" | "alternative") => {
      console.log("Drop target:", dropTarget);
      event.preventDefault();

      try {
        let jurorData;
        const transferData = event.dataTransfer.getData("application/json");

        if (transferData) {
          try {
            jurorData = JSON.parse(transferData);
          } catch (parseError) {
            console.error("Error parsing JSON:", parseError);
            jurorData = transferData; // Use the raw data if it's not valid JSON
          }
        } else {
          // If no JSON data, try to get the data directly
          jurorData = event.dataTransfer.getData("text");
        }

        if (!jurorData) {
          console.error("No valid data found in the drop event");
          return;
        }

        if (typeof jurorData === "string") {
          try {
            jurorData = JSON.parse(jurorData);
          } catch (parseError) {
            console.error("Error parsing jurorData string:", parseError);
            return;
          }
        }

        if (dropTarget === "juryBox") {
          addJurorToBox(jurorData);
        } else if (dropTarget === "alternative") {
          handleAddAlternativeJuror(jurorData);
        }
      } catch (error) {
        console.error("Error in onDrop function:", error);
      }
    },
    [addJurorToBox, handleAddAlternativeJuror]
  );
  const onConfirmKickOut = useCallback(() => {
    if (selectedJuror) {
      kickOutParticipant(selectedJuror, user?.id, socketEmit);
      setIsConfirmationModalOpen(false);
    }
  }, [selectedJuror, socketEmit, user?.id]);

  const onView = useCallback(
    (data: Participant) => {
      dispatch(setSelectedParticipant(data));
      navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
        state: { participantData: data, isTrial: true, from: location.pathname }
      });
    },
    [dispatch, navigate]
  );

  const onEdit = useCallback(
    (data: Participant) => {
      dispatch(setSelectedParticipant(data));
      navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
        state: { participantData: data, isTrial: true, from: location.pathname }
      });
    },
    [dispatch, navigate]
  );

  const [, dropTable] = useDrop(() => ({
    accept: "JUROR",
    drop: (item: Participant & { source: string }) => {
      if (item.source === "DISMISSED" || item.source === "ALTERNATIVE") {
        if (item.source === "DISMISSED") {
          handleRestoreJuror(item);
        } else if (item.source === "ALTERNATIVE") {
          handleRemoveAlternativeJuror(item);
        }
      }
    },
    collect: (monitor) => ({
      isOverTable: !!monitor.isOver()
    })
  }));

  return (
    <div className='jury-box-tab'>
      <div className='header'>
        <div className='w-60'>
          <h5>{STRINGS.JURY_BOX}</h5>
          <p>{STRINGS.DRAG_AND_DROP_PARA}</p>
        </div>
        <div className={`collapsible-container ${isTableCollapsed ? "d-flex" : "d-none"}`}>
          <CollapsibleButton isCollapsed={isTableCollapsed} onClick={() => setIsTableCollapsed(!isTableCollapsed)} />
        </div>
      </div>
      <div className='main-content-con'>
        <div
          className={`left-section ${isTableCollapsed ? "" : "table-expanded"} ${isOver ? "active-drop" : ""}`}
          ref={drop}
          onDragOver={onDragOver}
          onDrop={(event) => onDrop(event, "juryBox")}
        >
          <div className='juror-cards'>
            {selectedJurors && selectedJurors.length > 0 ? (
              <>
                {/* Top Row: 7-12 */}
                <div className='juror-row top-row'>
                  {[...selectedJurors]
                    .filter((i) => i?.seatNumber !== undefined && i.seatNumber > 6)
                    .map((juror) => (
                      <JurorCard
                        key={juror.id}
                        juror={juror}
                        onRemove={() => removeJurorFromBox(juror)}
                        onDropOnCard={handleDropOnCard}
                        onDismiss={handleDismissJuror}
                        onEdit={() => onEdit(juror)}
                        onView={() => onView(juror)}
                        inJuryBox={true}
                      />
                    ))}
                </div>
                {/* Bottom Row: 1-6 */}
                <div className='juror-row bottom-row'>
                  {[...selectedJurors]
                    .filter((i) => i?.seatNumber !== undefined && i.seatNumber <= 6)
                    .map((juror) => (
                      <JurorCard
                        key={juror.id}
                        juror={juror}
                        onRemove={() => removeJurorFromBox(juror)}
                        onDropOnCard={handleDropOnCard}
                        onDismiss={handleDismissJuror}
                        onEdit={() => onEdit(juror)}
                        onView={() => onView(juror)}
                        inJuryBox={true}
                      />
                    ))}
                </div>
              </>
            ) : (
              <div className='drag-hint'>Drag a trial team here to add them as a juror.</div>
            )}
          </div>
        </div>
        <div
          className={`table-con p-2 ${isTableCollapsed ? "collapsed" : "expanded"}`}
          ref={dropTable}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(event) => onDrop(event, "juryBox")}
        >
          <div className='collapse-button'>
            <CollapsibleButton
              hide
              isCollapsed={isTableCollapsed}
              onClick={() => setIsTableCollapsed(!isTableCollapsed)}
            />
          </div>
          <div className='jury-selection-header'>
            <h5>{STRINGS.JURY_SELECTION}</h5>
            <p>{STRINGS.DRAG_AND_DROP_PARA}</p>
          </div>
          <div className='ag-grid'>
            <AgGrid
              columnDefs={JurorColumns}
              rowData={jurorsData}
              rowDragManaged={true}
              suppressMoveWhenRowDragging={true}
              loading={trialLoading}
            />
          </div>
        </div>
      </div>
      <div className='additional-sections'>
        <AlternativeJurors
          alternativeJurors={alternativeJurorsList}
          onAddAlternativeJuror={handleAddAlternativeJuror}
          onRemoveAlternativeJuror={handleRemoveAlternativeJuror}
          onEdit={addJurorToBox}
          onView={onView}
          onDrop={onDrop}
        />
        <DismissedJurors
          dismissedJurors={dismissedJurorsList}
          onRestoreJuror={handleRestoreJuror}
          onDismissJuror={handleDismissJuror}
          onEdit={onEdit}
          onView={onView}
        />
      </div>
      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
      {openStrikeModal && (
        <StrikeJurorModal
          isOpen={openStrikeModal}
          onClose={() => setOpenStrikeModal(false)}
          onSubmit={() => setOpenStrikeModal(false)}
        />
      )}
      {isConfirmationModalOpen && (
        <ConfirmationModal
          onCancel={() => setIsConfirmationModalOpen(false)}
          message={STRINGS.ARE_YOUR_SURE_KICK_OUT}
          onConfirm={onConfirmKickOut}
        />
      )}
    </div>
  );
};

export default JuryBox;
