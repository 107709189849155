import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TabItem } from "../../utils/types";

const initialTabs: TabItem[] = [
  { id: "caseDetails", label: "Case Details", status: "current" },
  { id: "participants", label: "Participants", status: "upcoming" },
  { id: "trialTeam", label: "Trial Team", status: "upcoming" },
  { id: "caseEvidence", label: "Evidence Documents", status: "upcoming" },
  { id: "documents", label: "Court Documents", status: "upcoming" }
];

const initialState = {
  tabs: initialTabs
};

const manageCaseTabSlice = createSlice({
  name: "manageCaseTab",
  initialState,
  reducers: {
    setActiveTab: (state, action: PayloadAction<string>) => {
      const newActiveTab = action.payload;
      const updatedTabs = state.tabs.map((tab, index) => {
        const activeIndex = state.tabs.findIndex((t) => t.id === newActiveTab);

        if (tab.id === newActiveTab) {
          return { ...tab, status: "current" };
        } else if (index < activeIndex) {
          return { ...tab, status: "completed" };
        } else {
          return { ...tab, status: "upcoming" };
        }
      });

      state.tabs = updatedTabs as TabItem[];
    }
  }
});

export const { setActiveTab } = manageCaseTabSlice.actions;
export default manageCaseTabSlice.reducer;
