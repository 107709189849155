import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { ActionButton, BreadCrumbs } from "../../components";
import RangeSlider from "../../components/AgGrid/GridRangeSlider";
import InfoCard from "../../components/InfoCard";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import NavigationWrapper from "../../components/NavigationWrapper";
import EditCandidate from "../../components/Pages/ManageCase/CustomizableEditCandidate";
import StrikeJurorModal from "../../components/StrikeJurorModal";
import { updateJuror } from "../../redux/slices/jurorSlice";
import { setActiveTab } from "../../redux/slices/manageCaseTabSlice";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { RootState } from "../../redux/store";
import { AppDispatch } from "../../redux/types";
import { BUTTON_TITLE, CASE_TABS, editTitleMap } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import { calculateNewRate } from "../../utils/helpers";
import useSocket from "../../utils/hooks/sockets";
import { SelectedReasons } from "../../utils/types";

import "./styles.scss";

const CustomizableEditParticipant: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [openStrikeModal, setOpenStrikeModal] = useState(false);
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const { socket, socketEmit } = useSocket();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "Edit Participant:";
  const isTrial: boolean = !!location.state?.isTrial;

  const addMemberAsTeam = useCallback(() => {
    if (isTrial) return;
    const payload = {
      userId:
        typeof selectedParticipant?.userId === "string"
          ? selectedParticipant.userId
          : selectedParticipant?.userId?._id || "",
      type: SOCKET_EVENTS.ADD_CASE_TRIAL_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
      caseParticipantId: selectedParticipant?.caseParticipants[0]?.id,
      requestId: uuidv4()
    };
    if (socket) {
      socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (data) => {
        if (data.requestId === payload.requestId) {
          navigate(routes.MANAGE_CASE);
          dispatch(setActiveMenu(routes.MANAGE_CASE || ""));
          dispatch(setActiveTab(CASE_TABS.TRIAL_TEAM));
        }
      });
    }
  }, [isTrial, selectedParticipant, socket, socketEmit, navigate, dispatch]);

  const handleOpenStrikeModal = useCallback(() => {
    setOpenStrikeModal(true);
  }, []);

  const handleStrikeSubmit = useCallback(
    (data: { selectedReasons: SelectedReasons }) => {
      if (selectedParticipant) {
        const currentRate = selectedParticipant?.jurorRate || "0";
        const newRate = calculateNewRate(currentRate, data.selectedReasons);
        const updatedJurors = { ...selectedParticipant, jurorRate: `${newRate}%` };
        dispatch(updateJuror(updatedJurors));
        setOpenStrikeModal(false);
      }
    },
    [dispatch, selectedParticipant]
  );

  const isDisabled = useMemo(
    () =>
      selectedParticipant?.participantDetails &&
      Array.isArray(selectedParticipant?.participantDetails) &&
      selectedParticipant?.participantDetails?.length === 0,
    [selectedParticipant?.participantDetails]
  );

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Participants", url: location.pathname }
        ]}
      />
      <NavigationWrapper
        title={`${title} ${selectedParticipant?.firstName || ""} ${selectedParticipant?.lastName || ""}`}
        headerChild={
          <div className={`header-child-container ${isDisabled ? "disabled" : "cursor-pointer"}`}>
            <div
              className={`button-rating bg-white  ${isDisabled ? "disabled" : "cursor-pointer"}`}
              onClick={() => (isDisabled ? undefined : handleOpenStrikeModal())}
            >
              <RangeSlider
                value={(selectedParticipant?.caseParticipants?.[0]?.participantRatings?.[0]?.rating ?? 0) / 0.05}
                min={0}
                max={100}
                onClick={() => (isDisabled ? undefined : handleOpenStrikeModal())}
                isDisabled={isDisabled}
              />
            </div>
            {!isTrial ? (
              <ActionButton
                className={"bg-primary-light"}
                onClick={addMemberAsTeam}
                title={BUTTON_TITLE.ADD_AS_A_TEAM}
                icon={<i className='fa-regular fa-user-plus'></i>}
                disabled={isDisabled || isTrial}
              />
            ) : null}
            {/* <ActionButton
                className={"bg-blue-light"}
                onClick={() => {
                  setShowModal(true);
                }}
                title={BUTTON_TITLE.AI_PROFILE_GENERATE}
                icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
              /> */}
          </div>
        }
      >
        {!isDisabled ? (
          <div className='scrollable-container'>
            <EditCandidate data={{ personData: selectedParticipant }} />
          </div>
        ) : (
          <InfoCard />
        )}
        <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
      </NavigationWrapper>
      <StrikeJurorModal
        isOpen={openStrikeModal}
        onClose={() => {
          setOpenStrikeModal(false);
        }}
        onSubmit={handleStrikeSubmit}
        currentParticipant={selectedParticipant}
      />
    </div>
  );
};

export default CustomizableEditParticipant;
