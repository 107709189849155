import React, { useCallback, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Modal, ModalHeader } from "reactstrap";
import { ImageAttachmentProps } from "../../utils/types";
import ActionButton from "../ActionButton";

import "./styles.scss";

const MAX_IMAGE_SIZE = 5 * 1024 * 1024; // 5MB for images
const MAX_VIDEO_SIZE = 15 * 1024 * 1024; // 15MB for videos

const ImageAttachment: React.FC<ImageAttachmentProps> = ({
  label = "",
  initialImageUrl,
  onFileChange,
  multiple = false,
  maxFiles = 5,
  placeholder = "Add a Photo",
  acceptImages = true,
  acceptVideos = true,
  isEdit = false
}) => {
  const [files, setFiles] = useState<File[]>([]);
  const [previewUrls, setPreviewUrls] = useState<string[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(null);

  useEffect(() => {
    if (initialImageUrl && initialImageUrl.length > 0) {
      setPreviewUrls([initialImageUrl]);
    }
  }, [initialImageUrl]);

  const handleFileChange = useCallback(
    (acceptedFiles: File[], mode?: string) => {
      const validFiles: File[] = [];
      const errorMessages: string[] = [];

      acceptedFiles.forEach((file) => {
        if (file.type.startsWith("image/")) {
          if (acceptImages && file.size <= MAX_IMAGE_SIZE) {
            validFiles.push(file);
          } else if (!acceptImages) {
            errorMessages.push("Image files are not allowed.");
          } else {
            errorMessages.push(`Image ${file.name} exceeds the 4MB size limit.`);
          }
        } else if (file.type.startsWith("video/")) {
          if (acceptVideos && file.size <= MAX_VIDEO_SIZE) {
            validFiles.push(file);
          } else if (!acceptVideos) {
            errorMessages.push("Video files are not allowed.");
          } else {
            errorMessages.push(`Video ${file.name} exceeds the 15MB size limit.`);
          }
        }
      });

      // Check if adding new files exceeds the maxFiles limit
      if (files.length + validFiles.length > maxFiles && !isEditMode) {
        setErrorMessage(`You can only upload up to ${maxFiles} files.`);
        validFiles.splice(maxFiles - files.length); // Slice the files to only include up to maxFiles
      }

      if (validFiles.length > 0) {
        const newPreviewUrls = validFiles.map((file) => URL.createObjectURL(file));

        if (selectedFileIndex !== null && selectedFileIndex >= 0) {
          const updatedFiles = [...files];
          const updatedPreviewUrls = [...previewUrls];

          updatedFiles[selectedFileIndex] = validFiles[0];
          updatedPreviewUrls[selectedFileIndex] = newPreviewUrls[0];

          setFiles(updatedFiles);
          setPreviewUrls(updatedPreviewUrls);
          onFileChange(updatedFiles[0], mode);
        } else if (multiple) {
          setFiles((prevFiles) => [...prevFiles, ...validFiles]);
          setPreviewUrls((prevUrls) => [...prevUrls, ...newPreviewUrls]);
          onFileChange([...files, ...validFiles], mode);
        } else {
          console.log("Valid files", validFiles);
          setFiles(validFiles);
          setPreviewUrls(newPreviewUrls);
          onFileChange(validFiles[0], mode);
        }

        setSelectedFileIndex(null); // Reset after replacement
        setIsEditMode(false); // Close the edit mode
      }

      if (errorMessages.length > 0) {
        setErrorMessage(errorMessages.join(" "));
      } else {
        setErrorMessage(null); // Clear any previous errors
      }
    },
    [files, maxFiles, isEditMode, acceptImages, acceptVideos, selectedFileIndex, multiple, previewUrls, onFileChange]
  );

  const handleRemoveFile = useCallback(
    (index: number) => {
      const newFiles = files.filter((_, i) => i !== index);
      const newPreviewUrls = previewUrls.filter((_, i) => i !== index);

      setFiles(newFiles);
      setPreviewUrls(newPreviewUrls);
      setErrorMessage(null); // Clear any error message

      if (multiple) {
        onFileChange(newFiles.length > 0 ? newFiles : null, "remove"); // Pass updated array of files or null
      } else {
        onFileChange(newFiles[0] || null, "remove"); // Pass single file or null
      }
      setIsEditMode(false);
    },
    [files, previewUrls, onFileChange, multiple]
  );

  const togglePreview = useCallback(() => setIsPreview(!isPreview), [isPreview]);

  const fetchAcceptTypes = useCallback(() => {
    const accept: Record<string, string[]> = {};

    if (acceptImages) {
      accept["image/*"] = [".jpeg", ".png", ".jpg"];
    }
    if (acceptVideos) {
      accept["video/*"] = [".mp4", ".avi", ".mov"];
    }

    return Object.keys(accept).length > 0 ? accept : undefined;
  }, [acceptImages, acceptVideos]);

  const acceptTypes = fetchAcceptTypes();

  return (
    <div className='profile-picture-uploader'>
      {label && <span className='label'>{label}</span>}
      <div className='client-photo-container'>
        {previewUrls.map((previewUrl, index) => (
          <div key={index} className='image-container'>
            {files[index]?.type.startsWith("video/") ? (
              <video controls src={previewUrl} className='client-media' />
            ) : (
              <img src={previewUrl} alt={`Media ${index + 1}`} className='client-media' />
            )}
            {isEdit ? (
              <div
                className='edit-icon'
                onClick={() => {
                  setSelectedFileIndex(index);
                  setIsEditMode(true);
                }}
              >
                <i className='fa fa-pencil'></i>
              </div>
            ) : null}

            {errorMessage && index === previewUrls.length - 1 && <div className='error-message'>{errorMessage}</div>}
          </div>
        ))}

        {previewUrls.length === 0 && files.length < maxFiles && (
          <Dropzone onDrop={(files) => handleFileChange(files, "add")} accept={acceptTypes} multiple={multiple}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className='drop-zone add-media-container'>
                <input {...getInputProps()} />
                <div className='file-container'>
                  <span className='icon-plus'>
                    <i className='fa-duotone fa-solid fa-plus'></i>
                  </span>
                  <p className='description-image'>{placeholder}</p>
                  <span className='image-subtitle'>
                    Accepts:{" "}
                    {[".jpeg", ".png", ".jpg"].map((ext, index, array) =>
                      index === array.length - 1 ? ` ${ext}` : ` ${ext},`
                    )}
                  </span>
                </div>
              </div>
            )}
          </Dropzone>
        )}
      </div>

      {isEditMode && selectedFileIndex !== null && (
        <Dropzone onDrop={(files) => handleFileChange(files, "update")} accept={acceptTypes}>
          {({ getRootProps, getInputProps }) => (
            <Modal isOpen={isEditMode} toggle={() => setIsEditMode(false)} className='edit-modal'>
              <ModalHeader toggle={() => setIsEditMode(false)}>Edit Media</ModalHeader>
              <div className='d-flex justify-content-center align-items-center p-2 pt-4'>
                <div {...getRootProps()} className='drop-zone'>
                  <input {...getInputProps()} />
                  <div className='file-container'>
                    <span className='icon-plus'>
                      <i className='fa-duotone fa-solid fa-plus'></i>
                    </span>
                    <p className='description'>Re-pick Media</p>
                  </div>
                </div>
              </div>
              <ActionButton
                title='Remove Current Media'
                onClick={() => handleRemoveFile(selectedFileIndex)}
                className='cancel-button mt-1 py-2'
              />
            </Modal>
          )}
        </Dropzone>
      )}

      {isPreview && previewUrls.length > 0 && (
        <Modal isOpen={isPreview} toggle={togglePreview} className='preview-modal'>
          <ModalHeader toggle={togglePreview}>Preview Media</ModalHeader>
          {files[0]?.type.startsWith("video/") ? (
            <video controls src={previewUrls[0]} className='full-preview-media' />
          ) : (
            <img src={previewUrls[0]} alt='Preview' className='full-preview-media' />
          )}
        </Modal>
      )}
    </div>
  );
};

export default ImageAttachment;
