import { Dispatch } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4, validate as isUUID } from "uuid";
import {
  buildParticipantDetails,
  buildValues,
  createParticipantDetailsPayload,
  emitUpdateParticipantDetails,
  fetchParticipantDetails,
  getFilteredNotes,
  getFormikInitialValues,
  initializeInputFields,
  initializeTabs,
  mergeParticipantValues
} from "./helper";
import { setSelectedConfigurator } from "../../../../redux/slices/formConfiguratorSlice";
import { RootState } from "../../../../redux/store";
import { AppDispatch } from "../../../../redux/types";
import { removeProfilePicture, uploadProfilePicture } from "../../../../services/login";
import { FORM_LABELS, NOTE_TYPES, REACTION_FIELD_TYPES, SORT_DIRECTIONS, STRINGS } from "../../../../utils/constants";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import {
  addFieldToInputFieldsData,
  dragFieldInTab,
  filterInputFieldsData,
  getFormikValidationSchema,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent
} from "../../../../utils/helpers/customizableTabHelper";
import useSocket from "../../../../utils/hooks/sockets";
import {
  AnervaServerResponseProps,
  BasicInfoField,
  ConfigureTabProps,
  EditCandidateProps,
  EventData,
  FormField,
  IFormSectionType,
  Note,
  SelectedParticipantProps,
  SocketEmitFunction,
  IFormConfiguratorType
} from "../../../../utils/types";
import CustomizableTabs from "../../../CustomizableTabs";
import InfoCard from "../../../InfoCard";
import ToggleButton from "../../../ToggleButton/toggleButton";

const CustomizableEditCandidate: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [inputFieldsData, setInputFieldsData] = useState<FormField[]>([]);
  const [excludedFields, setExcludedFields] = useState<{ field: FormField; section: string }[]>([]);
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);

  const { selectedParticipant } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const user = useSelector((state: RootState) => state.users.user);
  const { selectedConfigurator } = useSelector((state: RootState) => state.formConfigurator);
  const { socketEmit } = useSocket();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const fetchedRef = useRef(false);

  useEffect(() => {
    if (selectedParticipant?.participantDetails) {
      initializeTabs(selectedParticipant, selectedConfigurator, setTabs, setExcludedFields, setActiveTab);
      initializeInputFields(selectedConfigurator, selectedParticipant, tabs, setInputFieldsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant, selectedConfigurator]);

  const fetchFormConfigurator = useCallback(() => {
    if (fetchedRef.current) return; // Exit if already fetched

    if (socketEmit && user && selectedCase) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        {
          type: SOCKET_EVENTS.FETCH_FORM_CONFIGURATOR,
          eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
          createdBy: user.id,
          companyId: user.company?.companyId,
          caseId: selectedCase.id,
          entityType: "participant",
          requestId: uuidv4()
        },
        (response: AnervaServerResponseProps) => {
          fetchedRef.current = true;

          if (response.success && response.data) {
            const selectedForm: IFormConfiguratorType | undefined = response?.data?.formConfigurator?.find(
              (i: IFormConfiguratorType) =>
                i.companyId === user?.company?.companyId &&
                i.entityType === "participant" &&
                i.caseId === selectedCase?.id
            );
            if (selectedForm) dispatch(setSelectedConfigurator(selectedForm));
            else dispatch(setSelectedConfigurator(null));
          } else {
            toast.error("Failed to fetch form configurator");
          }
        }
      );
    }
  }, [socketEmit, user, selectedCase, dispatch]);

  useEffect(() => {
    fetchFormConfigurator();
  }, [fetchFormConfigurator]);

  const addNewTab = useCallback(
    (label: string) => {
      if (socketEmit && user && selectedCase) {
        const newSection: Partial<IFormSectionType> = {
          label,
          name: label.toLowerCase().replace(/\s+/g, "_"),
          content: []
        };

        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          {
            type: SOCKET_EVENTS.ADD_SECTION,
            eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
            createdBy: user.id,
            companyId: user.company?.companyId,
            caseId: selectedCase.id,
            entityType: "participant",
            requestId: uuidv4(),
            section: newSection
          },
          (response: AnervaServerResponseProps) => {
            if (response.success && response.data) {
              toast.success("New section added successfully");
            } else {
              toast.error("Failed to add new section");
            }
          }
        );
      }
    },
    [socketEmit, user, selectedCase]
  );

  const handleAddField = useCallback(
    (section: ConfigureTabProps, fieldData: Partial<FormField>) => {
      if (socketEmit && user && selectedCase) {
        const payload = {
          type: SOCKET_EVENTS.ADD_FIELD,
          eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
          createdBy: user.id,
          companyId: user.company?.companyId,
          caseId: selectedCase.id,
          entityType: "participant",
          requestId: uuidv4(),
          formConfiguratorId: selectedConfigurator?._id || null,
          sectionId: section?.id && isUUID(section?.id) ? null : section?.id,
          formField: fieldData
        };

        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          {
            ...payload,
            ...((!payload.sectionId || !payload.formConfiguratorId) && { sectionName: section?.label })
          },
          (response: AnervaServerResponseProps) => {
            if (response.success && response.data) {
              toast.success("New field added successfully");
            } else {
              toast.error("Failed to add new field");
            }
          }
        );
      }
    },
    [socketEmit, user, selectedCase, selectedConfigurator]
  );

  const basicInfo = useMemo(() => selectedParticipant?.participantDetails?.[0]?.basic || [], [selectedParticipant]);

  const getFieldByType = useCallback(
    (fieldType: string) =>
      Array.isArray(basicInfo) ? basicInfo.filter((field: BasicInfoField) => field?.field === fieldType) : [],
    [basicInfo]
  );

  const profileImage: string = useMemo(() => {
    const field = getFieldByType("profileImage")?.[0];
    return typeof field?.value === "string" ? field.value : "";
  }, [getFieldByType]);

  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: FormField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: FormField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: FormField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: FormField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const handleSaveNote = useCallback(
    (
      socketEmit: <T extends EventData = EventData>(
        eventName: string,
        data: T,
        onSuccess?: (data: AnervaServerResponseProps) => void,
        onFailure?: (data: AnervaServerResponseProps) => void
      ) => void,
      values: {
        note: Note;
        type: string;
        eventType: string;
        caseId?: string;
        participantId?: string;
      }
    ) => {
      if (socketEmit) {
        const notePayload = {
          type: values.type,
          createdBy: user?.id || "",
          eventType: values.eventType,
          requestId: uuidv4(),
          note: values?.note.note,
          noteType: NOTE_TYPES.PARTICIPANT,
          caseId: selectedParticipant?.caseParticipants[0]?.caseId,
          participantId: selectedParticipant?.id,
          caseParticipantId: selectedParticipant?.caseParticipants[0]?.id
        };

        console.log("notePayload: ", notePayload);
        if (socketEmit) {
          socketEmit(
            SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
            notePayload,
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            },
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            }
          );
        }
      } else {
        toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
        return;
      }
    },
    [selectedParticipant?.caseParticipants, selectedParticipant?.id, user?.id]
  );

  const handleReactions = useCallback(
    (obj: {
      field: { name: string; label: string };
      tabSection: { name: string; label: string };
      isLiked: boolean | null;
    }) => {
      if (socketEmit) {
        const payload = {
          type: SOCKET_EVENTS.ADD_FIELD_REACTION,
          userId: user?.id || "",
          eventType: SOCKET_EVENTS.MANAGE_FIELD_REACTIONS,
          requestId: uuidv4(),
          detailType: REACTION_FIELD_TYPES.PARTICIPANT_DETAILS,
          caseId: selectedParticipant?.caseParticipants[0]?.caseId,
          caseParticipantId: selectedParticipant?.caseParticipants[0]?.id,
          isLiked: obj.isLiked,
          fieldName: obj.field.name,
          fieldLabel: obj.field.label,
          tabSection: obj.tabSection.name,
          tabSectionLabel: obj.tabSection.label
        };

        console.log("Reactions Payload: ", payload);
        if (socketEmit) {
          socketEmit(
            SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
            payload,
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            },
            (data) => {
              const resData = data as AnervaServerResponseProps;
              if (resData.success) toast.success(resData.message);
              else if (!resData.success) toast.error(resData.message);
            }
          );
        }
      } else {
        toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
        return;
      }
    },
    [selectedParticipant?.caseParticipants, socketEmit, user?.id]
  );

  const handleSaveBasicDetails = useCallback(
    (participant: SelectedParticipantProps) => {
      const payload = createParticipantDetailsPayload(
        participant as SelectedParticipantProps,
        user!,
        selectedCase?.id,
        selectedParticipant?.id
      );
      emitUpdateParticipantDetails(socketEmit as SocketEmitFunction, payload as SelectedParticipantProps);
    },
    [selectedCase?.id, selectedParticipant?.id, socketEmit, user]
  );

  const onFormSubmit = useCallback(
    async (tabs: ConfigureTabProps[]) => {
      const values = buildValues(tabs);
      const updatedParticipant: SelectedParticipantProps = mergeParticipantValues(
        values,
        selectedParticipant as SelectedParticipantProps
      );
      const mergedDetails = buildParticipantDetails(tabs);
      excludedFields.forEach(({ field, section }) => {
        // Ensure section exists in mergedDetails, then add excluded field to its respective section
        if (!mergedDetails[section]) mergedDetails[section] = [];
        mergedDetails[section].push(field);
      });
      const participantDetails = fetchParticipantDetails(updatedParticipant, mergedDetails);

      const participant = {
        ...updatedParticipant,
        participantDetails: [participantDetails]
      };
      // Save participant details
      await handleSaveBasicDetails(participant);
    },
    [handleSaveBasicDetails, selectedParticipant, excludedFields]
  );

  const onFormValuesChange = useCallback((newValues: FormikValues) => {
    setTabs((prevTabs) =>
      prevTabs.map((tab) => {
        const updatedContent = tab?.content?.map((field) => {
          if (field.name in newValues) {
            return {
              ...field,
              value: newValues[field.name]
            };
          }
          return field;
        });

        return {
          ...tab,
          content: updatedContent
        };
      })
    );
  }, []);

  const handleImage = useCallback(
    async (file: File | File[] | null, mode?: string) => {
      const obj = {
        image: file,
        uploadedBy: user?.id || "",
        participantId: selectedParticipant?.id,
        caseId: selectedCase?.id,
        mode: mode,
        imageId: getFieldByType("profileImage")?.[0]?.imageId || ""
      };
      if (mode === "remove") await removeProfilePicture(obj);
      else await uploadProfilePicture(obj);
    },
    [getFieldByType, selectedCase?.id, selectedParticipant?.id, user?.id]
  );
  return tabs.length > 0 ? (
    <div className='d-flex w-100 flex-column'>
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        initialValues={getFormikInitialValues([...tabs.flatMap((item) => item.content)])}
        validationSchema={getFormikValidationSchema([...tabs.map((item) => item.content)].flat())}
        moveField={moveField}
        onFormSubmit={onFormSubmit}
        onFormValuesChange={onFormValuesChange}
        enableNotepad={true}
        notesHistory={getFilteredNotes(selectedParticipant!, selectedCase!)}
        handleSaveNote={(values) => handleSaveNote(socketEmit, values)}
        setInputFieldsData={setInputFieldsData}
        profileImage={profileImage || ""}
        handleReactions={handleReactions}
        handleImage={handleImage}
        onAddField={handleAddField}
        isReacted
      />
    </div>
  ) : (
    <InfoCard />
  );
};

export default CustomizableEditCandidate;
