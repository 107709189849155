import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { initializeTabs } from "./helper";
import { RootState } from "../../../../redux/store";
import { ConfigureTabProps, EditCandidateProps, TabDataField } from "../../../../utils/types";
import InfoCard from "../../../InfoCard";
import TabButtons from "../../../TabButtons";
import {
  Demographic,
  EducationInfo,
  ExperienceInfo,
  ParticipantBasicInfo,
  SkillsInfo,
  SocialProfiles
} from "../../ParticipantInfoTabs";
const excludeTabs = ["alternativeNames"];
const ViewParticipantDetails: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);
  const { selectedParticipant } = useSelector((state: RootState) => state?.participants);

  useEffect(() => {
    if (selectedParticipant?.participantDetails) {
      initializeTabs(selectedParticipant, setTabs, setActiveTab);
    }
  }, [selectedParticipant]);

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const renderTabContent = useCallback(() => {
    return tabs && tabs.length > 0 ? (
      tabs
        .filter((tab) => !excludeTabs.includes(tab.value)) // Exclude specified tabs
        .map((tab) => {
          if (activeTab?.value === tab.value) {
            const rawTabData = selectedParticipant?.participantDetails?.[0]?.[tab.value];
            const tabData: TabDataField[] = Array.isArray(rawTabData) ? rawTabData : [];
            return (
              <div key={tab.value}>
                {tab.value === "basic" && <ParticipantBasicInfo basicData={tabData} />}
                {tab.value === "demographic" && <Demographic data={tabData} />}
                {tab.value === "education" && <EducationInfo />}
                {tab.value === "experience" && <ExperienceInfo />}
                {tab.value === "skills" && <SkillsInfo />}
                {tab.value === "socialProfiles" && <SocialProfiles />}

                {!["basic", "demographic", "education", "skills", "socialProfiles", "experience"].includes(
                  tab.value
                ) && <Demographic data={tabData} />}
              </div>
            );
          }
          return null;
        })
    ) : (
      <InfoCard />
    );
  }, [activeTab?.value, selectedParticipant?.participantDetails, tabs]);

  return (
    <div className='d-flex w-100 flex-column'>
      <div className='d-flex flex-row align-items-center justify-content-between'>
        <TabButtons
          tabs={tabs.filter((tab) => !excludeTabs.includes(tab.value)) as ConfigureTabProps[]}
          activeTab={activeTab as ConfigureTabProps}
          onTabChange={handleTabChange}
        />
      </div>

      <div className='d-flex w-100 flex-column'>{renderTabContent()}</div>
    </div>
  );
};

export default ViewParticipantDetails;
