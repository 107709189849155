import { phoneFields } from "../../utils/constants";
import { formatPhoneNumber } from "../../utils/helpers";
import { PhoneFieldName } from "../../utils/types";

export const handleInputChange = (
  e: React.ChangeEvent<HTMLInputElement>,
  props: {
    name?: string;
    onlyAlpha?: boolean;
    onlyNumeric?: boolean;
    alphaNumeric?: boolean;
  }
): void => {
  let inputValue = e.target.value;
  const fieldName: string = props?.name || "";

  // Format phone number if applicable
  if (fieldName?.length > 0 && phoneFields.includes(fieldName as PhoneFieldName)) {
    inputValue = formatPhoneNumber(inputValue);
  } else {
    // Handle onlyAlpha (only alphabets, space, and underscore)
    if (props.onlyAlpha) {
      inputValue = inputValue.replace(/[^a-zA-Z _]/g, "");
    }

    // Handle onlyNumeric
    else if (props.onlyNumeric) {
      inputValue = inputValue.replace(/\D/g, "");
    }

    // Handle alphaNumeric
    else if (props.alphaNumeric) {
      inputValue = inputValue.replace(/[^a-zA-Z0-9 _]/g, "");
    }

    // Remove special characters and emojis, except for password fields
    else if (fieldName.toLowerCase() !== "password") {
      inputValue = inputValue.replace(/[^\p{L}\p{N}\s_.,@]/gu, "");
    }
  }

  // Update the input value
  e.target.value = inputValue;
};
