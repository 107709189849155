import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { Company, CompanyState } from "../../utils/types";

const initialState: CompanyState = {
  loading: true,
  companiesList: [],
  selectedCompany: null,
  total: 0 as number
};

// Create a slice for companies management.
const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {
    // Action to get all companies.
    allCompanies: (state, action: PayloadAction<Company[]>) => {
      state.companiesList = action.payload;
      state.loading = false;
    },
    // Action to delete a company by its name.
    deleteCompany: (state, action: PayloadAction<string>) => {
      state.companiesList = state.companiesList.filter((company) => company.companyName !== action.payload);
    },
    // Action to create a new company.
    createNewCompany: (state, action: PayloadAction<Company>) => {
      state.companiesList.push(action.payload);
    },
    // Action to set the selected company.
    setSelectedCompany: (state, action: PayloadAction<Company | null>) => {
      state.selectedCompany = action.payload;
    },
    // Action to clear the selected company.
    clearSelectedCompany: (state) => {
      state.selectedCompany = null;
    }
  }
});

// Persist configuration for companies
const companiesPersistConfig = {
  key: "companies",
  storage,
  whitelist: ["selectedCompany"] // Specify what to persist
};

// Create a persisted reducer
const persistedCompaniesReducer = persistReducer(companiesPersistConfig, companiesSlice.reducer);

// Export the actions for use in components.
export const { allCompanies, deleteCompany, createNewCompany, setSelectedCompany, clearSelectedCompany } =
  companiesSlice.actions;

// Export the persisted reducer for use in the store.
export default persistedCompaniesReducer;
