import { AxiosError } from "@digitalworkflow/psl_web";
import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { setUser } from "../../../redux/slices/userSlice";
import { verifyEmail } from "../../../services/login";
import getInstance from "../../../utils/axiosInstance";
import { MESSAGES, SUPER_ADMIN } from "../../../utils/constants";
import { API_END_POINTS } from "../../../utils/constants/apiEndPoints";
import routes from "../../../utils/constants/routes";
import { handleNavigation } from "../../../utils/helpers";
import socketService from "../../../utils/socketService";
import { AxiosErrorResponse } from "../../../utils/types";

// Validation schema for Formik
export const validationSchema = {
  email: Yup.object({
    email: Yup.string().email(MESSAGES.INVALID_EMAIL_FORMAT).required(MESSAGES.EMAIL_IS_REQUIRED)
  }),
  password: Yup.object({
    password: Yup.string().required(MESSAGES.PASSWORD_REQUIRED)
  })
};

// Handle Next Step (Email Step)
export const handleNext = async (
  values: { email: string },
  setStep: (step: "email" | "password") => void,
  setErrors: (errors: { email?: string; password?: string }) => void,
  setLoading: (loading: boolean) => void
): Promise<void> => {
  try {
    setLoading(true);
    const emailResponse = await verifyEmail(values.email);

    if (emailResponse && emailResponse.code === 200) {
      setStep("password");
    } else {
      let errorMessage = "An error occurred during email verification";
      if (emailResponse instanceof AxiosError && emailResponse.response?.data) {
        errorMessage = (emailResponse.response.data as AxiosErrorResponse).message;
      }
      toast.error(errorMessage);
      setErrors({ email: errorMessage });
    }
  } catch (error) {
    const errorMessage = (error as { message: string }).message || "An unexpected error occurred";

    toast.error(errorMessage);
    setErrors({ email: errorMessage });
  } finally {
    setLoading(false);
  }
};

// Handle Submit (Password Step)
export const handleSubmit = async (
  values: { email: string; password: string },
  setErrors: (errors: { email?: string; password?: string }) => void,
  navigate: NavigateFunction,
  dispatch: Dispatch,
  setLoading: (loading: boolean) => void
) => {
  console.log("Credentials values:", values);
  try {
    const axiosInstance = await getInstance();
    const response = await axiosInstance.post(API_END_POINTS.LOGIN, {
      email: values.email,
      password: values.password
    });
    console.log("response", response);
    if (response.status === 200) {
      const userResponse = response.data;
      if (userResponse && userResponse.user) {
        toast.success(
          `${MESSAGES.LOGGED_IN_AS}${userResponse?.user?.firstName || ""} ${userResponse?.user?.lastName || ""}`
        );
        localStorage.removeItem("logout-event");
        if (userResponse.user && userResponse.user?.role?.name) {
          localStorage.setItem("userRole", userResponse.user?.role?.name || "");
        }
        localStorage.setItem("user", JSON.stringify(userResponse?.user || ""));
        localStorage.setItem("authToken", userResponse?.token);
        localStorage.setItem("login-event", Date.now().toString());
        dispatch(setUser({ user: userResponse?.user, token: userResponse?.token }));
        await socketService.reconnect();
        handleNavigation(
          userResponse.user?.role?.name === SUPER_ADMIN ? routes.COMPANIES : routes.DASHBOARD,
          navigate,
          dispatch
        );
      }
    }
  } catch (error) {
    console.log("Error: ", error);
    const axiosError = error as AxiosError;
    if (axiosError.response) {
      const errorMessage = (axiosError.response.data as { message: string }).message;
      toast.error(errorMessage);
      if (errorMessage.includes("password")) {
        setErrors({ password: errorMessage });
      } else {
        setErrors({ email: errorMessage, password: errorMessage });
      }
    } else {
      toast.error(MESSAGES.AN_ERROR_OCCURRED);
    }
  } finally {
    setLoading(false);
  }
};
