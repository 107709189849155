import { Dispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch } from "react-redux";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { AppDispatch } from "../../redux/types";
import routes from "../../utils/constants/routes";
import { isSuperAdmin } from "../../utils/helpers/localStorageHelper";

import "./styles.notFound.scss";

const NotFound = () => {
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();
  return (
    <div className='not-found-container'>
      <h1 className='not-found-title'>404</h1>
      <p className='not-found-message'>Oops! The page you&apos;re looking for doesn&apos;t exist.</p>
      <Link
        to={isSuperAdmin() ? routes.COMPANIES : routes.DASHBOARD}
        className='home-link'
        onClick={() => {
          navigate(isSuperAdmin() ? routes.COMPANIES : routes.DASHBOARD);
          dispatch(setActiveMenu(isSuperAdmin() ? routes.COMPANIES : routes.DASHBOARD));
        }}
      >
        Go back to the dashboard
      </Link>
    </div>
  );
};

export default NotFound;
