import { GridApi, ColumnApi } from "ag-grid-community";
import { LicenseManager } from "ag-grid-enterprise";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import GridTable, { GridProps } from "./GridTable";
import "./AgGrid.scss";
import "rc-dock/dist/rc-dock.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-balham.css";

LicenseManager.setLicenseKey(
  "CompanyName=Brians Com LLC,LicensedGroup=Protovate-1,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=2,AssetReference=AG-033265,SupportServicesEnd=26_November_2023_[v2]_MTcwMDk1NjgwMDAwMA==e06890fd3dc491144a33daab1bbf3843"
);

const AgGrid: React.FC<GridProps> = ({ loading = false, pagination = true, paginationPageSize = 50, ...props }) => {
  // Use AG Grid's API types for better type safety
  const gridRef = useRef<{ api: GridApi | null; columnApi: ColumnApi | null }>({
    api: null,
    columnApi: null
  });

  /**
   * Default column definitions to standardize column behavior across the grid.
   */
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      minWidth: 90,
      resizable: true,
      sortable: true,
      filter: true
    };
  }, []);

  /**
   * Handles grid readiness and initializes the loading overlay if applicable.
   */
  const onGridReady = useCallback(
    (params: { api: GridApi; columnApi: ColumnApi }) => {
      gridRef.current.api = params.api;
      gridRef.current.columnApi = params.columnApi;

      if (loading) {
        params.api.showLoadingOverlay();
      }
    },
    [loading]
  );

  /**
   * Manages the visibility of AG Grid overlays based on loading state and row data.
   */
  useEffect(() => {
    if (gridRef.current.api) {
      if (loading) {
        gridRef.current.api.showLoadingOverlay();
      } else if (!props.rowData || props.rowData.length === 0) {
        gridRef.current.api.showNoRowsOverlay();
      } else {
        gridRef.current.api.hideOverlay();
      }
    }
  }, [loading, props.rowData]);

  return (
    <div className='grid-table'>
      <GridTable
        defaultColDef={defaultColDef}
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        cacheBlockSize={paginationPageSize}
        animateRows
        rowDragManaged={true}
        suppressMoveWhenRowDragging={true}
        onGridReady={onGridReady}
        {...props}
      />
    </div>
  );
};

export default AgGrid;
