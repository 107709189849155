import { useDrag, useDrop } from "react-dnd";
import { getFieldValue } from "../../../../utils/helpers/customizableTabHelper";
import { DraggableInputFieldProps } from "../../../../utils/types";
import DateInput from "../../../DateInput";
import SelectComponent from "../../../SelectComponent";
import TextInput from "../../../TextInput";

import "./draggableInputField.scss";

function DraggableInputField({
  field,
  onDragEnd,
  onDragStart,
  isConfigureMode,
  isInputField,
  moveField,
  currentIndex,
  onChange,
  values,
  setFieldValue,
  fieldReactionValue,
  onLikeClick = () => {},
  onDisLikeClick = () => {},
  isReacted = false
}: DraggableInputFieldProps) {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "Field",
    item: field, // Pass the entire field object instead of just field.name
    canDrag: () => isConfigureMode && !field.isFixed,
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  }));
  const [, drop] = useDrop({
    accept: "Field",
    canDrop: () => isConfigureMode && !field.isFixed,
    hover(item: {
      index: number;
      label: string;
      name: string;
      isMandatory: boolean;
      placeholder?: string;
      isFixed?: boolean;
      type: string;
      value: Date | string | null;
      isSwitch?: boolean;
      min?: number;
      max?: number;
      id?: string;
      currencyText?: string;
    }) {
      if (item.index !== currentIndex && !item.isFixed && isConfigureMode) {
        moveField(item.index, currentIndex, item);
        item.index = currentIndex;
      }
    }
  });
  return !isInputField ? (
    <div
      onDrag={(e) => {
        if (onDragStart) onDragStart(e);
      }}
      onDragEnd={(e) => {
        if (onDragEnd) onDragEnd(e);
      }}
      ref={(node) => drag(drop(node))}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className='w-100'
    >
      <div className='draggable-input-field'>
        <span className='font-size-15 ellipsis-text'>{field.label}</span>
        <i className='fa-solid fa-grip-dots-vertical'></i>
      </div>
    </div>
  ) : (
    <div
      onDrag={(e) => {
        if (onDragStart) onDragStart(e);
      }}
      onDragEnd={(e) => {
        if (onDragEnd) onDragEnd(e);
      }}
      ref={isConfigureMode ? (node) => drag(drop(node)) : null}
      style={{ opacity: isDragging ? 0.5 : 1 }}
      className='w-100'
    >
      {!field.isDropdown && field.type === "date" ? (
        <DateInput
          label={field.label}
          placeholder={field.placeholder}
          parentClassName='w-100'
          value={getFieldValue(field, values) as Date}
          onDateChange={(date) => {
            if (setFieldValue) setFieldValue(field.name, date);
          }}
          minDate={new Date()}
        />
      ) : !field.isDropdown && field.type !== "date" ? (
        <TextInput
          key={field.name}
          label={field.label}
          name={field.name}
          placeholder={field.placeholder}
          type={field.type}
          errorMessage={field.error}
          isSwitch={field.isSwitch}
          onSwitchChange={field.isSwitch ? () => {} : undefined}
          min={field.min}
          max={field.max}
          onChange={onChange}
          value={getFieldValue(field, values) as string | number | readonly string[] | undefined}
          disabled={field.disabled}
          rows={field.rows}
          isReacted={isReacted}
          onLikeClick={onLikeClick}
          onDisLikeClick={onDisLikeClick}
          fieldReactionValue={fieldReactionValue}
        />
      ) : field.type !== "date" && field.isDropdown ? (
        <SelectComponent
          label={field.label}
          items={field?.dropdownList || []}
          value={getFieldValue(field, values) as string | number | readonly string[] | undefined}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (setFieldValue) setFieldValue(field.name, e.target.value);
          }}
          layout='column'
          placeholder={field.placeholder}
          customClass={"mb-1 mt-0"}
        />
      ) : null}
    </div>
  );
}

export default DraggableInputField;
