import { Form, useFormikContext } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { updateClientInformation } from "./helper";
import { RootState } from "../../../../../redux/types";
import { ClientInformationFormValues, NewClientFormBasicProps } from "../../../../../utils/types";
import BasicInfo from "../../../BasicInfo";

const NewClientFormBasic: React.FC<NewClientFormBasicProps> = (props) => {
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues, isValid } =
    useFormikContext<ClientInformationFormValues>();

  const { selectedParticipant } = useSelector((state: RootState) => state.participants);

  useEffect(() => {
    if (selectedParticipant) {
      setValues((prevValues) => updateClientInformation(prevValues, selectedParticipant));
    }
  }, [selectedParticipant, setValues]);
  return (
    <Form onSubmit={handleSubmit}>
      <BasicInfo
        values={values}
        errors={errors}
        setFieldValue={setFieldValue}
        handleChange={handleChange}
        photoLabel={props?.photoLabel}
        handleTabChange={props?.handleTabChange}
        resetForm={resetForm}
        isValid={isValid}
      />
    </Form>
  );
};

export default NewClientFormBasic;
