// InfoCard.js
import React from "react";
import "./styles.scss";

const InfoCard = ({ message = "Information isn't available yet", customClass = "" }) => {
  return (
    <div className={`info-card ${customClass}`}>
      <div className='icon-container'>
        <i className='fa-light fa-triangle-exclamation'> </i>
      </div>
      <p className='message'> {message} </p>
    </div>
  );
};

export default InfoCard;
