import { v4 as uuidv4 } from "uuid";
import { getProfileImageUrl } from "../../../utils/helpers";
import { CompanyUser, ConfigureTabProps, FormField, TabDataField } from "../../../utils/types";

export function transformValue(value: unknown): string | string[] | Date | null {
  if (typeof value === "string" || Array.isArray(value) || value instanceof Date || value === null) {
    return value;
  }
  if (typeof value === "number" || typeof value === "boolean") {
    return String(value);
  }
  return null;
}

export function transformTabValue(value: unknown): string | number | undefined {
  if (value === null || value === undefined) {
    return undefined;
  }
  if (typeof value === "string" || typeof value === "number") {
    return value;
  }
  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }
  if (value instanceof Date) {
    return value.toISOString();
  }
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  return String(value);
}

export const initializeTabs = (
  data: CompanyUser,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const details = [data?.userId?.userDetails];

  const list: ConfigureTabProps[] = [];

  details?.forEach((section) => {
    const sectionKeys: string[] = Object.keys(section ?? {}).filter(
      (key) => !["_id", "userId", "__v", "createdAt", "updatedAt"].includes(key)
    );

    sectionKeys.forEach((sectionKey: string) => {
      if (section) {
        const sectionData = section[sectionKey as keyof typeof section];
        if (Array.isArray(sectionData) && sectionData.length > 0) {
          const content: FormField[] = (sectionData as FormField[]).map((field: FormField) => {
            const value = transformValue(field.value);
            return {
              ...field,
              id: field.id?.toString() || "",
              label: field.label || "",
              isMandatory: field.isMandatory || false,
              name: field.field || field.name || "",
              placeholder: field.placeholder || "",
              type: field.type || "text",
              value: value,
              isFixed: field.isFixed || false,
              field: field.field || field.name || "",
              isCustom: field.isCustom ?? true,
              isSwitch: field.isSwitch || false,
              disabled: field.disabled || false,
              dropdownList: field.dropdownList || []
            };
          });

          // Add profile image only to the basic section
          if (sectionKey === "basic") {
            const image = getProfileImageUrl(data?.userId?.images?.[0]?.profileImage?.filename || "");
            if (image) {
              content.unshift({
                name: "profileImage",
                type: "image",
                field: "profileImage",
                value: image,
                id: uuidv4(),
                label: "",
                isMandatory: false,
                placeholder: "",
                isCustom: true,
                isSwitch: false,
                disabled: false,
                dropdownList: []
              });
            }
          }
          list.push({
            id: uuidv4(),
            label: (sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)).replace(/_/g, " "),
            value: sectionKey,
            content
          });
        }
      }
    });
  });

  setTabs(list);
  setActiveTab(list[0]);
};

export const transformDetailedViewData = (activeTab: ConfigureTabProps): TabDataField[] => {
  if (!activeTab || !activeTab.content) {
    return [];
  }

  return activeTab.content.map(
    (field: FormField): TabDataField => ({
      _id: (field.id || field._id || "").toString(),
      label: field.label || "",
      name: field.name || field.field || "",
      type: field.type || "text",
      value: transformTabValue(field.value),
      isFixed: field.isFixed || false,
      isMandatory: field.isMandatory || false,
      placeholder: field.placeholder || "",
      isCustom: field.isCustom || false,
      isSwitch: field.isSwitch || false,
      disabled: field.disabled || false,
      error: field.error || undefined
    })
  );
};
