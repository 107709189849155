import { faChevronRight, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { SidebarMenuCustomProps } from "./layout_types";
import SidebarMenuLinkCustom from "./SideBarMenuLinkCustom";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { AppDispatch, RootState } from "../../redux/types";

import "./styles.scss";

const SidebarMenuCustom: React.FC<SidebarMenuCustomProps> = ({
  menu,
  index,
  collapse,
  onCollapse,
  leftSideBarType
}) => {
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const activeMenu = useSelector((state: RootState) => state.menu.activeMenu);
  const [isCollapsed, setIsCollapsed] = useState(collapse);
  const menuClose = leftSideBarType === "menu-closed";

  useEffect(() => {
    setIsCollapsed(collapse);
  }, [collapse]);

  const isActive = useMemo(() => {
    if (typeof menu.route === "string" && activeMenu) {
      return activeMenu.includes(menu.route) || menu.children?.some((item) => item.route === activeMenu);
    }
    return menu.children?.some((item) => item.route === activeMenu) || false;
  }, [activeMenu, menu.route, menu.children]);

  const hasChildren = useMemo(() => !!((menu?.children || [])?.length > 0), [menu.children]);

  const handleToggleCollapse = useCallback(() => {
    const newCollapseState = !isCollapsed;
    setIsCollapsed(newCollapseState);

    if (isActive) {
      // dispatch(setActiveMenu(undefined));
    } else {
      dispatch(setActiveMenu(menu.route!));
      if (!newCollapseState) {
        onCollapse(menu);
      }
    }
  }, [isCollapsed, dispatch, menu, onCollapse, isActive]);

  return (
    <li className={isActive ? "mm-active" : ""}>
      {menu.tooltip_en && (
        <UncontrolledTooltip placement='top' target={`tooltip-${index}`}>
          {menu.tooltip_en}
        </UncontrolledTooltip>
      )}
      <Link
        to={hasChildren && !menu.route ? "#" : menu.route!}
        className={`waves-effect ${isActive ? "mm-active" : ""}`}
        id={`tooltip-${index}`}
        onClick={handleToggleCollapse}
        style={{ justifyContent: "space-between" }}
      >
        <div className='menu-label'>
          {menu?.icon && <i className={menu?.icon || ""} />}
          <span style={{ marginLeft: "10px" }} className='font-size-15 menu-text'>
            {menu.title_en}
          </span>
        </div>
        {isCollapsed && !menuClose && (
          <FontAwesomeIcon icon={faChevronRight} className={`chevron-icon ${isActive ? "hide-arrow" : "hide-arrow"}`} />
        )}
        {!isCollapsed && hasChildren && <FontAwesomeIcon icon={faChevronUp} className='chevron-icon' />}
      </Link>
      {hasChildren && (
        <ul className={`sub-menu mm-collapse ${isCollapsed ? "" : "mm-show"}`} aria-expanded='false'>
          {menu.children?.map((menuLink, i) => (
            <SidebarMenuLinkCustom
              key={`${index}-${i}`}
              link={menuLink}
              label={menuLink.subtitle_en || ""}
              index={`${index}-${i}`}
            />
          ))}
          <hr className='sub-menu-bottom-border' />
        </ul>
      )}
    </li>
  );
};

export default SidebarMenuCustom;
