import React from "react";
import { useDrop } from "react-dnd";
import { Participant } from "../../../../../utils/types";
import "./styles.alternativeJurors.scss";
import JurorCard from "../JurorCard";

interface AlternativeJurorsProps {
  alternativeJurors: Participant[];
  onAddAlternativeJuror: (juror: Participant) => void;
  onRemoveAlternativeJuror: (juror: Participant) => void;
  onEdit: (juror: Participant) => void;
  onView: (juror: Participant) => void;
  onDrop: (event: React.DragEvent, dropTarget: "juryBox" | "alternative") => void;
}

const AlternativeJurors: React.FC<AlternativeJurorsProps> = ({
  alternativeJurors,
  // onAddAlternativeJuror,
  onRemoveAlternativeJuror,
  onEdit,
  onView,
  onDrop
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "JUROR",
    drop: (item: Participant & { source: string }) => {
      console.log("Dragged Alternative", item);
      // if (item.source === "AG_GRID") {
      //   onAddAlternativeJuror(item);
      // }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  return (
    <div
      ref={drop}
      className={`alternative-jurors-section ${isOver ? "active-drop" : ""}`}
      onDrop={(event) => onDrop(event, "alternative")}
      onDragOver={(event) => event.preventDefault()}
    >
      <h4>Alternative Juror(s)</h4>
      <div className='juror-cards flex-col'>
        {alternativeJurors && alternativeJurors.length > 0 ? (
          alternativeJurors.map((juror) => (
            <JurorCard
              key={juror.id}
              juror={{ ...juror, seatNumber: undefined }}
              alternative
              onRemove={() => onRemoveAlternativeJuror(juror)}
              onEdit={() => onEdit(juror)}
              onView={() => onView(juror)}
            />
          ))
        ) : (
          <div className='drag-hint'>Drag and drop a juror here to add as an alternative (max 3).</div>
        )}
      </div>
    </div>
  );
};

export default AlternativeJurors;
