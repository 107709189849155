/**
 * @file anervaBoard.ts
 * @description Provides services for interacting with the Anerva Board backend APIs, including generating a registration code and checking the registration status.
 * @module AnervaBoardService
 */

import { AxiosError, AxiosInstance } from "@digitalworkflow/psl_web";
import getInstance from "../utils/axiosInstance";
import { API_END_POINTS } from "../utils/constants/apiEndPoints";
import { handleError } from "../utils/helpers/errorhandler";

/**
 * @interface Response
 * @description Defines the structure of the API response.
 */
interface Response {
  code: number | string;
  message: string;
  error?: string;
  success?: boolean;
  expiresIn?: number;
}

/**
 * @interface GenerateCodeRequest
 * @description Defines the structure of the request payload for generating a registration code.
 */
interface GenerateCodeRequest {
  userId: string;
  trialId: string;
}

/**
 * @function generateCode
 * @description Sends a request to the backend API to generate a registration code for a user and trial.
 * @param {string} userId - The unique ID of the user.
 * @param {string} trialId - The unique ID of the trial.
 * @returns {Promise<Response>} Resolves to the API response containing the generated code or throws a standardized error.
 */
export const generateCode = async (userId: string, trialId: string): Promise<Response> => {
  try {
    const axiosInstance: AxiosInstance | null = await getInstance();

    if (!axiosInstance) {
      throw new Error("Failed to initialize Axios instance.");
    }

    const data: GenerateCodeRequest = { userId, trialId };
    const response = await axiosInstance.post<Response>(API_END_POINTS.GENERATE_CODE, data);

    if (!response || !response.data) {
      throw new Error("No response received from the API.");
    }

    console.log("The response of Code generation is", response.data);
    return response.data;
  } catch (error) {
    const handledError = handleError(error as AxiosError | Error); // Explicitly cast error here
    console.log("Code generation failed:", handledError.message);
    throw handledError;
  }
};

/**
 * @function checkRegistrationStatus
 * @description Verifies if an Anerva Board is registered using the provided registration code.
 * @param {string} code - The registration code to check.
 * @returns {Promise<Response>} Resolves to the API response containing the registration status or throws a standardized error.
 */
export const checkRegistrationStatus = async (code: string): Promise<Response> => {
  try {
    const axiosInstance: AxiosInstance | null = await getInstance();

    if (!axiosInstance) {
      throw new Error("Failed to initialize Axios instance.");
    }

    const response = await axiosInstance.get<Response>(`${API_END_POINTS.BOARD_STATUS}/${code}`);

    if (!response || !response.data) {
      throw new Error("No response received from the API.");
    }

    return response.data;
  } catch (error) {
    const handledError = handleError(error as AxiosError | Error); // Explicitly cast error here
    console.log("Registration status check failed:", handledError.message);
    throw handledError;
  }
};
