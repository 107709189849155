import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { CasesState, SingleCase } from "../../utils/types/index";
import { CaseListData, SelectedCaseProps } from "../../utils/types/index";
import { RootState } from "../store";

const initialState: CasesState = {
  loading: true,
  caseList: [],
  singleCase: null,
  selectedCase: null,
  total: 0 as number
};

const casesSlice = createSlice({
  name: "cases",
  initialState,
  reducers: {
    allCases: (state, action: PayloadAction<CaseListData[]>) => {
      if (Array.isArray(action.payload)) {
        state.caseList = action.payload;
        if (state.selectedCase) {
          const updatedCase = action.payload.find((c) => c.id === state.selectedCase?.id);
          if (updatedCase) {
            state.selectedCase = updatedCase;
          }
        }
      } else {
        console.error("allCases action payload is not an array:", action.payload);
      }
      state.loading = false;
    },
    deleteCase: (state, action: PayloadAction<string>) => {
      if (Array.isArray(state.caseList)) {
        state.caseList = state.caseList.filter(
          (caseItem) => String(caseItem.courtCaseNumber) !== String(action.payload)
        );
      } else {
        console.error("caseList is not an array:", state.caseList);
      }
    },
    singleCase: (state, action: PayloadAction<SingleCase | null>) => {
      state.singleCase = action.payload;
    },
    setSelectedCase: (state, action: PayloadAction<SelectedCaseProps | null>) => {
      state.selectedCase = action.payload;
    },
    clearSelectedCase: (state) => {
      state.selectedCase = null;
    },
    setTotalCases: (state, action: PayloadAction<number>) => {
      state.total = action.payload;
    }
  }
});

const casesPersistConfig = {
  key: "cases",
  storage,
  whitelist: ["selectedCase"]
};

const persistedCasesReducer = persistReducer(casesPersistConfig, casesSlice.reducer);
export const selectSelectedCaseId = (state: RootState) => state.cases.selectedCase?.id;

export const { allCases, deleteCase, singleCase, setSelectedCase, clearSelectedCase, setTotalCases } =
  casesSlice.actions;
export default persistedCasesReducer;
