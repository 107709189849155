import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { ActionButton, BreadCrumbs, MenuButton, TabButtons } from "../../components";
import NavigationWrapper from "../../components/NavigationWrapper";
import { JuryBox, TrialTeamTab } from "../../components/Pages/TrialTeam";
import { BUTTON_TITLE, trialTeamTabButtons } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { ConfigureTabProps } from "../../utils/types";

import "./styles.trialTeam.scss";

const TrialTeam: React.FC = () => {
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(trialTeamTabButtons[0]);
  const navigate: NavigateFunction = useNavigate();

  const onTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const renderActiveTabContent = () => {
    switch (activeTab) {
      case trialTeamTabButtons[0]:
        return <TrialTeamTab />;
      case trialTeamTabButtons[1]:
        return <JuryBox />;
      default:
        return null;
    }
  };

  return (
    <div className='trial-team'>
      <BreadCrumbs
        breadcrumbItems={[
          { url: routes.CREATE_CASE, text: "Case Name" },
          { text: "Trial Team", url: routes.TRIAL_TEAM }
        ]}
      />
      <NavigationWrapper
        title='Trial Team'
        headerChild={
          <div className='button-container'>
            <ActionButton
              className='save-button'
              title={BUTTON_TITLE.UPLOAD_JURY_LIST}
              onClick={() => alert(BUTTON_TITLE.UPLOAD_JURY_LIST)}
              icon={<i className='fa-solid fa-plus'></i>}
            />
            <ActionButton
              title={BUTTON_TITLE.ADD_JUROR}
              onClick={() => navigate(routes.ADD_NEW_JUROR)}
              icon={<FontAwesomeIcon icon={"plus"} />}
            />
            <MenuButton />
          </div>
        }
      >
        <div>
          <TabButtons
            tabs={trialTeamTabButtons}
            activeTab={activeTab || trialTeamTabButtons[0]}
            onTabChange={onTabChange}
          />
          {renderActiveTabContent()}
        </div>
      </NavigationWrapper>
    </div>
  );
};

export default TrialTeam;
