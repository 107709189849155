/**
 * @file DeviceRegistrationModal.tsx
 * @description Modal component to handle device registration for the Anerva Board with animated timer icon.
 */

import { faStopwatch as faStopwatchRegular } from "@fortawesome/pro-regular-svg-icons";
import { faStopwatch, faQrcode, faCheckCircle, faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { RootState } from "../../redux/types";
import { BUTTON_TITLE } from "../../utils/constants";
import { DeviceRegistrationModalProps, Status } from "../../utils/types";
import ActionButton from "../ActionButton";
import { checkBoardStatus, fetchRegistrationCode, formatTime } from "./helper";
import "./styles.deviceRegistrationModal.scss";

const DeviceRegistrationModal: React.FC<DeviceRegistrationModalProps> = ({ showModal, onClose, size = "lg" }) => {
  const [code, setCode] = useState<string>("");
  const [timeLeft, setTimeLeft] = useState<number>(0);
  const [status, setStatus] = useState<Status>("idle");
  const [error, setError] = useState<string | null>(null);
  const [isSolidIcon, setIsSolidIcon] = useState<boolean>(true); // Manage icon toggle state

  const { user } = useSelector((state: RootState) => state.users);
  const { selectedCase } = useSelector((state: RootState) => state.cases);

  /**
   * @function fetchCode
   * @description Fetches a registration code and sets the timer.
   */
  const fetchCode = useCallback(async (): Promise<void> => {
    setStatus("active");
    setError(null);

    const response = await fetchRegistrationCode(user?._id || "", selectedCase?.id || "");

    if (response) {
      setCode(response.code);
      setTimeLeft(response.expiresIn || 900); // Default to 15 minutes
    } else {
      setError("Failed to generate code. Please try again.");
      setStatus("idle");
    }
  }, [user, selectedCase]);

  /**
   * @function checkStatus
   * @description Checks the registration status at regular intervals.
   */
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (status === "active" && code) {
      intervalId = setInterval(async () => {
        const response = await checkBoardStatus(code);

        if (response?.success) {
          setStatus("success");
          setTimeout(onClose, 5000); // Close modal after 5 seconds
        }
      }, 5000);
    }
    return () => clearInterval(intervalId);
  }, [status, code, onClose]);

  /**
   * @function startTimer
   * @description Starts the countdown timer for the registration code expiration.
   */
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showModal && status === "active" && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            setStatus("timeout");
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [showModal, status, timeLeft]);

  /**
   * @function toggleIcon
   * @description Toggles the stopwatch icon between regular and solid every 2 seconds.
   */
  useEffect(() => {
    let iconInterval: NodeJS.Timeout;
    if (status === "active") {
      iconInterval = setInterval(() => {
        setIsSolidIcon((prev) => !prev);
      }, 1000); // Toggle every 1 seconds
    }
    return () => clearInterval(iconInterval);
  }, [status]);

  /**
   * @function handleTryAgain
   * @description Handles "Try Again" action to regenerate the registration code.
   */
  const handleTryAgain = useCallback(() => {
    fetchCode();
  }, [fetchCode]);

  // Fetch code on modal open
  useEffect(() => {
    if (showModal) {
      fetchCode();
    }
  }, [showModal, fetchCode]);

  console.log("The error is =>", error);
  return (
    <Modal isOpen={showModal} toggle={onClose} centered size={size} className='device-registration-modal'>
      <ModalHeader toggle={onClose}>
        <FontAwesomeIcon icon={faQrcode} className='mr-2' />
        Attach Anerva Board
      </ModalHeader>
      <ModalBody>
        {status === "active" && (
          <div className='active-content'>
            <h3 className='registration-code'>
              Registration Code: <span className='code'>{code}</span>
            </h3>
            <p className='time-remaining'>
              <span>Time remaining:</span>
              <span className='timer-container'>
                <span className='timer'>{formatTime(timeLeft)}</span>
                <FontAwesomeIcon icon={isSolidIcon ? faStopwatch : faStopwatchRegular} />
              </span>
            </p>
            <p className='instructions'>
              Please enter this code on your Anerva Board to complete the registration process.
            </p>
          </div>
        )}
        {status === "timeout" && (
          <div className='timeout-content'>
            <h3 className='registration-code'>
              Registration Code: <span className='code-blank'>XXXXX</span>
            </h3>
            <p className='expired-message'>
              <FontAwesomeIcon icon={faCircleXmark} />
              <span>Registration code has expired.</span>
            </p>
            <ActionButton
              className='font-size-16 px-2 py-1'
              title={BUTTON_TITLE.TRY_AGAIN}
              icon={<i className='fa-solid fa-rotate-right'></i>}
              onClick={handleTryAgain}
            />
          </div>
        )}
        {status === "success" && (
          <div className='success-content'>
            <FontAwesomeIcon icon={faCheckCircle} className='success-icon' />
            <p className='success-message'>Registration successful! This window will close shortly.</p>
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DeviceRegistrationModal;
