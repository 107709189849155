import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { SUPER_ADMIN } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { MenuActionTypes } from "../types";

interface NavigationState extends MenuActionTypes {
  history: string[]; // Add a `history` array to store previous paths
}

const initialState: NavigationState = {
  activeMenu: localStorage.getItem("userRole") === SUPER_ADMIN ? routes.COMPANIES : routes.DASHBOARD,
  history: [] // Initialize an empty history array
};

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {
    setActiveMenu: (state, action: PayloadAction<string | undefined>) => {
      if (state.activeMenu) {
        // Push the current `activeMenu` to the history before updating
        state.history.push(state.activeMenu);
      }
      state.activeMenu = action.payload;
    },
    clearHistory: (state) => {
      state.history = []; // Add an action to clear the history if needed
    }
  }
});

export const { setActiveMenu, clearHistory } = menuSlice.actions;

const menuPersistConfig = {
  key: "menu",
  storage,
  whitelist: ["activeMenu", "history"] // Persist both activeMenu and history
};

const persistedMenuReducer = persistReducer(menuPersistConfig, menuSlice.reducer);

export default persistedMenuReducer;
