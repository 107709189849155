import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useMemo } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { SidebarMenuLinkProps } from "./layout_types";

const SidebarMenuLinkCustom: React.FC<SidebarMenuLinkProps> = ({ link, label, index }) => {
  const location: Location = useLocation();

  const isActive = useMemo(() => location.pathname === link.route, [location.pathname, link.route]);

  return (
    <React.Fragment>
      <li className={`mt-1 ${isActive ? "mm-active" : ""}`}>
        {link.tooltip_en && (
          <UncontrolledTooltip placement='top' target={`tooltip-${index}`}>
            {link.tooltip_en}
          </UncontrolledTooltip>
        )}
        <Link to={link.route!} className={`sidebar-link ${isActive ? "active" : ""}`} id={`tooltip-${index}`}>
          <div className='sidebar-link-content'>
            <div className='icon-wrapper'>
              <i className={link.icon!} />
            </div>
            <span className='link-label'>{label}</span>

            {link.badge_count ? (
              <span className='badge badge-pill badge-primary float-right font-size-12'>{link.badge_count}</span>
            ) : (
              <FontAwesomeIcon icon={faChevronRight} className={isActive ? "" : "chevron-icon"} />
            )}
          </div>
        </Link>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenuLinkCustom;
