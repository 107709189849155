import { AxiosError, AxiosInstance } from "@digitalworkflow/psl_web";
import { v4 as uuidv4 } from "uuid";
import getInstance from "../utils/axiosInstance";
import { API_END_POINTS } from "../utils/constants/apiEndPoints";
import { handleError } from "../utils/helpers/errorhandler";

// Define a common response structure
interface ApiResponse {
  code: number;
  message: string;
  token?: string;
}

// Define a common object for profile picture operations
interface ProfilePicturePayload {
  uploadedBy: string;
  userId?: string;
  participantId?: string;
  caseId?: string;
  companyId?: string;
  imageId?: string;
  mode?: string;
  image?: File | File[] | null;
}

// Retry configuration
const MAX_RETRIES = 3; // Max number of retries
const RETRY_DELAY = 1000; // Delay in ms between retries

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

/**
 * Service to verify if the current token is valid.
 * Implements retry logic with exponential backoff.
 * @param {string} token - The token to verify.
 * @returns {Promise<ApiResponse | null>} - Returns the verification response or null on failure.
 */
export const verifyToken = async (token: string): Promise<ApiResponse | null> => {
  let retries = 0;

  while (retries < MAX_RETRIES) {
    try {
      const axiosInstance: AxiosInstance | null = await getInstance(token);

      if (!axiosInstance) {
        throw new Error("Failed to create Axios instance");
      }

      const response = await axiosInstance.get<ApiResponse>(API_END_POINTS.VERIFY_TOKEN);
      console.log("Token verification response:", response.data);
      return response?.data ?? null;
    } catch (error) {
      console.error(`Token verification attempt ${retries + 1} failed:`, error);

      if (retries >= MAX_RETRIES - 1) {
        console.error("Max retries reached. Token verification failed.");
        return null;
      }

      retries++;
      await delay(RETRY_DELAY * retries); // Exponential backoff
    }
  }

  return null;
};

/**
 * Service to refresh the user's token.
 * @param {string} userId - The user's ID.
 * @param {string} token - The current token.
 * @returns {Promise<ApiResponse | null>} - Returns the refreshed token response or null on failure.
 */
export const refreshToken = async (userId: string, token: string): Promise<ApiResponse | null> => {
  try {
    const axiosInstance = await getInstance(token);
    if (!axiosInstance) throw new Error("Failed to create Axios instance");

    const data = { id: userId, token };
    const response = await axiosInstance.post<ApiResponse>(API_END_POINTS.REFRESH_TOKEN, data);

    console.log("Token refresh response:", response.data);
    return response?.data ?? null;
  } catch (error) {
    console.error("Token refresh failed:", error);
    return null;
  }
};

/**
 * Service to verify an email address.
 * @param {string} email - The email address to verify.
 * @returns {Promise<ApiResponse>} - Returns the email verification response.
 */
export const verifyEmail = async (email: string): Promise<ApiResponse | null> => {
  try {
    const axiosInstance = await getInstance();
    if (!axiosInstance) throw new Error("Failed to create Axios instance");

    const response = await axiosInstance.post<ApiResponse>(API_END_POINTS.VERIFY_EMAIL, { email });
    console.log("Email verification response:", response.data);
    return response?.data ?? null;
  } catch (error) {
    console.error("Email verification failed:", error);
    throw handleError(error as AxiosError | Error);
  }
};

/**
 * Service to upload a profile picture.
 * @param {ProfilePicturePayload} payload - The profile picture data.
 * @returns {Promise<ApiResponse | null>} - Returns the upload response or null on failure.
 */
export const uploadProfilePicture = async (payload: ProfilePicturePayload): Promise<ApiResponse | null> => {
  try {
    const token = localStorage.getItem("authToken") || "";
    const axiosInstance = await getInstance(token);
    if (!axiosInstance) throw new Error("Failed to create Axios instance");

    axiosInstance.defaults.headers["Content-Type"] = "multipart/form-data";
    axiosInstance.defaults.headers.common["Content-Type"] = "multipart/form-data";

    const formData = new FormData();
    formData.append("uploadedBy", payload.uploadedBy);
    formData.append("requestId", uuidv4());

    if (payload.userId) formData.append("userId", payload.userId);
    if (payload.participantId) formData.append("participantId", payload.participantId);
    if (payload.caseId) formData.append("caseId", payload.caseId);
    if (payload.companyId) formData.append("companyId", payload.companyId);

    if (payload.mode && payload.image) {
      if (payload.image instanceof File) {
        formData.append("image", payload.image);
      } else if (Array.isArray(payload.image) && payload.image[0] instanceof File) {
        formData.append("image", payload.image[0]);
      } else {
        console.error("Invalid image type");
        return null;
      }

      const url =
        payload.mode === "add"
          ? API_END_POINTS.UPLOAD_SINGLE_IMAGES
          : `${API_END_POINTS.UPDATE_FILE}/${payload.imageId}`;
      const response = await axiosInstance.post<ApiResponse>(url, formData);

      console.log(`${payload.mode} response:`, response.data);
      return response?.data ?? null;
    }

    return null;
  } catch (error) {
    console.error("Profile picture upload failed:", error);
    throw handleError(error as AxiosError | Error);
  }
};

/**
 * Service to remove a profile picture.
 * @param {Omit<ProfilePicturePayload, "image">} payload - The data to remove the profile picture.
 * @returns {Promise<ApiResponse | null>} - Returns the removal response or null on failure.
 */
export const removeProfilePicture = async (
  payload: Omit<ProfilePicturePayload, "image">
): Promise<ApiResponse | null> => {
  try {
    const token = localStorage.getItem("authToken") || "";
    const axiosInstance = await getInstance(token);
    if (!axiosInstance) throw new Error("Failed to create Axios instance");

    const response = await axiosInstance.post<ApiResponse>(`${API_END_POINTS.DELETE_FILE}/${payload.imageId}`, payload);

    console.log("Profile picture removal response:", response.data);
    return response?.data ?? null;
  } catch (error) {
    console.error("Profile picture removal failed:", error);
    throw handleError(error as AxiosError | Error);
  }
};
