import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteDocument } from "../../../../redux/slices/documentsSlice";
import { AppDispatch, RootState } from "../../../../redux/types";
import { CaseDocumentProps, CaseEvidence } from "../../../../utils/types";
import CaseDocuments from "../CaseDocuments";

const Documents: React.FC<CaseDocumentProps> = () => {
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const documents = useSelector((state: RootState) => state.documents.documents);
  const [documentsList, setDocumentList] = useState<CaseEvidence[]>([]);

  useEffect(() => {
    setDocumentList([]);
  }, [documents]);

  const handleDownload = useCallback((data: CaseEvidence) => {
    alert(`Downloading document with id: ${data?.id}`);
  }, []);

  const handleDelete = useCallback(
    (data: CaseEvidence) => {
      dispatch(deleteDocument(data?.id || 0));
    },
    [dispatch]
  );

  return <CaseDocuments documentsList={documentsList} onDownload={handleDownload} onDelete={handleDelete} />;
};

export default Documents;
