import React, { useCallback, useState } from "react";
import BasicInformation from "./BasicInformation";
import CommunicationPreferences from "./CommunicationPreferences";
import DemographicInformation from "./DemographicInformation";
import FinancialInformation from "./FinancialInformation";
import LegalRecordsInformation from "./LegalRecords";
import PersonalPreferences from "./PersonalPreferences";
import PoliticalAndSocialDetails from "./PoliticalAndSocial";
import { SMAndOnlinePresence } from "./SocialMediaAndOnlinePresence";
import { personalDataPageTabs } from "../../../../utils/constants";
import { ConfigureTabProps, PersonalDataProps } from "../../../../utils/types";
import TabButtons from "../../../TabButtons";

export const PersonalData: React.FC<PersonalDataProps> = ({ setActiveCard }) => {
  const [activeTab, setActiveTab] = useState<ConfigureTabProps>(personalDataPageTabs[0]);

  const handleTabChange = useCallback((tab: ConfigureTabProps = personalDataPageTabs[0]) => {
    setActiveTab(tab);
  }, []);

  const onTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab as ConfigureTabProps);
  }, []);

  const renderTabSection = (activeTab: ConfigureTabProps) => {
    switch (activeTab) {
      case personalDataPageTabs[0]:
        return <BasicInformation setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      case personalDataPageTabs[1]:
        return <DemographicInformation handleTabChange={handleTabChange} />;
      case personalDataPageTabs[2]:
        return <LegalRecordsInformation handleTabChange={handleTabChange} />;
      case personalDataPageTabs[3]:
        return <SMAndOnlinePresence handleTabChange={handleTabChange} />;
      case personalDataPageTabs[4]:
        return <PersonalPreferences handleTabChange={handleTabChange} />;
      case personalDataPageTabs[5]:
        return <PoliticalAndSocialDetails handleTabChange={handleTabChange} />;
      case personalDataPageTabs[6]:
        return <FinancialInformation handleTabChange={handleTabChange} />;
      case personalDataPageTabs[7]:
        return <CommunicationPreferences setActiveCard={setActiveCard} handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <TabButtons onTabChange={onTabChange} tabs={personalDataPageTabs} activeTab={activeTab} />
      {renderTabSection(activeTab)}
    </div>
  );
};
