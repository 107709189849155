import React, { useEffect, useRef, useState } from "react";
import { formatCaseType } from "../../utils/helpers";
import { ConfigureTabProps, TabButtonsProps } from "../../utils/types";

import "./styles.tabbuttons.scss";
/**
 * A component that renders a set of tab buttons with an animated bar to indicate the active tab.
 *
 * @component
 * @param {TabButtonsProps} props - The props for the TabButtons component.
 * @param {ConfigureTabProps[]} props.tabs - An array of tabs to be displayed as buttons.
 * @param {ConfigureTabProps | null} props.activeTab - The currently active tab.
 * @param {(tab: ConfigureTabProps) => void} props.onTabChange - Callback function to handle tab change events.
 *
 * @returns {JSX.Element} A rendered component with tab buttons and an animated bar.
 */
const TabButtons: React.FC<TabButtonsProps> = ({ tabs, activeTab, onTabChange }: TabButtonsProps): JSX.Element => {
  const [barWidth, setBarWidth] = useState<number>(0);
  const [barLeft, setBarLeft] = useState<number>(0);
  const buttonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>({});

  useEffect(() => {
    const activeButton = activeTab && buttonRefs.current[activeTab.value];
    if (activeButton) {
      setBarWidth(activeButton.offsetWidth);
      setBarLeft(activeButton.offsetLeft);
    }
  }, [activeTab, tabs]);

  const handleTabClick = (tab: ConfigureTabProps) => {
    onTabChange(tab);
  };

  return (
    <div className='tab-buttons-container'>
      <div className='tab-buttons-wrapper'>
        <div className='tab-buttons'>
          <div className='tab-buttons__bar' style={{ width: `${barWidth}px`, left: `${barLeft}px` }}></div>
          {tabs.map((tab, index) => (
            <button
              key={`${tab.id}_${index}_${tab.value}`}
              ref={(el) => (buttonRefs.current[tab.value] = el)}
              className={`tab-buttons__button ${activeTab && tab.value === activeTab.value ? "active" : ""}`}
              onClick={() => handleTabClick(tab)}
            >
              {formatCaseType(tab?.label || "")}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TabButtons;
