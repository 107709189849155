import { Dispatch } from "@reduxjs/toolkit";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { setSelectedParticipant } from "../../../../redux/slices/participantsSlice";
import { AppDispatch, RootState } from "../../../../redux/types";
import { ACTION_COLUMN_STYLE, baseJurorColumns, STRINGS } from "../../../../utils/constants";
import routes from "../../../../utils/constants/routes";
import useSocket from "../../../../utils/hooks/sockets";
import { Juror, KickOutParticipantData } from "../../../../utils/types";
import { PersonInfo } from "../../../../utils/types";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import ConfirmationModal from "../../../ConfirmationModal";
import PersonInfoModal from "../../../PersonInfoModal";
import WrapperComponent from "../../../WrapperComponent";
import { fetchTrialTeam, kickOutParticipant } from "../helper";

const TrialTeamTab: React.FC = () => {
  const { socket, socketEmit } = useSocket();
  const fetchedRef = useRef(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<PersonInfo | null>(null);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [selectedJuror, setSelectedJuror] = useState<Juror | null>(null);
  const { trialTeam, trialLoading } = useSelector((state: RootState) => state.participants);
  const user = useSelector((state: RootState) => state.users.user);

  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (socket && selectedCase) {
      fetchTrialTeam(selectedCase, user!, fetchedRef, socketEmit);
    }
  }, [socket, selectedCase, user, socketEmit]);

  const handleViewPersonInfo = (person: PersonInfo) => {
    setSelectedPerson(person);
    setIsModalOpen(true);
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const JurorColumns: ColDef[] = [
    ...baseJurorColumns,

    {
      ...ACTION_COLUMN_STYLE,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          editable={true}
          kickOut={true}
          deletable={false}
          data={params.data}
          onKickOut={() => {
            setSelectedJuror(params.data);
            setIsConfirmationModalOpen(true);
          }}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
              state: { participantData: params.data, isTrial: true, from: location.pathname }
            });
          }}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            handleViewPersonInfo(params.data);
            navigate(routes.PARTICIPANT_DETAILS, {
              state: { participantData: params.data, isTrial: true, from: location.pathname }
            });
          }}
        />
      )
    }
  ];

  const onConfirmKickOut = useCallback(() => {
    if (selectedJuror) {
      const kickOutData: KickOutParticipantData = {
        ...selectedJuror,
        caseParticipants: Array.isArray(selectedJuror.caseParticipants)
          ? selectedJuror.caseParticipants
          : selectedJuror.caseParticipants
            ? [selectedJuror.caseParticipants]
            : []
      };

      kickOutParticipant(kickOutData, user?.id, socketEmit);
      setIsConfirmationModalOpen(false);
    }
  }, [selectedJuror, user, socketEmit]);

  return (
    <WrapperComponent title='Trial Team' customClass='mb-2'>
      <div style={{ height: "80vh" }}>
        <AgGrid
          loading={trialLoading}
          columnDefs={JurorColumns}
          rowData={trialTeam}
          suppressContextMenu={true}
          getContextMenuItems={() => {
            return [];
          }}
        />
      </div>

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}

      {isConfirmationModalOpen && (
        <ConfirmationModal
          onCancel={() => setIsConfirmationModalOpen(false)}
          message={STRINGS.ARE_YOUR_SURE_KICK_OUT}
          onConfirm={onConfirmKickOut}
        />
      )}
    </WrapperComponent>
  );
};

export default TrialTeamTab;
