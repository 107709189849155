import { ValueFormatterParams, ValueGetterParams } from "ag-grid-community";
import moment from "moment";

export const colDef = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "age",
    headerName: "Age",
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: "agTextColumnFilter",
    type: "rightAligned",
    floatingFilter: true
  }
];

export const ClientColumns = [
  {
    field: "firstName",
    headerName: "First Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: "lastName",
    headerName: "Last Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 250,
    floatingFilter: true
  },

  {
    field: "phoneNumber",
    headerName: "Phone",
    sortable: true,
    unSortIcon: true,
    minWidth: 200,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "city",
    headerName: "City",
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "researchingStatus",
    headerName: "Status",
    sortable: true,
    unSortIcon: true,
    minWidth: 200,
    filter: "agTextColumnFilter",
    floatingFilter: true
  }
];

export const gridData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "New York",
    actions: "View Details"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "San Francisco",
    actions: "View Details"
  },
  {
    firstName: "James",
    lastName: "Brown",
    email: "james.brown@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Chicago",
    actions: "View Details"
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emily.davis@example.com",
    phoneNumber: "+1-202-555-0111",
    city: "Los Angeles",
    actions: "View Details"
  }
];

export const participantGridData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "New York",
    actions: "View Details"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "San Francisco",
    actions: "View Details"
  },
  {
    firstName: "James",
    lastName: "Brown",
    email: "james.brown@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Chicago",
    actions: "View Details"
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emily.davis@example.com",
    phoneNumber: "+1-202-555-0111",
    city: "Los Angeles",
    actions: "View Details"
  }
];

export const candidatesGridData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "New York",
    actions: "View Details"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "San Francisco",
    actions: "View Details"
  },
  {
    firstName: "James",
    lastName: "Brown",
    email: "james.brown@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Chicago",
    actions: "View Details"
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emily.davis@example.com",
    phoneNumber: "+1-202-555-0111",
    city: "Los Angeles",
    actions: "View Details"
  }
];

export const witnessesGridData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "New York",
    actions: "View Details"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "San Francisco",
    actions: "View Details"
  },
  {
    firstName: "James",
    lastName: "Brown",
    email: "james.brown@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Chicago",
    actions: "View Details"
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emily.davis@example.com",
    phoneNumber: "+1-202-555-0111",
    city: "Los Angeles",
    actions: "View Details"
  }
];

export const expertsGridData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "New York",
    actions: "View Details"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "San Francisco",
    actions: "View Details"
  },
  {
    firstName: "James",
    lastName: "Brown",
    email: "james.brown@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Chicago",
    actions: "View Details"
  },
  {
    firstName: "Emily",
    lastName: "Davis",
    email: "emily.davis@example.com",
    phoneNumber: "+1-202-555-0111",
    city: "Los Angeles",
    actions: "View Details"
  }
];

export const trialTeamColumns = [
  {
    headerName: "#",
    valueGetter: "node.rowIndex + 1",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "name",
    headerName: "Full Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 140,
    floatingFilter: true
  },
  {
    field: "juryId",
    headerName: "Jury ID",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 145,
    floatingFilter: true
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "age",
    headerName: "Age",
    sortable: true,
    unSortIcon: true,
    minWidth: 145,
    filter: "agTextColumnFilter",
    floatingFilter: true
  }
];

export const trialTeamGridData = [
  {
    name: "John Doe",
    juryId: "J12345",
    age: 30,
    gender: "Male",
    occupation: "Software Engineer",
    education: "B.Sc. in Computer Science",
    city: "New York",
    email: "john.doe@example.com",
    jurorRate: 4.5,
    actions: "View Details"
  },
  {
    name: "Jane Smith",
    juryId: "J67890",
    age: 28,
    gender: "Female",
    occupation: "Graphic Designer",
    education: "B.A. in Graphic Design",
    city: "San Francisco",
    email: "jane.smith@example.com",
    jurorRate: 4.7,
    actions: "View Details"
  },
  {
    name: "James Brown",
    juryId: "J54321",
    age: 35,
    gender: "Male",
    occupation: "Project Manager",
    education: "MBA",
    city: "Chicago",
    email: "james.brown@example.com",
    jurorRate: 4.2,
    actions: "View Details"
  },
  {
    name: "Emily Davis",
    juryId: "J98765",
    age: 32,
    gender: "Female",
    occupation: "Marketing Specialist",
    education: "B.Sc. in Marketing",
    city: "Los Angeles",
    email: "emily.davis@example.com",
    jurorRate: 4.8,
    actions: "View Details"
  },
  {
    name: "Michael Johnson",
    juryId: "J11111",
    age: 40,
    gender: "Male",
    occupation: "Data Analyst",
    education: "M.Sc. in Data Science",
    city: "Houston",
    email: "michael.johnson@example.com",
    jurorRate: 4.6,
    actions: "View Details"
  },
  {
    name: "Linda Martinez",
    juryId: "J22222",
    age: 29,
    gender: "Female",
    occupation: "Content Writer",
    education: "B.A. in English Literature",
    city: "Miami",
    email: "linda.martinez@example.com",
    jurorRate: 4.4,
    actions: "View Details"
  },
  {
    name: "Robert Wilson",
    juryId: "J33333",
    age: 45,
    gender: "Male",
    occupation: "Sales Executive",
    education: "B.B.A. in Marketing",
    city: "Seattle",
    email: "robert.wilson@example.com",
    jurorRate: 4.3,
    actions: "View Details"
  },
  {
    name: "Sophia Taylor",
    juryId: "J44444",
    age: 37,
    gender: "Female",
    occupation: "HR Manager",
    education: "M.A. in Human Resources",
    city: "Austin",
    email: "sophia.taylor@example.com",
    jurorRate: 4.9,
    actions: "View Details"
  }
];

export const baseJurorColumns = [
  {
    field: "firstName",
    headerName: "First Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 180,
    floatingFilter: true
  },
  {
    field: "lastName",
    headerName: "Last Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 150,
    floatingFilter: true
  },
  {
    headerName: "Juror ID",
    sortable: true,
    unSortIcon: true,
    minWidth: 150,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    field: "data.caseParticipants?.[0]?.jurorId",
    valueGetter: (params: ValueGetterParams) => {
      return params.data?.caseParticipants?.[0]?.jurorId || "N/A"; // Use optional chaining
    }
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    unSortIcon: true,
    minWidth: 250,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    sortable: true,
    unSortIcon: true,
    minWidth: 200,
    filter: "agTextColumnFilter",
    floatingFilter: true
  }
];

export const jurorGridData = [
  {
    id: 1,
    firstName: "Emily",
    lastName: "Johnson",
    seatNumber: 1,
    email: "emily@example.com",
    phoneNumber: "+1-202-555-0175",
    city: "Anytown",
    jurorRate: "25%",
    actions: "actions"
  },
  {
    id: 2,
    firstName: "Michael",
    lastName: "Rodriguez",
    seatNumber: 2,
    email: "michael@example.com",
    phoneNumber: "+1-202-555-0143",
    city: "Anytown",
    jurorRate: "90%",
    actions: "actions"
  },
  {
    id: 3,
    firstName: "Jennifer",
    lastName: "Smith",
    seatNumber: 3,
    email: "jennifer@example.com",
    phoneNumber: "+1-202-555-0198",
    city: "Anytown",
    jurorRate: "75%",
    actions: "actions"
  },
  {
    id: 4,
    firstName: "David",
    lastName: "Thompson",
    seatNumber: 4,
    email: "david@example.com",
    phoneNumber: "+1-202-555-0156",
    city: "Anytown",
    jurorRate: "10%",
    actions: "actions"
  },
  {
    id: 5,
    firstName: "Amanda",
    lastName: "Wilson",
    seatNumber: 5,
    email: "amanda@example.com",
    phoneNumber: "+1-202-555-0188",
    city: "Anytown",
    jurorRate: "85%",
    actions: "actions"
  },
  {
    id: 6,
    firstName: "Daniel",
    lastName: "Brown",
    seatNumber: 6,
    email: "daniel@example.com",
    phoneNumber: "+1-202-555-0199",
    city: "Anytown",
    jurorRate: "10%",
    actions: "actions"
  },
  {
    id: 7,
    firstName: "Sarah",
    lastName: "Martinez",
    seatNumber: 7,
    email: "sarah@example.com",
    phoneNumber: "+1-202-555-0167",
    city: "Anytown",
    jurorRate: "68%",
    actions: "actions"
  },
  {
    id: 8,
    firstName: "Christopher",
    lastName: "Taylor",
    seatNumber: 8,
    email: "chris@example.com",
    phoneNumber: "+1-202-555-0155",
    city: "Anytown",
    jurorRate: "92%",
    actions: "actions"
  },
  {
    id: 9,
    firstName: "Michelle",
    lastName: "Lee",
    seatNumber: 9,
    email: "michelle@example.com",
    phoneNumber: "+1-202-555-0172",
    city: "Anytown",
    jurorRate: "40%",
    actions: "actions"
  },
  {
    id: 10,
    firstName: "Jason",
    lastName: "Harris",
    seatNumber: 10,
    email: "jason@example.com",
    phoneNumber: "+1-202-555-0190",
    city: "Anytown",
    jurorRate: "70%",
    actions: "actions"
  },
  {
    id: 11,
    firstName: "Emily",
    lastName: "Clark",
    seatNumber: 11,
    email: "emily@example.com",
    phoneNumber: "+1-202-555-0181",
    city: "Springfield",
    jurorRate: "85%",
    actions: "actions"
  },
  {
    id: 12,
    firstName: "Michael",
    lastName: "Smith",
    seatNumber: 12,
    email: "michael@example.com",
    phoneNumber: "+1-202-555-0123",
    city: "Centerville",
    jurorRate: "90%",
    actions: "actions"
  },
  {
    id: 13,
    firstName: "Sophia",
    lastName: "Johnson",
    seatNumber: 13,
    email: "sophia@example.com",
    phoneNumber: "+1-202-555-0145",
    city: "Greenfield",
    jurorRate: "75%",
    actions: "actions"
  },
  {
    id: 14,
    firstName: "David",
    lastName: "Brown",
    seatNumber: 14,
    email: "david@example.com",
    phoneNumber: "+1-202-555-0167",
    city: "Riverdale",
    jurorRate: "80%",
    actions: "actions"
  },
  {
    id: 15,
    firstName: "Olivia",
    lastName: "Williams",
    seatNumber: 15,
    email: "olivia@example.com",
    phoneNumber: "+1-202-555-0198",
    city: "Lakeside",
    jurorRate: "88%",
    actions: "actions"
  },
  {
    id: 16,
    firstName: "William",
    lastName: "Taylor",
    seatNumber: 16,
    email: "william@example.com",
    phoneNumber: "+1-202-555-0172",
    city: "Hillsborough",
    jurorRate: "95%",
    actions: "actions"
  },
  {
    id: 17,
    firstName: "Ava",
    lastName: "Anderson",
    seatNumber: 17,
    email: "ava@example.com",
    phoneNumber: "+1-202-555-0134",
    city: "Fairview",
    jurorRate: "78%",
    actions: "actions"
  },
  {
    id: 18,
    firstName: "James",
    lastName: "Martinez",
    seatNumber: 18,
    email: "james@example.com",
    phoneNumber: "+1-202-555-0119",
    city: "Mapleton",
    jurorRate: "82%",
    actions: "actions"
  },
  {
    id: 19,
    firstName: "Isabella",
    lastName: "Garcia",
    seatNumber: 19,
    email: "isabella@example.com",
    phoneNumber: "+1-202-555-0189",
    city: "Brookfield",
    jurorRate: "89%",
    actions: "actions"
  },
  {
    id: 20,
    firstName: "Henry",
    lastName: "Robinson",
    seatNumber: 20,
    email: "henry@example.com",
    phoneNumber: "+1-202-555-0101",
    city: "Oakwood",
    jurorRate: "92%",
    actions: "actions"
  }
];

export const baseCaseEvidenceColumns = [
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 600,
    floatingFilter: true
  },
  {
    field: "docType",
    headerName: "Doc Type",
    sortable: true,
    unSortIcon: true,
    minWidth: 135,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "size",
    headerName: "Size",
    sortable: true,
    unSortIcon: true,
    minWidth: 115,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    sortable: true,
    unSortIcon: true,
    minWidth: 160,
    filter: "agDateColumnFilter",
    floatingFilter: true
  },
  {
    field: "addedBy",
    headerName: "Added by",
    sortable: true,
    unSortIcon: true,
    minWidth: 140,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    tooltipField: "addedBy"
  }
];

export const caseEvidenceGridData = [
  {
    id: 1,
    description: "Examination of a witness Sara Polson",
    docType: ".doc",
    size: "21 MB",
    dateAdded: "04/25/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    id: 3,
    description: "Notes of a meeting between Jackson's attorney and the prosecutor (Johnson's case)",
    docType: ".doc",
    size: "23 MB",
    dateAdded: "04/25/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    id: 3,
    description: "Consultant's Report (Alise White)",
    docType: ".pptx",
    size: "28 MB",
    dateAdded: "04/27/2024",
    addedBy: "Alise White",
    actions: "actions"
  },
  {
    id: 3,
    description: "Juror Evaluation Generated Info (Jury List #97)",
    docType: ".xls",
    size: "31 MB",
    dateAdded: "04/27/2024",
    addedBy: "Robert Fox",
    actions: "actions"
  },
  {
    id: 3,
    description: "April's Work Report (Mike Hardworker)",
    docType: ".xls",
    size: "21 MB",
    dateAdded: "05/01/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    id: 3,
    description: "Financial report of the Jury – Jury List #235",
    docType: ".pdf",
    size: "28 MB",
    dateAdded: "05/01/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    id: 3,
    description: "Federal Court Visualization, Houston TX",
    docType: ".aps",
    size: "31 MB",
    dateAdded: "05/02/2024",
    addedBy: "Robert Fox",
    actions: "actions"
  }
];

export const baseDocsColumns = [
  {
    field: "documentName",
    headerName: "Document Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 450,
    floatingFilter: true
  },
  {
    field: "docType",
    headerName: "Doc Type",
    sortable: true,
    unSortIcon: true,
    minWidth: 135,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "caseName",
    headerName: "Case Name",
    sortable: true,
    unSortIcon: true,
    minWidth: 200,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "clientName",
    headerName: "Client Name",
    sortable: true,
    unSortIcon: true,
    minWidth: 150,
    filter: "agTextColumnFilter",
    floatingFilter: true
  },
  {
    field: "dateAdded",
    headerName: "Date Added",
    sortable: true,
    unSortIcon: true,
    minWidth: 160,
    filter: "agDateColumnFilter",
    floatingFilter: true
  },
  {
    field: "addedBy",
    headerName: "Added by",
    sortable: true,
    unSortIcon: true,
    minWidth: 180,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    tooltipField: "addedBy"
  }
];

export const caseDocsGridData = [
  {
    documentName: "Financial report of the Jury - Jury List #123",
    docType: ".xls",
    caseName: "State of NC Vs James",
    clientName: "Johnson",
    dateAdded: "04/25/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    documentName: "Notes of a meeting between Jackson's attorney and the prosecutor",
    docType: ".doc",
    caseName: "State of NC Vs James",
    clientName: "Lane",
    dateAdded: "04/25/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    documentName: "Consultant's Report (Alise White)",
    docType: ".pptx",
    caseName: "State of NC Vs James",
    clientName: "Edwards",
    dateAdded: "04/27/2024",
    addedBy: "Alise White",
    actions: "actions"
  },
  {
    documentName: "Juror Evaluation Generated Info (Jury List #97)",
    docType: ".xls",
    caseName: "State of NC Vs James",
    clientName: "Hawkins",
    dateAdded: "04/27/2024",
    addedBy: "Robert Fox",
    actions: "actions"
  },
  {
    documentName: "April's Work Report (Mike Hardworker)",
    docType: ".xls",
    caseName: "State of NC Vs James",
    clientName: "Johnson",
    dateAdded: "05/01/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    documentName: "Financial report of the Jury - Jury List #235",
    docType: ".pdf",
    caseName: "State of NC Vs James",
    clientName: "Warren",
    dateAdded: "05/01/2024",
    addedBy: "Mick Hardworker",
    actions: "actions"
  },
  {
    documentName: "Federal Court Visualization, Houston TX",
    docType: ".eps",
    caseName: "State of NC Vs James",
    clientName: "Hawkins",
    dateAdded: "05/02/2024",
    addedBy: "Robert Fox",
    actions: "actions"
  }
];

export const ACTION_COLUMN_STYLE = {
  field: "actions",
  headerName: "Actions",
  minWidth: 140,
  width: 140,
  maxWidth: 140,
  cellStyle: { borderColor: "transparent" },
  headerClass: "actions-header-col"
};

export const baseCaseColumns = [
  {
    field: "caseName",
    headerName: "Case Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  },
  {
    field: "courtCaseNumber",
    headerName: "Case Number",
    sortable: true,
    unSortIcon: true,
    minWidth: 150,
    filter: "agTextColumnFilter",
    floatingFilter: true
  }
];
export const baseCompaniesColumns = [
  {
    field: "firstName",
    headerName: "First Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 180,
    floatingFilter: true
  },
  {
    field: "lastName",
    headerName: "Last Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 180,
    floatingFilter: true
  },
  {
    field: "email",
    headerName: "Email",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  },
  {
    field: "data.userId.role.name",
    headerName: "Role",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 180,
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data?.userId?.role?.name || "N/A"; // Use optional chaining
    }
  }
];

export const dummyCompaniesRowData = [
  {
    firstName: "John",
    lastName: "Doe",
    email: "john.doe@example.com"
  },
  {
    firstName: "Jane",
    lastName: "Smith",
    email: "jane.smith@example.com"
  },
  {
    firstName: "Michael",
    lastName: "Johnson",
    email: "michael.johnson@example.com"
  },
  {
    firstName: "Emily",
    lastName: "Williams",
    email: "emily.williams@example.com"
  },
  {
    firstName: "Chris",
    lastName: "Brown",
    email: "chris.brown@example.com"
  }
];

export const baseCompaniesNameColumns = [
  {
    field: "companyName",
    headerName: "Company Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  },
  {
    field: "createdAt",
    headerName: "Created At",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  }
];

export const baseAccountsColumns = [
  {
    field: "userFullName",
    headerName: "User Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      const user = params.data?.user;
      if (user) {
        return `${user.firstName || ""} ${user.lastName || ""}`;
      }
      return "-";
    }
  },
  {
    field: "provider",
    headerName: "Provider Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 180,
    floatingFilter: true
  },
  {
    field: "apiType",
    headerName: "Provider Type",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  },
  {
    field: "participantFullName",
    headerName: "Participant Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      const participant = params.data?.participant;
      if (participant) {
        return `${participant.firstName || ""} ${participant.lastName || ""}`;
      }
      return "-";
    }
  },
  {
    field: "caseName",
    headerName: "Case Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      const caseData = params.data?.case;
      if (caseData && typeof caseData === "object") {
        return `${caseData.caseName || ""}`;
      }
      return "-";
    }
  },
  {
    field: "companyName",
    headerName: "Company Name",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueGetter: (params: ValueGetterParams) => {
      const company = params.data?.company;
      if (company && typeof company === "object") {
        return `${company.companyName || ""}`;
      }
      return "-";
    }
  },
  {
    field: "price",
    headerName: "Provider Price",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value && params.value.$numberDecimal) {
        return `$${Number(params.value.$numberDecimal).toFixed(10)}`;
      }
      return params.value ? `$${Number(params.value).toFixed(10)}` : "";
    }
  },
  {
    field: "cost",
    headerName: "Provider Cost",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 200,
    floatingFilter: true,
    valueFormatter: (params: ValueFormatterParams) => {
      if (params.value && params.value.$numberDecimal) {
        return `$${Number(params.value.$numberDecimal).toFixed(10)}`;
      }
      return params.value ? `$${Number(params.value).toFixed(10)}` : "";
    }
  },
  {
    field: "description",
    headerName: "Description",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true
  },

  {
    field: "transactionDate",
    headerName: "Transaction Date",
    sortable: true,
    unSortIcon: true,
    filter: "agTextColumnFilter",
    minWidth: 300,
    floatingFilter: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return moment(params.value).format("YYYY-MM-DD hh:mm A");
    }
  }
];

export const companiesListRowsData = [
  {
    companyName: "Tech Innovators Ltd.",
    createdAt: "2023-01-15"
  },
  {
    companyName: "Global Solutions Inc.",
    createdAt: "2022-08-10"
  },
  {
    companyName: "Future Ventures",
    createdAt: "2021-11-05"
  },
  {
    companyName: "Green Earth Enterprises",
    createdAt: "2023-03-22"
  },
  {
    companyName: "Skyline Technologies",
    createdAt: "2022-12-30"
  }
];

export const caseListGridData = [
  {
    caseName: "State of NC Vs James Brown",
    courtCaseNumber: "1243",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs Emily Johnson",
    courtCaseNumber: "3254",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs Michael Rodriguez",
    courtCaseNumber: "8723",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs Sarah Martinez",
    courtCaseNumber: "7843",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs David Thompson",
    courtCaseNumber: "6253",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs Amanda Wilson",
    courtCaseNumber: "3242",
    actions: "actions"
  },
  {
    caseName: "State of NC Vs Daniel Brown",
    courtCaseNumber: "7793",
    actions: "actions"
  }
];

export const dummyValuesForParticipantsDynamicFields: Record<string, string> = {
  age: "-",
  gender: "-",
  education: "-",
  occupation: "-"
};

export const newColumnFieldsInParticipants = {
  Age: false,
  Gender: false,
  Occupation: false,
  Education: false
};

export const fields = ["Age", "Gender", "Occupation", "Education"];

export const DELETE_ICON_POSITION = { x: 1000, y: 365 };

export const demographicData = {
  individualLevelMatch: "High",
  dob: "1985-05-20",
  age: "39",
  ageRange: "35-44",
  gender: "Female",
  ethnicGroup: "Hispanic",
  religion: "Catholic",
  educationLevel: "Bachelor's Degree",
  occupation: "Software Engineer",
  language: "English, Spanish",
  maritalStatus: "Married",
  workingWomanInHousehold: "Yes",
  seniorInHousehold: "No",
  singleParent: "No",
  presenceOfChildren: "Yes",
  youngAdultInHousehold: "No",
  smallOfficeOrHomeOffice: "Yes",
  onlinePurchasingIndicator: "Active",
  onlineEducation: "Yes",
  homeOwnOrRent: "Own",
  householdIncome: "$75,000 - $99,999",
  estimatedNetWorth: "$250,000 - $500,000",
  homeYearBuilt: "2010",
  homePurchaseDate: "2012-03-15",
  homePurchasePrice: "$300,000",
  dwellingType: "Single Family Home",
  homeValue: "$350,000",
  lengthOfResidence: "8 years",
  creditCardHolderBank: "Bank of America",
  numberOfCreditLines: "4",
  upscaleCardHolder: "Yes",
  creditRating: "Good",
  mortgagePurchaseAmount: "$240,000",
  mortgagePurchaseLoanType: "Fixed-rate",
  mortgagePurchaseDate: "2012-04-01",
  secondMostRecentMortgageAmount: "$20,000",
  secondMostRecentMortgageLoanType: "Home Equity Loan",
  secondMostRecentMortgageInterestRateType: "Variable",
  secondMostRecentMortgageDate: "2017-08-10",
  loanToValue: "70%",
  refinanceDate: "2021-06-01",
  refinanceAmount: "$200,000",
  refinanceLoanType: "Fixed-rate",
  refinanceRateType: "Fixed",
  homePool: "Yes",
  partyAffiliation: "Democratic",
  donorEnvironmental: "Yes",
  donorAnimalWelfare: "No",
  donorArtsAndCulture: "Yes",
  donorChildrensCauses: "No",
  donorEnvironmentalOrWildlife: "Yes",
  donorHealth: "No",
  donorInternationalAid: "Yes",
  donorPolitical: "No",
  donorConservativePolitics: "No",
  donorLiberalPolitics: "Yes",
  donorReligious: "No",
  donorVeterans: "No",
  donorUnspecified: "No",
  donorCommunity: "Yes"
};
