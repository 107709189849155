import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { User, UserState } from "../../utils/types";

// Initial state
const initialState: UserState = {
  user: null,
  isLoggedIn: false,
  loading: false,
  error: null,
  role: null,
  username: "",
  token: null, // Add token to state
  companyInfo: null
};

// Create user slice
const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<{ user: User; token: string }>) => {
      state.user = action.payload.user;
      state.isLoggedIn = true;
      state.loading = false;
      state.error = null;
      state.username = `${action.payload?.user?.firstName || ""} ${action.payload?.user?.lastName || ""}`.trim();
      state.role = action?.payload?.user?.role?.name || ""; // Set user role
      state.token = action.payload.token; // Set token in Redux state
      state.companyInfo = action.payload?.user?.company?.companyInfo || null;
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");
      localStorage.removeItem("authToken");

      localStorage.setItem("authToken", action.payload.token);
      localStorage.setItem("userRole", action?.payload?.user?.role?.name || "");
      localStorage.setItem("user", JSON.stringify(action.payload?.user));
    },
    logout: (state) => {
      state.user = null;
      state.isLoggedIn = false;
      state.username = "";
      state.role = null;
      state.token = null; // Clear token on logout
      state.companyInfo = null;
      localStorage.removeItem("user");
      localStorage.removeItem("userRole");
      localStorage.removeItem("authToken");
      localStorage.removeItem("userChanged-event");
      localStorage.removeItem("login-event");
    },
    // Update user role
    setUserRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload; // Update the user's role
    },
    // Start login loading
    loginStart: (state) => {
      state.loading = true; // Set loading state
      state.error = null; // Clear any previous errors
    },
    // Handle login failure
    loginFailure: (state, action: PayloadAction<string>) => {
      state.loading = false; // Stop loading
      state.error = action.payload; // Set error message
    },
    // Reset error message
    resetError: (state) => {
      state.error = null; // Clear error message
    }
  }
});

// Persist configuration for user slice
const userPersistConfig = {
  key: "user",
  storage,
  whitelist: ["user", "isLoggedIn", "username", "role", "token", "companyInfo"] // Persist token as well
};

const persistedUserReducer = persistReducer(userPersistConfig, userSlice.reducer);

export const { setUser, logout, setUserRole, loginStart, loginFailure, resetError } = userSlice.actions;
export default persistedUserReducer;
