import React, { useMemo } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";
import { SidebarMenuLinkProps } from "./layout_types";

const SidebarMenuLink = ({ link, label, index }: SidebarMenuLinkProps) => {
  const location: Location = useLocation();

  const isActive = useMemo(() => location.pathname === link.route, [location.pathname, link.route]);

  return (
    <React.Fragment>
      <li className={isActive ? "mm-active" : ""}>
        {link.tooltip_en && (
          <UncontrolledTooltip placement='top' target={`tooltip-${index}`}>
            {link.tooltip_en}
          </UncontrolledTooltip>
        )}
        <Link to={link.route!} className={isActive ? "active" : ""} id={`tooltip-${index}`}>
          <p>
            <i className={link.icon!} />
            <span className='font-size-15'>{label}</span>
          </p>
          {link.badge_count && (
            <span className='badge badge-pill badge-primary float-right font-size-12'>{link.badge_count}</span>
          )}
        </Link>
      </li>
    </React.Fragment>
  );
};

export default SidebarMenuLink;
