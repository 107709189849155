import React from "react";
import { Spinner } from "reactstrap";
import { ActionButtonPropTypes } from "../../utils/types";

import "./styles.button.scss";

export const ActionButton = ({
  className = "",
  onClick,
  icon,
  title,
  disabled = false,
  loading = false,
  type = "button" // Default to "button" but allow overriding
}: ActionButtonPropTypes) => {
  return (
    <button
      type={type} // Use the passed type prop instead of hardcoding
      className={`button ${className} ${disabled || loading ? "disabled" : ""}`}
      onClick={disabled || loading ? undefined : onClick}
      disabled={disabled || loading}
    >
      {loading ? (
        <Spinner size='sm'>
          <span className='visually-hidden'>Loading...</span>
        </Spinner>
      ) : (
        <>
          {icon && <span className='button-icon'>{icon}</span>}
          {title && <span className='button-title'>{title}</span>}
        </>
      )}
    </button>
  );
};

export default ActionButton;
