export const API_END_POINTS = {
  LOGIN: "login",
  VERIFY_TOKEN: "verify-token",
  REFRESH_TOKEN: "refresh-token",
  VERIFY_EMAIL: "verify-email",
  UPLOAD_SINGLE_IMAGES: "upload/single/images",
  DELETE_FILE: "uploads/file/delete",
  UPDATE_FILE: "uploads/file/update/images",
  GENERATE_CODE: "anervaboard/generate-code",
  ATTACH_BOARD: "anervaboard/attach",
  PING_BOARD: "anervaboard/ping",
  BOARD_STATUS: "anervaboard/status"
};
