import { faAnglesRight, faChevronRight } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CollapsibleButtonProps {
  isCollapsed: boolean;
  onClick: () => void;
  hide?: boolean;
}

const CollapsibleButton: React.FC<CollapsibleButtonProps> = ({ isCollapsed, onClick, hide }) => (
  <div className='flex collapsible-button' onClick={onClick}>
    {hide ? (
      <FontAwesomeIcon icon={faChevronRight} />
    ) : isCollapsed ? (
      <FontAwesomeIcon icon={faAnglesRight} />
    ) : (
      <FontAwesomeIcon icon={faChevronRight} />
    )}
  </div>
);

export default CollapsibleButton;
