import { Dispatch } from "@reduxjs/toolkit";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ActionButton, BreadCrumbs, DeviceRegistrationModal } from "../../components";
import NavigationTab from "../../components/NavigationTabs";
import NavigationWrapper from "../../components/NavigationWrapper";
import {
  CaseEvidenceList,
  CaseTrialTeam,
  CreateCaseDocument,
  CreateNewCase,
  Documents,
  Participants
} from "../../components/Pages/CreateCase";
import { addDocument } from "../../redux/slices/documentsSlice";
import { setActiveTab } from "../../redux/slices/manageCaseTabSlice";
import { AppDispatch, RootState } from "../../redux/types";
import { BUTTON_TITLE, CASE_TABS, NavigationTabs, TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { formatFileSize } from "../../utils/helpers";
import { CaseEvidence, DocumentFormValues } from "../../utils/types";

const ManageCase = () => {
  const [documentModalShow, setDocumentModalShow] = useState<boolean>(false);
  const [deviceBoardModalShow, setDeviceBoardModalShow] = useState<boolean>(false);
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const { selectedCase } = useSelector((state: RootState) => state.cases);

  const tabs = useSelector((state: RootState) => state.manageCaseTab.tabs);
  const handleTabChange = useCallback(
    (newActiveTab: string) => {
      dispatch(setActiveTab(newActiveTab));
    },
    [dispatch]
  );

  const renderActiveTabContent = () => {
    const activeTab = tabs.find((tab) => tab.status === "current")?.id;
    switch (activeTab) {
      case CASE_TABS.CASE_DETAILS:
        return <CreateNewCase handleTabChange={handleTabChange} caseData={selectedCase || undefined} />;
      case CASE_TABS.PARTICIPANTS:
        return <Participants caseData={selectedCase || undefined} handleTabChange={handleTabChange} />;
      case CASE_TABS.TRIAL_TEAM:
        return <CaseTrialTeam handleTabChange={handleTabChange} />;
      case CASE_TABS.CASE_EVIDENCE:
        return <CaseEvidenceList handleTabChange={handleTabChange} />;
      case CASE_TABS.DOCUMENTS:
        return <Documents handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  const renderActiveTabHeader = useMemo(() => {
    const activeTab = tabs.find((tab) => tab.status === "current")?.id;

    switch (activeTab) {
      case CASE_TABS.TRIAL_TEAM:
        return (
          <div className='d-flex gap-1'>
            <ActionButton
              className='font-size-16 px-2'
              title={BUTTON_TITLE.ATTACH_ANERVA_BOARD}
              icon={<i className='fa-regular fa-plus'></i>}
              onClick={() => {
                setDeviceBoardModalShow(true);
              }}
            />
          </div>
        );
      case CASE_TABS.CASE_EVIDENCE:
        return (
          <div className='d-flex flex-row justify-content-between align-items-center gap-1'>
            <ActionButton
              className='bg-blue font-size-16 px-3'
              title={BUTTON_TITLE.ADD_NEW_EVIDENCE}
              onClick={() => {
                navigate(routes.CASE_EVIDENCE);
              }}
              icon={<i className='fa-solid fa-plus'></i>}
            />
            <ActionButton
              className='bg-primary-light font-size-16 px-2'
              title={BUTTON_TITLE.DOWNLOAD_FILES}
              onClick={() => {
                alert(BUTTON_TITLE.DOWNLOAD_FILES);
              }}
              icon={<i className='fa-regular fa-layer-group'></i>}
            />
          </div>
        );
      case CASE_TABS.DOCUMENTS:
        return (
          <div className='d-flex gap-1'>
            <ActionButton
              className='font-size-16 px-2'
              title={BUTTON_TITLE.ADD_DOCUMENT}
              icon={<i className='fa-regular fa-plus'></i>}
              onClick={() => {
                setDocumentModalShow(true);
              }}
            />
            <ActionButton
              className='bg-primary-light font-size-16 px-2'
              title={BUTTON_TITLE.DOWNLOAD_DOCUMENTS}
              icon={<i className='fa-regular fa-layer-group'></i>}
              onClick={() => {
                alert(BUTTON_TITLE.DOWNLOAD_DOCUMENTS);
              }}
            />
          </div>
        );
      default:
        return null;
    }
  }, [navigate, tabs]);

  const breadcrumbItems = useMemo(() => {
    const previousPage = location.state?.from;

    if (previousPage === routes.DASHBOARD) {
      return [
        { text: "Dashboard", url: routes.DASHBOARD },
        { text: "Manage Case", url: routes.MANAGE_CASE }
      ];
    }

    return [
      { text: "Case List", url: routes.CASES_LIST },
      { text: "Manage Case", url: routes.MANAGE_CASE }
    ];
  }, [location.state]);

  const handleAddNewDocument = useCallback(
    (values: DocumentFormValues) => {
      const file = values.files && values.files[0];
      if (file) {
        const fileExtension = file.name.split(".").pop();
        const fileSize = formatFileSize(file.size);
        const newDocument: CaseEvidence = {
          id: Math.random(),
          description: file.name || values.documentName || "",
          docType: fileExtension ? `.${fileExtension}` : "unknown",
          size: fileSize,
          dateAdded: moment().format("DD/MM/YYYY"),
          addedBy: "Anerva"
        };

        dispatch(addDocument(newDocument));
        toast.success("Document added successfully");
      }
    },
    [dispatch]
  );

  return (
    <div className='scrollable-container'>
      <BreadCrumbs breadcrumbItems={breadcrumbItems} />

      <NavigationWrapper
        title={`${TITLES.MANAGE_CASE}: ${selectedCase?.caseName || ""}`}
        headerChild={renderActiveTabHeader}
        titleClass='color-primary-dark font-size-18 fw-bold'
      >
        <NavigationTab
          tabs={tabs}
          activeTab={tabs.find((tab) => tab.status === "current")?.id || NavigationTabs[0].id}
          setActiveTab={handleTabChange}
        />
        {renderActiveTabContent()}
      </NavigationWrapper>
      {deviceBoardModalShow && (
        <DeviceRegistrationModal
          showModal={deviceBoardModalShow}
          onClose={() => {
            setDeviceBoardModalShow(false);
          }}
        />
      )}
      {documentModalShow && (
        <CreateCaseDocument
          showModal={documentModalShow}
          setShowModal={(value: boolean) => setDocumentModalShow(value)}
          onAddDocument={handleAddNewDocument}
        />
      )}
    </div>
  );
};

export default ManageCase;
