import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import UserRoleCard from "./userRoleCard";
import NavigationWrapper from "../../../components/NavigationWrapper";
import SelectComponent from "../../../components/SelectComponent";
import WrapperComponent from "../../../components/WrapperComponent";
import { RootState } from "../../../redux/store";
import { TITLES } from "../../../utils/constants";
import useSocket from "../../../utils/hooks/sockets";
import { Company } from "../../../utils/types";
import { fetchAllCompaniesFromServer } from "../companies/helpers";
import { fetchAllCompanyUsersFromServer } from "../companyDetails/helpers";

import "./styles.switchUserRoles.scss";

type SwitchUserRolesProps = object;

type SelectedUserCompanyType = {
  value: string;
  company: Company | null;
};

const SwitchUserRoles: React.FC<SwitchUserRolesProps> = () => {
  const { companiesList, selectedCompany } = useSelector((state: RootState) => state.companies);
  const user = useSelector((state: RootState) => state.users.user);
  const { companyUsersList } = useSelector((state: RootState) => state.companyUsers);
  const [selectedUserCompany, setSelectedCompany] = useState<SelectedUserCompanyType>({ value: "", company: null });
  const { socketEmit } = useSocket();
  const fetchedRef = useRef(false);
  const fetchedRefCompanies = useRef(false);

  const companyOptions = useMemo(
    () =>
      companiesList.length
        ? companiesList.map((company: Company) => ({
            value: company.id as string,
            label: company.companyName,
            company: company
          }))
        : [],
    [companiesList]
  );

  useEffect(() => {
    fetchAllCompaniesFromServer(socketEmit, fetchedRef, user?.id || "");
  }, [socketEmit, user?.id]);

  useEffect(() => {
    if (selectedCompany && !selectedUserCompany.value) {
      setSelectedCompany({ value: selectedCompany?.id || "", company: selectedCompany });
      fetchAllCompanyUsersFromServer(socketEmit, selectedCompany?.id || "", user?.id || "", fetchedRefCompanies);
    }
  }, [selectedCompany, selectedUserCompany, socketEmit, user?.id]);

  const handleCompanySelect = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedOption = companyOptions.find((option) => option.value === event.target.value);
      if (selectedOption) {
        setSelectedCompany({ value: selectedOption.value, company: selectedOption.company });
        fetchAllCompanyUsersFromServer(socketEmit, selectedOption.value, user?.id || "");
      }
    },
    [companyOptions, socketEmit, user?.id]
  );

  return (
    <NavigationWrapper
      title={TITLES.SWITCH_USER_ROLES}
      goBack={false}
      headerChild={
        <div className='companies-dropdown'>
          <SelectComponent
            label='Company'
            hideLabel
            items={companyOptions}
            value={selectedUserCompany.value}
            onChange={handleCompanySelect}
            errorMessage={selectedUserCompany.value ? "" : "Please select a company"}
            layout='row'
            placeholder='Select a company'
          />
        </div>
      }
    >
      <WrapperComponent>
        <span className='company-name-header'>{selectedUserCompany?.company?.companyName || ""}</span>

        {selectedUserCompany.value ? (
          <UserRoleCard companyUsersList={companyUsersList} selectedUserCompany={selectedUserCompany} />
        ) : (
          <div className='noRecordsMessage'>Please select a company from the dropdown.</div>
        )}
      </WrapperComponent>
    </NavigationWrapper>
  );
};

export default SwitchUserRoles;
