import { Dispatch } from "@reduxjs/toolkit";
import { CellClickedEvent, ColDef, ICellRendererParams } from "ag-grid-community";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import CustomAddColumn from "./CustomAddColumn";
import { toggleField } from "./helpers";
import { updateJuror } from "../../../../redux/slices/jurorSlice";
import { setSelectedParticipant, setParticipantTab } from "../../../../redux/slices/participantsSlice";
import { AppDispatch, RootState } from "../../../../redux/types";
import {
  BUTTON_TITLE,
  ClientColumns,
  MEMBER_TYPES,
  participantTabButtons,
  STRINGS,
  TITLES
} from "../../../../utils/constants";
import {
  ACTION_COLUMN_STYLE,
  dummyValuesForParticipantsDynamicFields,
  newColumnFieldsInParticipants
} from "../../../../utils/constants/columns";
import routes from "../../../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import { calculateNewRate } from "../../../../utils/helpers";
import useSocket from "../../../../utils/hooks/sockets";
import {
  CaseListData,
  ConfigureTabProps,
  Participant,
  ParticipantsProps,
  SelectedReasons
} from "../../../../utils/types";
import AgGrid from "../../../AgGrid";
import ActionsRenderer from "../../../AgGrid/ActionGridRenderer";
import RangeSlider from "../../../AgGrid/GridRangeSlider";
import PersonInfoModal from "../../../PersonInfoModal";
import StrikeJurorModal from "../../../StrikeJurorModal";
import TabButtons from "../../../TabButtons";
import { WrapperComponent } from "../../../WrapperComponent";

// Define tab buttons for participants

const Participants: React.FC<ParticipantsProps> = () => {
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const fetchedRef = useRef(false);
  const { socket, socketEmit } = useSocket();
  const user = useSelector((state: RootState) => state.users.user);
  const { candidates, witnesses, experts, loading } = useSelector((state: RootState) => state.participants);
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const { activeParticipantTab } = useSelector((state: RootState) => state.participants);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedJurorForStrike, setSelectedJurorForStrike] = useState<Participant | null>(null);
  const [openStrikeModal, setOpenStrikeModal] = useState(false);
  const [activeTab, setActiveTab] = useState(participantTabButtons[0]);
  const [gridKey, setGridKey] = useState(`grid-key-${Math.random()}`);
  const selectedPerson = null;
  const [activeCandidatesFields, setActiveCandidatesFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });
  const [activeWitnessesFields, setActiveWitnessesFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });
  const [activeExpertsFields, setActiveExpertsFields] = useState<{ [key: string]: boolean }>({
    ...newColumnFieldsInParticipants
  });

  const [columnDefsCandidates, setColumnDefsCandidates] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);
  const [columnDefsWitnesses, setColumnDefsWitnesses] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);
  const [columnDefsExperts, setColumnDefsExperts] = useState<ColDef[]>([
    ...ClientColumns.map((col) => ({ ...col, field: col.field ?? "" }))
  ]);

  useEffect(() => {
    if (activeParticipantTab) {
      const tab = participantTabButtons.find((t) => t.value === activeParticipantTab);
      if (tab) setActiveTab(tab);
    }
  }, [activeParticipantTab]);

  useEffect(() => {
    fetchAllParticipantsFromServer();
    return () => {
      socket?.off(SOCKET_EVENTS.PARTICIPANT_DATA_NON_TRIAL);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, dispatch]);

  const handleToggleField = useCallback(
    (field: string, participantType: string) => {
      switch (participantType) {
        case STRINGS.Candidates:
          toggleField(
            field,
            participantType,
            activeCandidatesFields,
            setActiveCandidatesFields,
            columnDefsCandidates,
            setColumnDefsCandidates,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        case STRINGS.Witnesses:
          toggleField(
            field,
            participantType,
            activeWitnessesFields,
            setActiveWitnessesFields,
            columnDefsWitnesses,
            setColumnDefsWitnesses,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        case STRINGS.Experts:
          toggleField(
            field,
            participantType,
            activeExpertsFields,
            setActiveExpertsFields,
            columnDefsExperts,
            setColumnDefsExperts,
            dummyValuesForParticipantsDynamicFields
          );
          break;
        default:
          break;
      }

      setGridKey(`grid-key-${Math.random()}`); // Regenerate gridKey to force re-render
    },
    [
      activeCandidatesFields,
      activeExpertsFields,
      activeWitnessesFields,
      columnDefsCandidates,
      columnDefsExperts,
      columnDefsWitnesses
    ]
  );

  const fetchAllParticipantsFromServer = () => {
    if (fetchedRef.current) return;
    if (socket) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        {
          type: SOCKET_EVENTS.FETCH_PARTICIPANT_NON_TRIAL,
          eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
          userId: user?.id || "",
          caseId: selectedCase?.id,
          requestId: uuidv4()
        },
        () => {
          fetchedRef.current = true;
        }
      );
    }
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleDelete = (rowData: Participant) => {
    handleRemovePerson(rowData.id, user?.id || "", selectedCase?.id);
  };

  const handleOpenStrikeModal = useCallback((juror: Participant) => {
    setSelectedJurorForStrike(juror);
    setOpenStrikeModal(true);
  }, []);

  const handleStrikeSubmit = useCallback(
    (data: { selectedReasons: SelectedReasons }) => {
      if (selectedJurorForStrike) {
        const currentRate = selectedJurorForStrike?.jurorRate || "0";
        const newRate = calculateNewRate(currentRate, data.selectedReasons);
        const updatedJurors = { ...selectedJurorForStrike, jurorRate: `${newRate}%` };
        dispatch(updateJuror(updatedJurors));
        setOpenStrikeModal(false);
        setSelectedJurorForStrike(null);
      }
    },
    [dispatch, selectedJurorForStrike]
  );

  const handleRemovePerson = (
    participantId: string | undefined,
    userId: string | undefined,
    caseId: string | undefined
  ) => {
    const payload = {
      participantId,
      requestId: uuidv4(),
      type: SOCKET_EVENTS.DELETE_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
      userId,
      caseId
    };
    try {
      if (socketEmit)
        socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, ({ success, message }) => {
          if (success) toast.success(message);
          else toast.error(message);
        });
    } catch (error) {
      if (error instanceof Error) console.error("addMember error: ", error.message);
    }
  };

  const handleAddNew = useCallback(
    (path: string, caseData: CaseListData | undefined, memberType?: string) => {
      navigate(path, { state: { caseData, memberType, from: routes.MANAGE_CASE } });
    },
    [navigate]
  );

  const candidatesClientColumnsList: ColDef[] = [
    ...columnDefsCandidates,
    {
      field: "rate",
      headerName: "Rate",
      flex: 1,
      minWidth: 140,
      floatingFilter: true,

      onCellClicked: (event: CellClickedEvent) => {
        const { data } = event;
        handleOpenStrikeModal(data);
      },
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
              state: { participantData: params.data, from: location.pathname }
            });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.PARTICIPANT_DETAILS, { state: { participantData: params.data, from: location.pathname } });
          }}
        />
      )
    },
    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, STRINGS.Candidates)}
          activeFields={activeCandidatesFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  const witnessesClientColumnsList: ColDef[] = [
    ...columnDefsWitnesses,
    {
      field: "rate",
      headerName: "Rate",
      flex: 1,
      minWidth: 140,
      onCellClicked: (event: CellClickedEvent) => {
        const { data } = event;
        handleOpenStrikeModal(data);
      },
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
              state: { participantData: params.data, from: location.pathname }
            });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.PARTICIPANT_DETAILS, { state: { participantData: params.data, from: location.pathname } });
          }}
        />
      )
    },
    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, STRINGS.Witnesses)}
          activeFields={activeWitnessesFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  const expertsClientColumnsList: ColDef[] = [
    ...columnDefsExperts,
    {
      field: "rate",
      headerName: "Rate",
      flex: 1,
      minWidth: 140,
      onCellClicked: (event: CellClickedEvent) => {
        const { data } = event;
        handleOpenStrikeModal(data);
      },
      cellRendererFramework: (params: ICellRendererParams) => (
        <RangeSlider
          value={(params?.data?.caseParticipants[0]?.participantRatings[0]?.rating ?? 0) / 0.05}
          min={0}
          max={100}
          onClick={() => handleOpenStrikeModal(params.data)}
        />
      )
    },
    {
      ...ACTION_COLUMN_STYLE,
      headerClass: "actions-header-col",
      flex: 1,
      cellRendererFramework: (params: ICellRendererParams) => (
        <ActionsRenderer
          data={params.data}
          onEdit={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.CUSTOMIZABLE_EDIT_PARTICIPANTS, {
              state: { participantData: params.data, from: location.pathname }
            });
          }}
          onDelete={() => handleDelete(params.data as Participant)}
          onView={() => {
            dispatch(setSelectedParticipant(params.data));
            navigate(routes.PARTICIPANT_DETAILS, { state: { participantData: params.data, from: location.pathname } });
          }}
        />
      )
    },
    {
      field: "add",
      headerName: "",
      minWidth: 70,
      headerComponentFramework: () => (
        <CustomAddColumn
          onToggleField={(field) => handleToggleField(field, STRINGS.Experts)}
          activeFields={activeExpertsFields}
        />
      ),
      headerClass: "custom-header"
    }
  ];

  // New state and handlers for participant tabs

  const handleParticipantTabChange = useCallback(
    (tab: { label: string; value: string }) => {
      setActiveTab((prevTab) => ({ ...prevTab, ...tab }));
      dispatch(setParticipantTab(tab.value)); // Update Redux with the new tab value
    },
    [dispatch]
  );

  const renderActiveTabContent = () => {
    switch (activeTab.value) {
      case "CANDIDATES":
        return (
          <WrapperComponent
            title={TITLES.CANDIDATES}
            customClass='mb-2'
            buttonText={BUTTON_TITLE.ADD_NEW_CANDIDATE}
            onClick={() => handleAddNew(routes.ADD_NEW_CANDIDATE, selectedCase || undefined, MEMBER_TYPES[2])}
          >
            <div style={{ height: "80vh", width: "100%" }}>
              <AgGrid
                loading={loading}
                key={gridKey}
                columnDefs={candidatesClientColumnsList}
                rowData={candidates}
                onGridReady={(params) => {
                  params.api.sizeColumnsToFit();
                }}
                animateRows
              />
            </div>
          </WrapperComponent>
        );

      case "WITNESSES":
        return (
          <WrapperComponent
            title={TITLES.WITNESSES}
            customClass='mb-2'
            buttonText={BUTTON_TITLE.ADD_NEW_WITNESS}
            onClick={() => handleAddNew(routes.ADD_NEW_WITNESS, selectedCase || undefined, MEMBER_TYPES[0])}
          >
            <div style={{ height: "80vh", width: "100%" }}>
              <AgGrid
                loading={loading}
                key={gridKey}
                columnDefs={witnessesClientColumnsList}
                rowData={witnesses}
                animateRows
              />
            </div>
          </WrapperComponent>
        );

      case "EXPERTS":
        return (
          <WrapperComponent
            title={TITLES.EXPERTS}
            customClass=' mb-2'
            buttonText={BUTTON_TITLE.ADD_NEW_EXPERT}
            onClick={() => handleAddNew(routes.ADD_NEW_EXPERTS, selectedCase || undefined, MEMBER_TYPES[1])}
          >
            <div style={{ height: "80vh", width: "100%" }}>
              <AgGrid
                loading={loading}
                key={gridKey}
                columnDefs={expertsClientColumnsList}
                rowData={experts}
                animateRows
              />
            </div>
          </WrapperComponent>
        );

      default:
        return null;
    }
  };

  return (
    <div className='d-flex h-100 flex-column'>
      {/* Tab Buttons at the top */}
      <TabButtons
        tabs={participantTabButtons as unknown as ConfigureTabProps[]}
        activeTab={activeTab as unknown as ConfigureTabProps}
        onTabChange={handleParticipantTabChange}
      />

      {/* Render the currently active tab content */}
      {renderActiveTabContent()}

      {isModalOpen && <PersonInfoModal isOpen={isModalOpen} toggle={toggleModal} data={selectedPerson} />}
      <StrikeJurorModal
        isOpen={openStrikeModal}
        onClose={() => {
          setOpenStrikeModal(false);
          setSelectedJurorForStrike(null);
        }}
        onSubmit={handleStrikeSubmit}
        currentParticipant={selectedJurorForStrike}
      />
    </div>
  );
};

export default Participants;
