import { faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useState } from "react";
import { EnhancedLabeledInputProps } from "../../utils/types";

import "./styles.LabeledInput.scss";

const LabeledInput: React.FC<EnhancedLabeledInputProps> = ({
  label,
  placeholder,
  value,
  onChange,
  type = "email",
  error,
  hideLabel = false
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const handleToggleVisibility = useCallback(() => {
    setPasswordVisible((prevState) => !prevState);
  }, []);

  const inputType = type === "password" && isPasswordVisible ? "text" : type;

  return (
    <div className='label-container'>
      <div className='labeled-input'>
        {!hideLabel && <label className='labeled-input__label'>{label}</label>}
        <input
          type={inputType}
          className={`labeled-input__input ${type === "password" ? "pr-3" : ""} ${error ? "labeled-input__input--error" : ""}`}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          maxLength={type === "password" ? 100 : 350} // Set appropriate max length
          autoComplete={type === "password" ? "current-password" : "email"} // Enable browser auto-fill
          aria-required='true' // Indicate the field is required
          aria-invalid={!!error} // Indicate validation error status
          aria-describedby={error ? `${label}-error` : undefined} // Associate with error message if present
          autoFocus
        />
        {type === "password" ? (
          <span className='labeled-input__toggle-visibility' onClick={handleToggleVisibility}>
            <FontAwesomeIcon icon={isPasswordVisible ? faEyeSlash : faEye} />
          </span>
        ) : null}
      </div>
      {error && (
        <div id={`${label}-error`} className='labeled-input__error'>
          {error}
        </div>
      )}
    </div>
  );
};

export default LabeledInput;
