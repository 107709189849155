import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import { CaseListData, NewCaseFormValues, SocketEmitFunction } from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  caseName: Yup.string().required("Case name is required"),
  courtCaseNumber: Yup.string().required("Court case number is required"),
  clientName: Yup.string().optional(),
  caseStatus: Yup.string().optional(),
  courtJurisdiction: Yup.string().optional(),
  caseType: Yup.string().optional(),
  conflictCheck: Yup.boolean().optional(),
  trialStartDate: Yup.date().optional(),
  filingDate: Yup.date().optional(),
  hearingDates: Yup.array().of(Yup.date().optional()),
  injuries: Yup.boolean().optional(),
  dateOfIncident: Yup.date().optional(),
  defendantName: Yup.string().optional(),
  opposingCounselsName: Yup.string().optional(),
  existingCaseOrClient: Yup.string().optional(),
  arrestDate: Yup.date().optional(),
  additionalInformation: Yup.string().optional(),
  discoveryDeadline: Yup.date().optional()
});
export const caseDetailsValidationSchema = Yup.object().shape({
  caseName: Yup.string().required("Case name is required"),
  courtCaseNumber: Yup.string().required("Court case number is required"),
  clientName: Yup.string().optional(),
  caseStatus: Yup.string().optional(),
  courtJurisdiction: Yup.string().optional(),
  caseType: Yup.string().optional(),
  defendantName: Yup.string().optional(),
  opposingCounselsName: Yup.string().optional()
});
export const courtDatesValidationSchema = Yup.object().shape({
  trialStartDate: Yup.date().optional(),
  filingDate: Yup.date().optional(),
  discoveryDeadline: Yup.date().optional()
});
export const initialDateValues: NewCaseFormValues = {
  trialStartDate: null,
  filingDate: null,
  dateOfIncident: null,
  arrestDate: null,
  discoveryDeadline: null,
  customDates: []
};

export const initialValues: NewCaseFormValues = {
  caseName: "",
  courtCaseNumber: "",
  clientName: "",
  caseStatus: "",
  courtJurisdiction: "",
  caseType: "",
  conflictCheck: false,
  injuries: false,
  dateOfIncident: null,
  arrestDate: null,
  discoveryDeadline: null,
  additionalInformation: ""
};

export const fetchDateValue = (values: Record<string, unknown>, name: string): Date => {
  const val = values[name as keyof typeof values];

  // Check if `val` is a valid input for the Date constructor
  if (val) {
    // Check if it's already a Date instance
    if (val instanceof Date) {
      return val;
    }

    // Check if it's a valid string or number to be converted to a Date
    if (typeof val === "string" || typeof val === "number") {
      return new Date(val);
    }
  }

  // Fallback to the current date if `val` is not valid
  return new Date();
};

/**
 * Helper function to update case details using sockets
 * @param socketEmit - The socket emit function
 * @param values - The case form values
 * @param userId - The user ID
 * @param caseId - The case ID (optional)
 */
export const updateCaseDetails = (
  socketEmit: SocketEmitFunction,
  values: NewCaseFormValues,
  userId: string,
  caseId?: string,
  companyId?: string
) => {
  if (!values) return;

  const caseDetails = {
    ...values,
    caseId,
    userId,
    companyId,
    caseName: values?.caseName || "",
    courtCaseNumber: values?.courtCaseNumber || "",
    status: values?.caseStatus || "",
    clientName: values?.clientName || "",
    courtJurisdiction: values?.courtJurisdiction || "",
    defendantName: values?.defendantName || "",
    caseType: values?.caseType || "",
    opposingCounselName: values?.opposingCounselsName || "",
    type: SOCKET_EVENTS.UPDATE_CASE_AND_DETAILS,
    eventType: SOCKET_EVENTS.MANAGE_CASE_DETAILS,
    requestId: uuidv4()
  };

  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      caseDetails,
      ({ success, message }: { success: boolean; message: string }) => {
        if (success) toast.success(message);
      },
      ({ success, message }: { success: boolean; message: string }) => {
        if (!success) toast.error(message);
      }
    );
  }
};

/**
 * Helper function to map selected case data to form values
 * @param selectedCase - The selected case object from Redux state
 * @param currentValues - The current form values from Formik
 * @returns NewCaseFormValues - Mapped form values to be used with setValues
 */
// Modified mapSelectedCaseToFormValues to include details
export const mapSelectedCaseToFormValues = (
  selectedCase: CaseListData,
  currentValues: NewCaseFormValues
): NewCaseFormValues => {
  if (!selectedCase) return currentValues;

  // Initialize form values
  const formValues: NewCaseFormValues = {
    ...currentValues,
    caseName: selectedCase?.caseName || "",
    courtCaseNumber: selectedCase?.courtCaseNumber || "",
    clientName: selectedCase?.caseDetails[0]?.clientName ?? "",
    caseStatus: selectedCase?.status ?? "",
    courtJurisdiction: selectedCase?.caseDetails[0]?.courtJurisdiction ?? "",
    caseType: selectedCase?.caseDetails[0]?.caseType ?? "",
    defendantName: selectedCase?.caseDetails[0]?.defendantName ?? "",
    opposingCounselsName: selectedCase?.caseDetails[0]?.opposingCounselName ?? "",
    ...selectedCase.caseDetails[0]?.details?.reduce(
      (acc: { [x: string]: unknown }, detail: { field: string; value: string }) => {
        acc[detail.field] = detail.value; // Field name as key and value as value
        return acc;
      },
      {} as Record<string, string>
    )
  };

  return formValues;
};
