import { checkRegistrationStatus, generateCode } from "../../services/anervaBoard";
import { GenerateCodeResponse, RegistrationStatusResponse } from "../../utils/types";

/**
 * @function handleApiCall
 * @description Wrapper for API calls with try-catch for error handling.
 * @param {() => Promise<T>} apiFunction - The API function to execute.
 * @returns {Promise<T | null>} The API response or null on error.
 */
export const handleApiCall = async <T>(apiFunction: () => Promise<T>): Promise<T | null> => {
  try {
    const response = await apiFunction();
    return response;
  } catch (error) {
    console.error("API call error:", error instanceof Error ? error.message : "Unknown error");
    return null;
  }
};

/**
 * @function fetchRegistrationCode
 * @description Fetches the registration code from the backend.
 * @param {string} userId - The user ID.
 * @param {string} caseId - The selected case ID.
 * @returns {Promise<GenerateCodeResponse | null>} The registration code response or null on error.
 */
export const fetchRegistrationCode = async (userId: string, caseId: string): Promise<GenerateCodeResponse | null> => {
  const response = await handleApiCall(() => generateCode(userId, caseId));
  if (response) {
    return {
      ...response,
      success: response.success ?? false,
      code: typeof response.code === "number" ? response.code.toString() : (response.code ?? "0"),
      expiresIn: response.expiresIn ?? 0
    };
  }

  return null;
};

/**
 * @function checkBoardStatus
 * @description Checks the registration status of the Anerva Board.
 * @param {string} code - The registration code.
 * @returns {Promise<RegistrationStatusResponse | null>} The registration status response or null on error.
 */
export const checkBoardStatus = async (code: string): Promise<RegistrationStatusResponse | null> => {
  const response = await handleApiCall(() => checkRegistrationStatus(code));

  // Ensure `success` is always a boolean by defaulting to false if undefined
  if (response) {
    return {
      ...response,
      success: response.success ?? false,
      message: response.message || "Unknown response message"
    };
  }

  return null;
};

/**
 * @function formatTime
 * @description Formats seconds into a MM:SS format.
 * @param {number} seconds - Time in seconds.
 * @returns {string} A formatted string in MM:SS format.
 */
export const formatTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes.toString().padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
};
