import React from "react";
import { useDrag, useDrop } from "react-dnd";
import { DummyPersonImg } from "../../../../../assets/images";
import { STRINGS } from "../../../../../utils/constants";
import { JurorCardProps, Participant } from "../../../../../utils/types";
import "./styles.jurorCard.scss";

const JurorCard: React.FC<JurorCardProps> = ({
  juror,
  onDropOnCard,
  onDismiss,
  onRestoreJuror,
  onRemove,
  onEdit,
  onView,
  dismissed,
  alternative
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "JUROR",
    item: {
      ...juror,
      source: alternative ? "ALTERNATIVE" : dismissed ? "DISMISSED" : "JURY_BOX"
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging()
    })
  }));
  const [{ isOver }, drop] = useDrop(() => ({
    accept: "JUROR",
    drop: (droppedJuror: Participant & { source: string }) => {
      if (droppedJuror.id !== juror.id) {
        onDropOnCard?.(droppedJuror, juror);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  }));

  return (
    <div
      ref={(node) => drag(drop(node))}
      className={`juror-card ${isDragging ? "dragging" : ""} ${dismissed ? "dismissed" : ""} ${
        alternative ? "alternative" : ""
      }${isOver ? "active-drop" : ""}`}
    >
      <div className='juror-card__index'>{juror?.seatNumber || "-"}</div>
      <div className='juror-card__image'>
        <img src={juror?.image || DummyPersonImg} alt='profileImage' />
      </div>
      <div className='juror-card__info'>
        <div className='juror-card__name'>
          {juror.firstName ?? ""} {juror.lastName ?? ""}
        </div>
        <div className='juror-card__id'>Juror ID | {juror?.caseParticipants?.[0]?.jurorId ?? "N/A"}</div>
        <div className='juror-card__actions'>
          <span
            className='icon-button eye-icon-row'
            data-tooltip-id='tooltip'
            data-tooltip-content='View'
            onClick={() => onView?.()}
          >
            <i className='fa-regular fa-eye'></i>
          </span>
          {!alternative ? (
            <span
              className='icon-button edit-icon-row'
              onClick={() => onEdit?.()}
              data-tooltip-id='tooltip'
              data-tooltip-content='Edit'
            >
              <i className='fa-regular fa-pen-to-square'></i>
            </span>
          ) : (
            <span
              className='icon-button edit-icon-row'
              onClick={() => onEdit?.()}
              data-tooltip-id='tooltip'
              data-tooltip-content='Add'
            >
              <i className='fa-solid fa-plus'></i>
            </span>
          )}
          {dismissed ? (
            <span
              className='icon-button delete-icon-row'
              onClick={() => onRestoreJuror?.(juror)}
              data-tooltip-id='tooltip'
              data-tooltip-content='Restore'
            >
              <i className='fa-regular fa-turn-down-left'></i>
            </span>
          ) : alternative ? (
            <span
              className='icon-button delete-icon-row'
              onClick={() => onRemove?.()}
              data-tooltip-id='tooltip'
              data-tooltip-content='Restore'
            >
              <i className='fa-regular fa-turn-down-left'></i>
            </span>
          ) : (
            <span
              className='icon-button delete-icon-row'
              onClick={() => onDismiss?.(juror)}
              data-tooltip-id='tooltip'
              data-tooltip-content='Dismiss'
            >
              <i className='fa-regular fa-circle-minus'></i>
            </span>
          )}
        </div>
      </div>
      {dismissed && (
        <div className='juror-card__message'>
          <p>{STRINGS.JUROR_HAS_BEEN_DISMISSED}</p>
        </div>
      )}
    </div>
  );
};

export default JurorCard;
