import { Dispatch } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { DummyPersonImg } from "../../assets/images";
import { setActiveMenu } from "../../redux/slices/menuActionsSlice";
import { URLS } from "../constants";
import routes from "../constants/routes";
import { BasicInfoField, MenuItem, Participant, ProfileMenuItem, SelectedReasons, TabDataField } from "../types";

export const menuItems: Array<MenuItem> = [
  {
    item_type: "Label",
    title_en: "MY WORKFLOW",
    view_groups: null
  },
  {
    item_type: "Custom",
    title_en: "Dashboard",
    icon: "fa-light fa-table-columns",
    view_groups: null,
    route: routes.DASHBOARD,
    children: []
  },
  {
    item_type: "Custom",
    title_en: "Interactive Calendar",
    icon: "fa-light fa-calendars",
    view_groups: null,
    route: routes.CALENDARS,
    children: []
  },
  {
    item_type: "Label",
    title_en: "Case MODULES",
    view_groups: null
  },
  {
    item_type: "Custom",
    title_en: "Create Case",
    icon: "fa-light fa-briefcase",
    view_groups: null,
    route: routes.CREATE_CASE,
    children: []
    //  [
    //   {
    //     item_type: "Custom",
    //     subtitle_en: "Trial Team",
    //     tooltip_en: "Trial Team",
    //     icon: "fas fa-clock",
    //     route: routes.TRIAL_TEAM,
    //     view_groups: null
    //   },
    //   {
    //     item_type: "Custom",
    //     subtitle_en: "Documents",
    //     tooltip_en: "Documents",
    //     icon: "fa-regular fa-paperclip",
    //     route: routes.DOCUMENTS,
    //     view_groups: null
    //   }
    // ]
  },
  {
    item_type: "Custom",
    title_en: "Manage Case",
    icon: "fa-light fa-table",
    view_groups: null,
    route: routes.CASES_LIST,
    children: []
  }
];

export const profileMenuItems: Array<ProfileMenuItem> = [
  {
    type: "Action",
    text_en: "Logout",
    icon: "fa fa-power-off",
    action: () => {}
  }
];

export const getUniqueId = () => {
  const timestamp = Date.now().toString(36);
  const randomNum = Math.random().toString(36).substr(2, 5); // Adjust the length as needed

  return `${timestamp}-${randomNum}`;
};

export const formatFileSize = (bytes: number) => {
  if (bytes < 1024) return `${bytes} bytes`;
  if (bytes < 1048576) return `${(bytes / 1024).toFixed(2)} KB`;
  return `${(bytes / 1048576).toFixed(2)} MB`;
};

export const calculateNewRate = (currentRate: string, selectedReasons: SelectedReasons) => {
  let rateDeduction = 0;
  if (selectedReasons.biasParty) rateDeduction += 20;
  if (selectedReasons.biasSubject) rateDeduction += 15;
  if (selectedReasons.cause) rateDeduction += 25;
  if (selectedReasons.excused) rateDeduction += 10;
  if (selectedReasons.peremptory) rateDeduction += 30;
  if (selectedReasons.other) rateDeduction += 5;
  const value = parseInt(currentRate, 10);
  const newRate = value * (1 - rateDeduction / 100);
  return Math.min(Math.max(0, newRate), 100);
};

export const getFirstWord = (str: string): string => {
  const firstWord = str.split(" ")[0];
  return firstWord.replace(":", "");
};

export const formatCaseType = (input: string): string =>
  input
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase());

export const toCamelCase = (str: string): string => {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => (index === 0 ? match.toLowerCase() : match.toUpperCase()))
    .replace(/\s+/g, ""); // Remove any remaining spaces
};

export const formatPhoneNumber = (value: string) => {
  if (!value) return value;

  // Remove all non-numeric characters
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;

  // Check if the number starts with '1' and format as '+1 (XXX) XXX-XXXX'
  if (phoneNumber[0] === "1") {
    if (phoneNumberLength <= 1) return "+1"; // Just return the country code if no further digits
    if (phoneNumberLength <= 4) return `+1 (${phoneNumber.slice(1)}`; // +1 (X)
    if (phoneNumberLength <= 7) return `+1 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4)}`; // +1 (XXX) XXX
    return `+1 (${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`; // +1 (XXX) XXX-XXXX
  }

  // Handle numbers that don't start with '1'
  if (phoneNumberLength < 4) return phoneNumber; // Less than 4 digits
  if (phoneNumberLength < 7) return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`; // (XXX) XXX

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`; // (XXX) XXX-XXXX
};

export const capitalizeFirstLetter = (value: string): string => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const adminMenuItems: Array<MenuItem> = [
  {
    item_type: "Label",
    title_en: "ADMIN PANEL",
    view_groups: null
  },
  {
    item_type: "Custom",
    title_en: "Companies",
    icon: "fa-light fa-building",
    view_groups: null,
    route: routes.COMPANIES,
    children: []
  },
  {
    item_type: "Custom",
    title_en: "Provider Query Logs",
    icon: "fa-light fa-file-lines",
    view_groups: null,
    route: routes.ACCOUNTS,
    children: []
  }
  // {
  //   item_type: "Custom",
  //   title_en: "Switch User Role",
  //   icon: "fa-light fa-user-cog",
  //   view_groups: null,
  //   route: routes.SWITCH_USER_ROLES,
  //   children: []
  // }
];

/**
 * Handles navigation to a specified route while updating the active menu in the Redux store.
 *
 * @param route - The route to navigate to.
 * @param navigate - The React Router `navigate` function for SPA navigation.
 * @param dispatch - The Redux `dispatch` function to dispatch actions.
 * @param useWindow - Optional flag to determine whether to use `window.location.href` for navigation.
 *                     Defaults to `false`. If `false`, performs a full-page reload; otherwise, uses SPA navigation.
 */
export const handleNavigation = (
  route: string,
  navigate: NavigateFunction,
  dispatch: Dispatch,
  useWindow: boolean = false,
  from: string = ""
): void => {
  if (useWindow) {
    // Perform a full-page reload by navigating using window.location.href
    window.location.href = route;
  } else {
    // Perform single-page navigation using React Router's navigate function
    if (from) navigate(route, { state: { from } });
    else navigate(route);
  }

  // Dispatch the action to update the active menu in the Redux store
  dispatch(setActiveMenu(route));
};

export const isUrl = (value: string): boolean => {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return !!urlPattern.test(value) || value.includes("http");
};

export const getProfileImage = (selectedParticipant: Participant): string => {
  const basicInfo = selectedParticipant?.participantDetails?.[0]?.basic || [];
  if (Array.isArray(basicInfo)) {
    const profileImageField = basicInfo.find((field: BasicInfoField) => field?.field === "profileImage");
    if (profileImageField?.value) {
      return `${URLS.IMAGE_BASE_URL}/images/${profileImageField?.value}`;
    } else {
      return DummyPersonImg;
    }
  }
  return DummyPersonImg;
};

export const getProfileImageUrl = (profileImage: string): string => {
  const IMAGE_BASE_URL = `${URLS.IMAGE_BASE_URL}/images`;
  return typeof profileImage === "string" && profileImage.trim() !== ""
    ? `${IMAGE_BASE_URL}/${profileImage}`
    : DummyPersonImg;
};

export const transformCaseDetails = (caseDetails: TabDataField[]) => {
  if (!Array.isArray(caseDetails)) {
    return caseDetails;
  }

  let caseLinkField = null;
  const otherFields = caseDetails.filter((field) => {
    if (field.field === "caseLink") {
      caseLinkField = field;
      return false;
    }
    return true;
  });

  if (caseLinkField) {
    return [...otherFields, caseLinkField];
  } else {
    return otherFields;
  }
};
