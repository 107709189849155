import { FormikValues } from "formik";
import { toast } from "react-toastify";
import { v4 as uuidv4, validate as isUUID } from "uuid";
import * as Yup from "yup";
import { SOCKET_EVENTS, NOTE_TYPES, STRINGS, DEFAULT_ROLES } from "../../../../utils/constants";
import { formatPhoneNumber } from "../../../../utils/helpers";
import {
  CompanyUser,
  ConfigureTabProps,
  FormField,
  IFormConfiguratorType,
  IFormSectionType,
  AnervaServerResponseProps,
  EventData,
  Note,
  UserDetailsType,
  ComUser
} from "../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.number().optional().positive().integer(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const getFormikInitialValues = (fields: FormField[]) => {
  const initialValues: { [key: string]: unknown } = {};
  fields.forEach((field: FormField) => {
    if (field.field) {
      switch (field.type) {
        case "text":
        case "string":
        case "textarea":
          initialValues[field.field] = field.value || "";
          break;
        case "number":
          initialValues[field.field] = field.value || 0;
          break;
        case "date":
          initialValues[field.field] = field.value || null;
          break;
        case "boolean":
          initialValues[field.field] = field.value || false;
          break;
        case "array":
          initialValues[field.field] = field.value || [null];
          break;
        default:
          initialValues[field.field] = field.value || null;
      }
    }
  });
  return initialValues;
};

export const transformCompanyUserData = (user: CompanyUser): FormField[] => {
  if (!user) return [];
  return [
    {
      label: "First Name",
      isMandatory: true,
      name: "firstName",
      placeholder: "Type here",
      type: "text",
      value: user.firstName ?? user.userId?.firstName ?? "",
      isCustom: false,
      field: "firstName",
      isFixed: true
    },
    {
      label: "Last Name",
      isMandatory: true,
      name: "lastName",
      placeholder: "Type here",
      type: "text",
      value: user.lastName ?? user.userId?.lastName ?? "",
      isCustom: false,
      field: "lastName",
      isFixed: true
    },
    {
      label: "Email",
      isMandatory: true,
      name: "email",
      placeholder: "Type here",
      type: "text",
      value: user.email ?? user.userId?.email ?? "",
      isCustom: false,
      field: "email",
      isFixed: true
    },
    {
      label: "Phone Number",
      isMandatory: false,
      name: "phoneNumber",
      placeholder: "Type here",
      type: "text",
      value: null,
      isCustom: false,
      field: "phoneNumber",
      isFixed: true
    },
    {
      label: "Role",
      isMandatory: true,
      name: "role",
      placeholder: "Type here",
      type: "text",
      value: user.role?.name ?? user.userId?.role?.name ?? "",
      isCustom: false,
      field: "role",
      isFixed: true,
      isDropdown: true,
      dropdownList: DEFAULT_ROLES
    }
  ];
};

export const ConfigureFieldList: FormField[] = [
  {
    label: "Nickname",
    isMandatory: false,
    name: "nickname",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "nickname"
  },
  {
    label: "Address",
    isMandatory: false,
    name: "address",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "address"
  },
  {
    label: "City",
    isMandatory: false,
    name: "city",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "city"
  },
  {
    label: "State",
    isMandatory: false,
    name: "state",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "state"
  },
  {
    label: "Zip Code",
    isMandatory: false,
    name: "zipCode",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "zipCode"
  },
  {
    label: "Country",
    isMandatory: false,
    name: "country",
    placeholder: "Type here",
    type: "text",
    value: null,
    isCustom: true,
    field: "country"
  }
];

export const handleSaveNote = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  values: {
    note: Note;
    type: string;
    eventType: string;
  },
  selectedCompanyUser: { id?: string; companyId?: string },
  userId: string
) => {
  const isCompanyUserNote = selectedCompanyUser?.id && selectedCompanyUser?.companyId;
  if (socketEmit) {
    const notePayload = {
      type: values.type,
      createdBy: userId || "",
      eventType: values.eventType,
      requestId: uuidv4(),
      note: values?.note.note,
      noteType: isCompanyUserNote ? NOTE_TYPES.COMPANY_USER : NOTE_TYPES.PARTICIPANT,
      companyUserId: selectedCompanyUser?.id,
      companyId: selectedCompanyUser?.companyId
    };
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      notePayload,
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else if (!resData.success) toast.error(resData.message);
      },
      (data) => {
        const resData = data as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else if (!resData.success) toast.error(resData.message);
      }
    );
  } else {
    toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
    return;
  }
};

export const handleUpdateCompanyUser = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void,
  data: Partial<CompanyUser>,
  userId: string,
  companyId: string,
  id: string
) => {
  if (socketEmit) {
    const updatePayload = {
      userUpdates: { ...data },
      requestId: uuidv4(),
      eventType: SOCKET_EVENTS.MANAGE_USER_DETAILS,
      type: SOCKET_EVENTS.USER_AND_DETAILS_UPDATED,
      createdBy: userId,
      companyId,
      id
    };
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      updatePayload,
      (response) => {
        const resData = response as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else toast.error(resData.message);
      },
      (response) => {
        const resData = response as AnervaServerResponseProps;
        if (resData.success) toast.success(resData.message);
        else toast.error(resData.message);
      }
    );
  } else {
    toast.error(STRINGS.SOCKET_NOT_AVAILABLE);
  }
};

const excludedFields = ["_id", "userId", "__v", "createdAt", "updatedAt", "profileImage"];

export const initializeTabs = (
  data: CompanyUser,
  selectedConfigurator: IFormConfiguratorType | null,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  // setExcludedFields: React.Dispatch<React.SetStateAction<{ field: FormField; section: string }[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const userDetails = [data.userId?.userDetails];
  const list: ConfigureTabProps[] = [];
  const excludedContent: { field: FormField; section: string }[] = [];

  const isObjectId = (id: string): boolean => {
    const objectIdRegex = /^[0-9a-fA-F]{24}$/;
    return objectIdRegex.test(id);
  };
  const getBestId = (existingId: string | undefined, newId: string | undefined): string => {
    if (existingId && isObjectId(existingId)) return existingId;
    if (newId && isObjectId(newId)) return newId;
    if (existingId && !isUUID(existingId)) return existingId;
    if (newId && !isUUID(newId)) return newId;
    return existingId || newId || uuidv4();
  };

  const findOrCreateTab = (label: string, value: string, id?: string): ConfigureTabProps => {
    const existingTab = list.find(
      (tab) => tab.label?.toLowerCase() === label?.toLowerCase() && tab.value?.toLowerCase() === value?.toLowerCase()
    );
    if (existingTab) {
      existingTab.id = getBestId(existingTab.id, id);
      return existingTab;
    } else {
      const newTab: ConfigureTabProps = {
        id: id || uuidv4(),
        label,
        value,
        content: []
      };
      list.push(newTab);
      return newTab;
    }
  };
  const processField = (field: FormField, sectionKey: string): FormField | null => {
    if (excludedFields.includes(field.name || field.field || "")) {
      excludedContent.push({ field: field as FormField, section: sectionKey });
      return null;
    }

    let valueOriginal: string | Date | string[] | null = null;
    if (typeof field.value === "string" || Array.isArray(field.value)) {
      valueOriginal = field.value;
    } else if (typeof field.value === "number") {
      valueOriginal = field.value.toString();
    } else if (typeof field.value === "boolean") {
      valueOriginal = field.value ? "true" : "false";
    } else if (field.value && typeof field.value === "object" && "toISOString" in field.value) {
      valueOriginal = new Date(field.value as Date).toISOString();
    }

    return {
      ...field,
      id: field.id ? String(field.id) : undefined,
      label: field.label || "",
      isMandatory: field.isMandatory ?? false,
      name: field.name || field?.field || "",
      placeholder: field.placeholder || "",
      isFixed: field.isFixed ?? true,
      field: field.field || field.name || "",
      originalValue: valueOriginal,
      type: field.type || "text",
      value: valueOriginal,
      isCustom: field.isCustom ?? true,
      isSwitch: field.isSwitch ?? false,
      disabled: field.disabled ?? false,
      isDropdown: field.isDropdown ?? false,
      dropdownList: (field.name || field?.field) && field?.isDropdown ? DEFAULT_ROLES : []
    };
  };
  if (userDetails && Array.isArray(userDetails)) {
    // Process user details
    userDetails.forEach((section: UserDetailsType | undefined) => {
      const sectionKeys = Object.keys(section ?? {}).filter((key) => !excludedFields.includes(key));
      sectionKeys.forEach((sectionKey) => {
        if (section && Array.isArray(section[sectionKey as keyof UserDetailsType])) {
          const content: FormField[] = (section[sectionKey] as FormField[])
            .map((field) => processField(field, sectionKey))
            .filter((field): field is FormField => field !== null);

          const tab = findOrCreateTab(
            (sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)).replace(/_/g, " "),
            sectionKey
          );
          tab.content = [...tab.content, ...content];
        }
      });
    });
  }
  // Pro}cess configurator sections and append them to the end of the list
  if (selectedConfigurator && selectedConfigurator?.sections) {
    selectedConfigurator.sections.forEach((section: IFormSectionType) => {
      const tab = findOrCreateTab(section.label, section.name, section._id || section.id);
      tab.content = [...tab.content];
    });
  }

  // Set the updated list of tabs, excluded fields, and the active tab
  setTabs(list);
  setActiveTab(list[0]);
};

export const initializeInputFields = (
  configurator: IFormConfiguratorType | null,
  selectedCompanyUser: CompanyUser | null,
  tabs: ConfigureTabProps[],
  setInputFieldsData: React.Dispatch<React.SetStateAction<FormField[]>>
): void => {
  let fields: FormField[] = [];

  // Process configurator data if available
  if (configurator && configurator.sections) {
    fields = configurator.sections.flatMap((section) =>
      section.content.map((field) => ({
        ...field,
        value: field.value || "",
        section: section.name
      }))
    );
  }

  // Process company user details if available
  if (selectedCompanyUser && selectedCompanyUser.userId?.userDetails) {
    const userDetails = selectedCompanyUser.userId.userDetails;
    const companyUserFields: FormField[] = Object.entries(userDetails).flatMap(([sectionName, sectionFields]) => {
      if (Array.isArray(sectionFields)) {
        return sectionFields.map(
          (field): FormField => ({
            ...field,
            name: field.field || field.name,
            value: field.value || "",
            section: sectionName,
            type: field.type || "text",
            isMandatory: field.isMandatory || false,
            isCustom: field.isCustom || false,
            label: field.label || field.name || ""
          })
        );
      }
      return [];
    });

    // Merge configurator fields and company user fields
    fields = [...fields, ...companyUserFields];
  }

  // Remove duplicate fields, prioritizing configurator fields
  const uniqueFields = Array.from(new Map(fields.map((field) => [field.name || field?.field, field])).values());

  // Filter out fields that are already in tabs
  const usedFieldNames = new Set([
    ...tabs.flatMap((tab) => tab?.content?.map((field) => field.name || field?.field)),
    ...excludedFields
  ]);

  const mergedArray = [...uniqueFields, ...ConfigureFieldList];
  const filteredFields = mergedArray.filter((field) => !usedFieldNames.has(field.name || field?.field));

  const formattedFields = filteredFields.map((field): FormField => {
    let formattedValue: string | string[] | Date | null;

    if (typeof field.value === "string") {
      const val: string = field.name || field?.field || "";
      formattedValue =
        val && val.length > 0 && val.toLowerCase().includes("phone") ? formatPhoneNumber(field.value) : field.value;
    } else if (field.value instanceof Date) {
      formattedValue = field.value;
    } else if (Array.isArray(field.value)) {
      formattedValue = field.value;
    } else if (typeof field.value === "number") {
      formattedValue = field.value.toString();
    } else if (typeof field.value === "boolean") {
      formattedValue = field.value.toString();
    } else {
      // Handle undefined, null, or any other unexpected types
      formattedValue = null;
    }

    return {
      ...field,
      id: uuidv4(),
      value: formattedValue,
      isMandatory: field.isMandatory ?? false,
      type: field.type || "text",
      isFixed: false,
      isCustom: true
    } as FormField;
  });

  setInputFieldsData(formattedFields);
};

function isKeyOfComUser(key: string): key is keyof ComUser {
  return key in ({} as ComUser);
}
function isKeyOfCompanyUser(key: string): key is keyof CompanyUser {
  return key in ({} as CompanyUser);
}

export const mergeCompanyUserValues = (values: FormikValues, companyUser: CompanyUser): CompanyUser => {
  return Object.keys(values).reduce(
    (updatedCompanyUser, key) => {
      if (isKeyOfCompanyUser(key)) {
        (updatedCompanyUser[key] as unknown) = values[key];
      } else if (updatedCompanyUser.userId && isKeyOfComUser(key)) {
        (updatedCompanyUser.userId[key] as unknown) = values[key];
      }
      return updatedCompanyUser;
    },
    { ...companyUser }
  );
};

export const buildCompanyUserDetails = (tabs: ConfigureTabProps[]): Record<string, FormField[]> =>
  tabs.reduce((acc: Record<string, FormField[]>, item) => {
    acc[item.value] =
      item?.content?.map((node) => {
        let value: string | Date | string[] | null = null;
        if (typeof node.value === "string" || node.value instanceof Date || Array.isArray(node.value)) {
          value = node.value;
        } else if (typeof node.value === "number" || typeof node.value === "boolean") {
          value = String(node.value);
        }
        return {
          ...node,
          value,
          isMandatory: node.isMandatory ?? false, // Ensure isMandatory is always boolean
          isFixed: true
        } as FormField;
      }) || [];
    return acc;
  }, {});
