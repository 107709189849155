import { AxiosError } from "@digitalworkflow/psl_web";

/**
 * @interface CustomError
 * @description Defines the structure of a standardized error object.
 */
interface CustomError {
  message: string;
  isAxiosError: boolean;
  originalError?: AxiosError | unknown;
}
interface ErrorResponse {
  message?: string;
}
/**
 * @function handleError
 * @description Handles errors and ensures a consistent error format.
 * @param {unknown} error - The error object caught in the try-catch block.
 * @returns {CustomError} A standardized error object.
 */
export const handleError = (error: AxiosError | Error): CustomError => {
  if ((error as AxiosError).isAxiosError) {
    const axiosError = error as AxiosError<ErrorResponse>; // Use ErrorResponse for response typing
    return {
      message: axiosError.response?.data?.message || axiosError.message || "An unknown error occurred.",
      isAxiosError: true,
      originalError: axiosError
    };
  }

  return {
    message: error.message || "An unknown error occurred.",
    isAxiosError: false,
    originalError: error
  };
};
