import * as Yup from "yup";
import {
  ClientInformationFormValues,
  LegalAndOtherRecordsValues,
  SelectedParticipantProps
} from "../../../../../utils/types";

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string().email("Invalid email format").required("Email is required"),
  pronouns: Yup.string().optional(),
  preferredTitle: Yup.string().optional(),
  age: Yup.string().optional(),
  sex: Yup.string().optional(),
  gender: Yup.string().optional(),
  occupation: Yup.string().optional(),
  educationLevel: Yup.string().optional(),
  address: Yup.string().optional(),
  city: Yup.string().optional(),
  state: Yup.string().optional(),
  country: Yup.string().optional(),
  zipCode: Yup.string().optional(),
  relationshipStatus: Yup.string().optional()
});

export const initialValues: ClientInformationFormValues = {
  firstName: "",
  lastName: "",
  nickname: "",
  email: "",
  age: "",
  sex: "",
  gender: "",
  pronouns: "",
  preferredTitle: "",
  occupation: "",
  educationLevel: "",
  address: "",
  addressAdditional: "",
  city: "",
  state: "",
  country: "",
  zipCode: "",
  children: false,
  childrenText: "",
  childSupport: false,
  childSupportText: "",
  relationshipStatusOption: "",
  relationshipStatus: "",
  additionalInformation: "",
  timeZone: ""
};

export const demographicsValidationSchema = Yup.object().shape({
  ethnicity: Yup.string().optional(),
  economicStatus: Yup.string().optional(),
  occupation: Yup.string().optional(),
  workplace: Yup.string().optional(),
  anyBankruptcy: Yup.boolean(),
  anyBankruptcyText: Yup.string(),
  estimatedHouseholdIncome: Yup.string().optional(),
  estimatedNetWorth: Yup.string().optional(),
  lengthOfResidence: Yup.string().optional(),
  homeOwnershipStatus: Yup.string().optional()
});

export const initialDemographicsValues = {
  ethnicity: "",
  economicStatus: "",
  occupation: "",
  workplace: "",
  anyBankruptcy: false,
  anyBankruptcyText: "",
  estimatedHouseholdIncome: "",
  estimatedNetWorth: "",
  lengthOfResidence: "",
  homeOwnershipStatus: ""
};

export const legalAndOtherRecordsValidationSchema = Yup.object().shape({
  legal_other_records: Yup.string().optional(),
  licenses_or_credentials: Yup.string().optional(),
  academic_records: Yup.string().optional(),
  criminal_records: Yup.string().optional(),
  testimony_transcripts: Yup.string().optional(),
  prior_verdicts_or_settlements: Yup.string().optional(),
  witness_protection_program: Yup.string().optional(),
  no_contact_or_restraining_order: Yup.string().optional(),
  additional_information: Yup.string().notRequired(),
  prior_statements_or_reports: Yup.string().optional(),
  // Switch checks are not required, but we still include them for completeness
  prior_verdicts_or_settlements_check: Yup.boolean(),
  witness_protection_program_check: Yup.boolean(),
  no_contact_or_restraining_order_check: Yup.boolean(),
  prior_statements_or_reports_check: Yup.boolean()
});

export const initialLegalAndOtherRecordsValues: LegalAndOtherRecordsValues = {
  legal_other_records: "",
  licenses_or_credentials: "",
  academic_records: "",
  criminal_records: "",
  testimony_transcripts: "",
  prior_verdicts_or_settlements: "",
  witness_protection_program: "",
  no_contact_or_restraining_order: "",
  additionalInformation: "",
  prior_statements_or_reports: "",
  // Switch checks
  prior_verdicts_or_settlements_check: false,
  witness_protection_program_check: false,
  no_contact_or_restraining_order_check: false,
  prior_statements_or_reports_check: false
};

export const socialMediaValidationSchema = Yup.object().shape({
  twitter: Yup.string().url("Invalid URL"),
  facebook: Yup.string().url("Invalid URL"),
  reddit: Yup.string().url("Invalid URL"),
  tiktok: Yup.string().url("Invalid URL"),
  website_blog_urls: Yup.string().url("Invalid URL").optional(),
  linkedin: Yup.string().url("Invalid URL"),
  instagram: Yup.string().url("Invalid URL"),
  youtube: Yup.string().url("Invalid URL")
});

export const initialSocialMediaValues = {
  twitter: "",
  facebook: "",
  reddit: "",
  tiktok: "",
  website_blog_urls: "",
  linkedin: "",
  instagram: "",
  youtube: ""
};

// Helper function to safely get a value of a specific type
export function safeGet<T>(value: unknown, fallback: T): T {
  if (typeof value === typeof fallback && value !== null) {
    return value as T;
  }
  return fallback;
}

// Helper function to safely get an array value
export const safeGetArray = <T>(value: unknown, fallback: T[]): T[] => {
  return Array.isArray(value) ? value : fallback;
};

export const updateClientInformation = (
  prevValues: ClientInformationFormValues,
  selectedParticipant: SelectedParticipantProps
): ClientInformationFormValues => {
  const details = selectedParticipant.participantDetails?.[0] ?? {};
  return {
    ...prevValues,
    // Fields from the main selectedParticipant object
    firstName: safeGet(selectedParticipant.firstName, prevValues.firstName ?? ""),
    lastName: safeGet(selectedParticipant.lastName, prevValues.lastName ?? ""),
    email: safeGet(selectedParticipant.email, prevValues.email ?? ""),
    phoneNumber: safeGet(selectedParticipant.phoneNumber, prevValues.phoneNumber ?? ""),
    city: safeGet(selectedParticipant.city, prevValues.city ?? ""),

    // Fields from participantDetails
    nickname: safeGet(details.nickname, prevValues.nickname ?? ""),
    age: safeGet(details.age?.toString(), prevValues.age ?? ""),
    sex: safeGet(details.sex, prevValues.sex ?? ""),
    gender: safeGet(details.gender, prevValues.gender ?? ""),
    pronouns: safeGet(details.pronouns, prevValues.pronouns ?? ""),
    preferredTitle: safeGet(details.preferredTitle, prevValues.preferredTitle ?? ""),
    occupation: safeGet(details.occupation, prevValues.occupation ?? ""),
    educationLevel: safeGet(details.educationLevel, prevValues.educationLevel ?? ""),
    address: safeGet(details.address, prevValues.address ?? ""),
    addressAdditional: safeGet(details.addressAdditional, prevValues.addressAdditional ?? ""),
    children: safeGet(details.anyChildren, prevValues.children ?? false),
    childrenText: safeGet(details.childrenText, prevValues.childrenText ?? ""),
    childSupport: safeGet(details.doesClientPayChildSupport, prevValues.childSupport ?? false),
    childSupportText: safeGet(details.childSupportText, prevValues.childSupportText ?? ""),
    relationshipStatus: safeGet(details.relationshipStatus, prevValues.relationshipStatus ?? ""),
    timeZone: safeGet(details.timeZone, prevValues.timeZone ?? ""),

    // Additional fields from participantDetails
    ethnicity: safeGet(details.ethnicity, prevValues.ethnicity ?? ""),
    lengthOfResidency: safeGet(details.lengthOfResidency?.toString(), prevValues.lengthOfResidency ?? ""),
    householdIncome: safeGet(details.householdIncome?.toString(), prevValues.householdIncome ?? ""),
    isHomeOwner: safeGet(details.isHomeOwner, prevValues.isHomeOwner ?? false),
    criminalRecords: safeGetArray(details.criminalRecords, prevValues.criminalRecords ?? []),
    pastLawsuits: safeGetArray(details.pastLawsuits, prevValues.pastLawsuits ?? []),
    additionalLegalInfo: safeGet(details.additionalLegalInfo, prevValues.additionalLegalInfo ?? ""),
    uploadedPhotos: safeGetArray(details.uploadedPhotos, prevValues.uploadedPhotos ?? []),
    uploadedVideos: safeGetArray(details.uploadedVideos, prevValues.uploadedVideos ?? []),
    hobbies: safeGetArray(details.hobbies, prevValues.hobbies ?? []),
    organizationsAffiliatedWith: safeGetArray(
      details.organizationsAffiliatedWith,
      prevValues.organizationsAffiliatedWith ?? []
    ),
    socialInterests: safeGetArray(details.socialInterests, prevValues.socialInterests ?? []),
    preferredContactMethod: safeGet(details.preferredContactMethod, prevValues.preferredContactMethod ?? ""),
    canReceiveMarketingEmails: safeGet(
      details.canReceiveMarketingEmails,
      prevValues.canReceiveMarketingEmails ?? false
    ),
    socialMediaProfiles: safeGetArray(details.socialMediaProfiles, prevValues.socialMediaProfiles ?? []),

    // Fields that are not in participantDetails remain unchanged
    state: safeGet(selectedParticipant?.state, prevValues.state ?? ""),
    country: safeGet(selectedParticipant?.country, prevValues.country ?? ""),
    zipCode: safeGet(selectedParticipant?.zipCode, prevValues.zipCode ?? ""),
    relationshipStatusOption: safeGet(details.relationshipStatusOption, prevValues.relationshipStatusOption ?? ""),
    additionalInformation: safeGet(selectedParticipant?.additionalInformation, prevValues.additionalInformation ?? "")
  };
};
