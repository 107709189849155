import React from "react";
import { isUrl } from "../../utils/helpers";
import { DetailViewProps, TabDataField } from "../../utils/types";
import InfoCard from "../InfoCard";
import "./styles.scss";

const DetailView: React.FC<DetailViewProps> = ({ data, showImage = false }) => {
  const imageField = data.find((field) => field.type === "image");

  return data && data.length > 0 ? (
    <section className='detailView-section'>
      <div className='detailView-container'>
        {showImage && (
          <div className='detail-image-container'>
            {imageField && (
              <img
                src={data.find((field) => field.type === "image")?.value as string}
                alt='Profile'
                className='detail-profile-image'
              />
            )}
          </div>
        )}
        <div className={`detailView-grid ${imageField ? "with-image" : ""}`}>
          {data
            .filter((field) => field.type !== "image")
            .map((field: TabDataField, index: number) => (
              <div key={`${field._id}_${index}`} className='detailView-item'>
                <strong>{field.label}:</strong>{" "}
                {isUrl(String(field.value)) ? (
                  <a href={String(field.value)} target='_blank' rel='noopener noreferrer'>
                    {String(field.value)}
                  </a>
                ) : (
                  <span>{String(field.value) || "N/A"}</span>
                )}
              </div>
            ))}
        </div>
      </div>
    </section>
  ) : (
    <InfoCard customClass='customClass-info' />
  );
};

export default DetailView;
