import { BasicInfoField } from "../../../../utils/types";

export const extractFields = (field: BasicInfoField, excludedFields: string[]) => {
  const fieldName = field?.field || field?.name;

  return (
    typeof fieldName === "string" &&
    !excludedFields.includes(fieldName) &&
    !fieldName.startsWith("additional_address") &&
    !fieldName.includes("alternative") &&
    !fieldName.includes("alt")
  );
};
