import { v4 as uuidv4 } from "uuid";
import { inputFieldsArray } from "../../../../utils/constants/formFieldsJson";
import { formatPhoneNumber } from "../../../../utils/helpers";
import { ConfigureTabProps, FormField, ParticipantDetails, SelectedParticipantProps } from "../../../../utils/types";

type CommonProps = Partial<SelectedParticipantProps & ParticipantDetails> & { _id?: string };

function isParticipantDetail(detail: CommonProps): detail is SelectedParticipantProps {
  return typeof (detail as SelectedParticipantProps).participantId === "string";
}

function transformValue(value: unknown): string | string[] | Date | null {
  if (typeof value === "string" || Array.isArray(value) || value instanceof Date || value === null) {
    return value;
  }
  if (typeof value === "number" || typeof value === "boolean") {
    return String(value);
  }
  return null;
}

export const initializeTabs = (
  data: SelectedParticipantProps,
  setTabs: React.Dispatch<React.SetStateAction<ConfigureTabProps[]>>,
  setActiveTab: React.Dispatch<React.SetStateAction<ConfigureTabProps | undefined>>
): void => {
  const details: CommonProps[] | null = data?.participantDetails
    ? data.participantDetails.filter(isParticipantDetail)
    : null;

  const list: ConfigureTabProps[] = [];

  details?.forEach((section: CommonProps) => {
    const sectionKeys: string[] = Object.keys(section).filter(
      (key) => !["_id", "participantId", "__v", "createdAt", "updatedAt"].includes(key)
    );

    sectionKeys.forEach((sectionKey: string) => {
      const sectionData = section[sectionKey as keyof CommonProps];
      if (Array.isArray(sectionData) && sectionData.length > 0) {
        const content: FormField[] = (sectionData as FormField[]).map((field: FormField) => {
          const value = transformValue(field.value);
          return {
            ...field,
            id: field.id?.toString() || "",
            label: field.label || "",
            isMandatory: field.isMandatory || false,
            name: field.field || field.name || "",
            placeholder: field.placeholder || "",
            type: field.type || "text",
            value: value,
            isFixed: field.isFixed || false,
            field: field.field || field.name || "",
            isCustom: field.isCustom ?? true,
            isSwitch: field.isSwitch || false,
            disabled: field.disabled || false,
            dropdownList: field.dropdownList || []
          };
        });

        list.push({
          id: uuidv4(),
          label: (sectionKey.charAt(0).toUpperCase() + sectionKey.slice(1)).replace(/_/g, " "),
          value: sectionKey,
          content
        });
      }
    });
  });

  setTabs(list);
  setActiveTab(list[0]);
};

export const initializeInputFields = (
  data: SelectedParticipantProps,
  tabs: ConfigureTabProps[],
  setInputFieldsData?: React.Dispatch<React.SetStateAction<FormField[]>>
): void => {
  const details: CommonProps[] | null = data?.participantDetails
    ? data.participantDetails.filter(isParticipantDetail)
    : null;

  const usedFieldNames: string[] = tabs.flatMap((tab) => tab.content.map((field) => field.name));
  const filteredFields: FormField[] = inputFieldsArray.filter((field) => !usedFieldNames.includes(field.name));

  const populatedFields: FormField[] = filteredFields.map((field: FormField): FormField => {
    const matchedField: FormField | undefined = details
      ?.flatMap((detail: CommonProps) =>
        Object.values(detail)
          .filter((item): item is FormField[] => Array.isArray(item))
          .flat()
      )
      .find((inputField) => inputField.field === field.name);

    const rawValue = matchedField?.value ?? "";
    const transformedValue = transformValue(rawValue);
    const formattedValue =
      field.name.toLowerCase().includes("phone") && typeof transformedValue === "string"
        ? formatPhoneNumber(transformedValue)
        : transformedValue;

    return {
      ...field,
      value: formattedValue,
      type: field.type || "text",
      isMandatory: field.isMandatory || false,
      isCustom: field.isCustom || false,
      isFixed: field.isFixed || false,
      isSwitch: field.isSwitch || false,
      disabled: field.disabled || false,
      dropdownList: field.dropdownList || []
    };
  });

  if (setInputFieldsData) {
    setInputFieldsData(populatedFields);
  }
};
