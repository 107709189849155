import React from "react";
import { useDrop } from "react-dnd";
import { DismissedJurorsProps, Participant } from "../../../../../utils/types";
import "./styles.dismissedJurors.scss";
import JurorCard from "../JurorCard";

const DismissedJurors: React.FC<DismissedJurorsProps> = ({
  dismissedJurors,
  onRestoreJuror,
  onDismissJuror,
  onEdit,
  onView
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: "JUROR",
    drop: (item: Participant & { source: string }) => {
      if (item.source === "JURY_BOX") {
        onDismissJuror(item);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver()
    })
  });

  return (
    <div ref={drop} className={`dismissed-jurors-section ${isOver ? "active-drop" : ""}`}>
      <h4>Dismissed Juror(s)</h4>
      <div className='juror-cards flex-col'>
        {dismissedJurors && dismissedJurors.length > 0 ? (
          dismissedJurors.map((juror) => (
            <JurorCard
              key={juror.id}
              juror={juror}
              dismissed
              onRestoreJuror={onRestoreJuror}
              onEdit={() => onEdit?.(juror)}
              onView={() => onView?.(juror)}
            />
          ))
        ) : (
          <div className='drag-hint'>Drag and drop a juror here to dismiss.</div>
        )}
      </div>
    </div>
  );
};

export default DismissedJurors;
