// SocketService.ts
import { ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { io, Socket } from "socket.io-client";
import { initializeConfig } from "./config";
import { RootState } from "../redux/store";
import { SOCKET_EVENTS } from "./constants/socketEvents";
import { getToken } from "./helpers/localStorageHelper";
import { handleErrorResponse, handleEventData, handlePendingRequests } from "./helpers/socketServiceHelper";
import {
  AnervaServerResponseProps,
  AnyAction,
  ErrorCallback,
  EventData,
  SocketPendingRequestProps,
  SocketServiceInterface,
  SuccessCallback
} from "./types";

// Constants for reconnection parameters
const RECONNECTION_ENABLED = true;
const MAX_RECONNECTION_ATTEMPTS = 5;
const RECONNECTION_DELAY = 1000;
const RECONNECTION_DELAY_MAX = 5000;

// Flag to use default Socket.io reconnection settings
const USE_DEFAULT_RECONNECTION = true;

class SocketService implements SocketServiceInterface {
  private static instance: SocketService | null = null;
  private socket: Socket | null = null;
  private isConnected: boolean = false;
  private listeners: Set<(isConnected: boolean) => void> = new Set();
  private reconnectionAttempts: number = 0;
  private pendingRequests: SocketPendingRequestProps[] = [];
  private dispatch: ThunkDispatch<RootState, unknown, AnyAction> | null = null;

  private constructor() {
    // this.initializeSocket();
  }

  public static getInstance(): SocketService {
    if (!SocketService.instance) {
      SocketService.instance = new SocketService();
    }
    return SocketService.instance;
  }

  private async initializeSocket(): Promise<void> {
    if (!this.socket) {
      const token = getToken();
      const reconnectionOptions = USE_DEFAULT_RECONNECTION
        ? {
            auth: {
              token: token ? `Bearer ${token}` : ""
            }
          }
        : {
            auth: {
              token: token ? `Bearer ${token}` : ""
            },
            reconnection: RECONNECTION_ENABLED,
            reconnectionAttempts: MAX_RECONNECTION_ATTEMPTS,
            reconnectionDelay: RECONNECTION_DELAY,
            reconnectionDelayMax: RECONNECTION_DELAY_MAX
          };
      let url: string = "";
      const serverUrl = await initializeConfig();
      if (!serverUrl) {
        console.log("Server URL does not exist", serverUrl);
      } else {
        if (serverUrl.includes("/api/")) {
          const [baseUrl, endpoint] = serverUrl.split("/api/");
          console.log("Base URL:", baseUrl);
          console.log("Endpoint:", `/api/${endpoint}`);
          url = baseUrl;
        } else {
          console.log("The URL does not include '/api/'");
          url = serverUrl;
        }
        console.log("initializeSocket => serverUrl", serverUrl, url);
        this.socket = io(url, reconnectionOptions);
        this.setupSocketListeners();
      }
    }
  }

  private setupSocketListeners(): void {
    if (!this.socket) return;

    this.socket.on(SOCKET_EVENTS.CONNECT, () => {
      console.log("Connected to socket server");
      this.setIsConnected(true);
      this.reconnectionAttempts = 0;
    });

    this.socket.on(SOCKET_EVENTS.DISCONNECT, (reason: string) => {
      toast.error("Disconnected from socket server.");
      console.log(`Disconnected from socket server. Reason: ${reason}`);
      this.setIsConnected(false);
    });

    this.socket.on(SOCKET_EVENTS.CONNECT_ERROR, (error: Error) => {
      console.error("Connection error:", error);
      this.reconnectionAttempts++;
      console.log(`Reconnection attempt ${this.reconnectionAttempts}`);
    });

    this.socket.on(SOCKET_EVENTS.ERROR, (data: AnervaServerResponseProps) => {
      console.error("Socket error:", data);
      this.pendingRequests = handleErrorResponse(this.pendingRequests, data);
    });

    this.socket.on(SOCKET_EVENTS.ANERVA_SERVER_RESPONSE, (data: AnervaServerResponseProps) => {
      console.log("Server response:", data);
      handleEventData(this.dispatch, data);
      this.pendingRequests = handlePendingRequests(this.pendingRequests, data);
    });

    this.socket.on(SOCKET_EVENTS.RECONNECT, (attemptNumber: number) => {
      console.log(`Reconnected to socket server after ${attemptNumber} attempts`);
      this.setIsConnected(true);
    });

    this.socket.on(SOCKET_EVENTS.RECONNECT_ATTEMPT, (attemptNumber: number) => {
      console.log(`Attempting to reconnect: attempt ${attemptNumber}`);
    });

    this.socket.on(SOCKET_EVENTS.RECONNECT_FAILED, () => {
      console.log("Failed to reconnect after maximum attempts");
    });
  }

  public getSocket(dispatch?: ThunkDispatch<RootState, unknown, AnyAction>): Socket | null {
    if (dispatch) {
      this.dispatch = dispatch;
    }
    return this.socket;
  }

  public getIsConnected(): boolean {
    return this.isConnected;
  }

  private setIsConnected(value: boolean): void {
    this.isConnected = value;
    this.listeners.forEach((listener) => listener(value));
  }

  public subscribe(listener: (isConnected: boolean) => void): () => void {
    this.listeners.add(listener);
    return () => this.listeners.delete(listener);
  }

  public disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
      this.socket = null;
      this.setIsConnected(false);
      console.log("Manually disconnected from socket server");
    }
  }

  public reconnect(): void {
    if (!this.socket || !this.socket.connected) {
      this.initializeSocket();
      console.log("Manually initiating reconnection");
    }
  }

  public on<T extends EventData = EventData>(
    eventName: string,
    onSuccess: SuccessCallback<T>,
    onFailure?: ErrorCallback
  ): void {
    if (this.socket) {
      this.socket.on(eventName, (data: T) => {
        try {
          onSuccess(data);
        } catch (error) {
          if (onFailure && error instanceof Error) {
            onFailure(error);
          } else {
            console.error(`Error in event ${eventName}:`, error);
          }
        }
      });
    } else {
      console.warn("Socket is not initialized. Unable to add listener.");
    }
  }

  public emit<T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ): void {
    if (this.socket) {
      this.socket.emit(eventName, data);
      if (onSuccess || onFailure) {
        this.pendingRequests.push({
          requestId: data?.requestId || "",
          caseId: (data.caseId as string) || "",
          onSuccess: onSuccess,
          onFailure: onFailure
        });
      }
    } else {
      console.warn("Socket is not initialized. Unable to emit event.");
    }
  }

  public off(eventName: string, onRemoveListener?: () => void): void {
    if (this.socket) {
      this.socket.off(eventName);
      if (onRemoveListener) {
        onRemoveListener();
      }
    } else {
      console.warn("Socket is not initialized. Unable to remove listener.");
    }
  }
}

export default SocketService.getInstance();
