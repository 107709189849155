/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FlatpickrFn } from "flatpickr/dist/types/instance";
import React, { useCallback, useRef } from "react";
import Flatpickr from "react-flatpickr";
import { DateInputProps } from "../../utils/types";
import "./styles.dateInput.scss";
import "flatpickr/dist/themes/material_green.css";

const DateInput: React.FC<DateInputProps> = ({
  label,
  required = false,
  placeholder = "MM/DD/YYYY",
  onDateChange,
  value = undefined,
  minDate,
  maxDate,
  parentClassName = "",
  errorMessage = "",
  ...props
}) => {
  const flatpickrRef = useRef<FlatpickrFn | null>(null);

  const handleDateChange = useCallback(
    (selectedDates: Date[]) => {
      const newDate = selectedDates[0] || null;
      if (onDateChange) {
        onDateChange(newDate);
      }
    },
    [onDateChange]
  );

  const handleCalendarIconClick = useCallback(() => {
    if (flatpickrRef.current) {
      // @ts-expect-error
      flatpickrRef.current.flatpickr.open();
    }
  }, []);

  return (
    <div className={`form-group ${parentClassName}`}>
      <label className='form-label'>
        {label}
        {required && <span className='required-marker'> *</span>}
      </label>
      <div className='date-field'>
        <div className='input-group'>
          <Flatpickr
            // @ts-expect-error
            ref={flatpickrRef}
            className='form-control'
            value={value ? new Date(value) : undefined}
            onChange={handleDateChange}
            options={{
              dateFormat: "m/d/Y",
              minDate: minDate,
              maxDate: maxDate
            }}
            placeholder={placeholder}
            {...props}
          />
        </div>
        <span className='calendar-icon' onClick={handleCalendarIconClick}>
          <i className='fa-regular fa-calendar'></i>
        </span>
      </div>
      <div className='invalid-feedback'>{errorMessage || `${label} is Required!`}</div>
    </div>
  );
};

export default DateInput;
