import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { initializeTabs, transformDetailedViewData } from "./helper";
import { BreadCrumbs, TabButtons } from "../../../components";
import DetailView from "../../../components/DetailView";
import InfoCard from "../../../components/InfoCard";
import NavigationWrapper from "../../../components/NavigationWrapper";
import WrapperComponent from "../../../components/WrapperComponent";
import { RootState } from "../../../redux/types";
import routes from "../../../utils/constants/routes";
import { ConfigureTabProps } from "../../../utils/types";

const ViewCompanyUserDetails = () => {
  const { selectedCompanyUser } = useSelector((state: RootState) => state.companyUsers);
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);

  useEffect(() => {
    if (selectedCompanyUser?.userId) {
      initializeTabs(selectedCompanyUser, setTabs, setActiveTab);
    }
  }, [selectedCompanyUser]);

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const renderTabContent = useCallback(() => {
    return tabs && tabs.length > 0 ? (
      tabs.map((tab) => {
        if (activeTab?.value === tab.value) {
          return (
            <WrapperComponent key={tab.value}>
              <DetailView data={transformDetailedViewData(activeTab)} showImage={tab.value === "basic"} />
            </WrapperComponent>
          );
        }
        return null;
      })
    ) : (
      <InfoCard />
    );
  }, [activeTab, tabs]);

  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Company Details",
            url: routes.COMPANY_DETAILS
          },
          { text: "View User Details", url: location.pathname }
        ]}
      />
      <NavigationWrapper
        title={`${"View Company User Detail"}: ${
          selectedCompanyUser?.userId?.firstName ?? ""
        } ${selectedCompanyUser?.userId?.lastName ?? ""}`}
        goBack={true}
      >
        <TabButtons
          tabs={tabs as ConfigureTabProps[]}
          activeTab={activeTab as ConfigureTabProps}
          onTabChange={handleTabChange}
        />
        {renderTabContent()}
      </NavigationWrapper>
    </div>
  );
};

export default ViewCompanyUserDetails;
