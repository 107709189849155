import { v4 as uuidv4 } from "uuid";
import { SUPER_ADMIN } from "../../../utils/constants";
import { SOCKET_EVENTS } from "../../../utils/constants/socketEvents";
import { AnervaServerResponseProps, EventData } from "../../../utils/types";

// Function to fetch all companies from the server
export const fetchAllAccountsFromServer = (
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void
  ) => void,
  fetchedRef: React.MutableRefObject<boolean>,
  userId: string,
  page = 1,
  limit = 100
) => {
  if (fetchedRef.current) return; // Exit if already fetched
  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      {
        type: SOCKET_EVENTS.FETCH_ACCOUNTING_RECORDS,
        eventType: SOCKET_EVENTS.MANAGE_ACCOUNTING,
        userId: userId || "",
        requestId: uuidv4(),
        role: SUPER_ADMIN,
        page,
        limit
      },
      () => {
        fetchedRef.current = true;
      }
    );
  }
};
