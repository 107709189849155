import { Dispatch } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  getFormikInitialValues,
  handleSaveNote,
  handleUpdateCompanyUser,
  ConfigureFieldList,
  initializeTabs,
  initializeInputFields,
  buildCompanyUserDetails
} from "./helpers";
import CustomizableTabs from "../../../../components/CustomizableTabs";
import ToggleButton from "../../../../components/ToggleButton/toggleButton";
import { setSelectedConfigurator } from "../../../../redux/slices/formConfiguratorSlice";
import { RootState } from "../../../../redux/store";
import { AppDispatch } from "../../../../redux/types";
import { removeProfilePicture, uploadProfilePicture } from "../../../../services/login";
import { FORM_LABELS, SOCKET_EVENTS, SORT_DIRECTIONS } from "../../../../utils/constants";
import {
  addFieldToInputFieldsData,
  dragFieldInTab,
  filterInputFieldsData,
  getFormikValidationSchema,
  moveFieldInTab,
  removeFieldFromTab,
  updateTabContent
} from "../../../../utils/helpers/customizableTabHelper";
import useSocket from "../../../../utils/hooks/sockets";
import {
  AnervaServerResponseProps,
  CompanyUser,
  ConfigureTabProps,
  EditCandidateProps,
  FormField,
  IFormConfiguratorType,
  IFormSectionType,
  Note
} from "../../../../utils/types";

const CustomizableEditCompanyUser: React.FC<EditCandidateProps> = () => {
  const [tabs, setTabs] = useState<ConfigureTabProps[]>([]);
  const [inputFieldsData, setInputFieldsData] = useState<FormField[]>(ConfigureFieldList);
  const [isConfigureMode, setIsConfigureMode] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ConfigureTabProps | undefined>(undefined);
  const [formikValues, setFormikValues] = useState<FormikValues>({});
  const { selectedCompanyUser } = useSelector((state: RootState) => state.companyUsers);
  const user = useSelector((state: RootState) => state.users.user);
  const { selectedConfigurator } = useSelector((state: RootState) => state.formConfigurator);
  const { socketEmit } = useSocket();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const fetchedRef = useRef(false);

  useEffect(() => {
    if (selectedCompanyUser) {
      initializeTabs(selectedCompanyUser, selectedConfigurator, setTabs, setActiveTab);
      initializeInputFields(selectedConfigurator, selectedCompanyUser, tabs, setInputFieldsData);

      const initialValues = getFormikInitialValues(tabs.flatMap((tab) => tab.content));
      setFormikValues(initialValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyUser, selectedConfigurator]);

  const fetchFormConfigurator = useCallback(() => {
    if (fetchedRef.current) return;
    if (socketEmit && user) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        {
          type: SOCKET_EVENTS.FETCH_FORM_CONFIGURATOR,
          eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
          createdBy: user.id,
          companyId: selectedCompanyUser?.companyId,
          entityType: "user",
          requestId: uuidv4()
        },
        (response: AnervaServerResponseProps) => {
          fetchedRef.current = true;
          if (response.success && response.data) {
            const selectedForm: IFormConfiguratorType | undefined = response?.data?.formConfigurator?.find(
              (i: IFormConfiguratorType) => i.companyId === selectedCompanyUser?.companyId && i.entityType === "user"
            );
            if (selectedForm) dispatch(setSelectedConfigurator(selectedForm));
            else dispatch(setSelectedConfigurator(null));
          } else {
            toast.error("Failed to fetch form configurator");
          }
        }
      );
    }
  }, [socketEmit, user, selectedCompanyUser?.companyId, dispatch]);

  useEffect(() => {
    fetchFormConfigurator();
  }, [fetchFormConfigurator]);

  const handleFieldChange = useCallback((updatedTab: ConfigureTabProps) => {
    setTabs((prevTabs) => updateTabContent(prevTabs, updatedTab));
    setInputFieldsData((prevInputFieldsData) => filterInputFieldsData(prevInputFieldsData, updatedTab));
  }, []);

  const onMoveDown = useCallback(
    (field: FormField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.DOWN, field.name));
    },
    [activeTab]
  );

  const onMoveUp = useCallback(
    (field: FormField) => {
      setTabs((prevTabs) => moveFieldInTab(prevTabs, activeTab?.value ?? "", SORT_DIRECTIONS.UP, field.name));
    },
    [activeTab]
  );

  const handleRemoveField = useCallback(
    (field: FormField) => {
      setInputFieldsData((prevInputFieldsData) => addFieldToInputFieldsData(prevInputFieldsData, field));
      setTabs((prevTabs) => removeFieldFromTab(prevTabs, activeTab?.value ?? "", field.name));
    },
    [activeTab]
  );

  const handleTabChange = useCallback((tab: ConfigureTabProps) => {
    setActiveTab(tab);
  }, []);

  const handleConfigureModeChange = useCallback((checked: boolean) => {
    setIsConfigureMode(checked);
  }, []);

  const moveField = useCallback(
    (dragIndex: number, hoverIndex: number, field: FormField) => {
      setTabs((prevTabs) => dragFieldInTab(prevTabs, activeTab?.value ?? "", dragIndex, hoverIndex, field));
    },
    [activeTab]
  );

  const onFormValuesChange = useCallback((newValues: FormikValues) => {
    setFormikValues(newValues);
    setTabs((prevTabs) =>
      prevTabs.map((tab) => ({
        ...tab,
        content: tab.content.map((field) => ({
          ...field,
          value: newValues[field.name] !== undefined ? newValues[field.name] : field.value
        }))
      }))
    );
  }, []);

  const onFormSubmit = useCallback(
    async (tabs: ConfigureTabProps[]) => {
      const updatedTabs = tabs.map((tab) => ({
        ...tab,
        content: tab.content.map((field) => ({
          ...field,
          value: formikValues[field.name] !== undefined ? formikValues[field.name] : field.value
        }))
      }));
      if (selectedCompanyUser) {
        const updatedData = updatedTabs.reduce((acc: Partial<CompanyUser>, tab) => {
          tab.content.forEach((field) => {
            acc[field.name as keyof CompanyUser] = field.value;
          });
          return acc;
        }, {} as Partial<CompanyUser>);

        const details = buildCompanyUserDetails(tabs);
        const userPayload = {
          ...updatedData,
          userDetails: details
        };
        handleUpdateCompanyUser(
          socketEmit,
          userPayload,
          user?.id ?? "",
          selectedCompanyUser?.companyId ?? "",
          selectedCompanyUser?.id ?? ""
        );
      } else {
        toast.error("Selected company user is not available");
      }
    },
    [formikValues, selectedCompanyUser, socketEmit, user?.id]
  );

  const handleImage = useCallback(
    async (file: File | File[] | null, mode?: string) => {
      const obj = {
        image: file,
        uploadedBy: user?.id || "",
        userId: selectedCompanyUser?.userId?._id || "",
        mode: mode,
        companyId: selectedCompanyUser?.companyId || "",
        imageId: selectedCompanyUser?.userId?.images?.[0]?.id || ""
      };
      if (mode === "remove") await removeProfilePicture(obj);
      else await uploadProfilePicture(obj);
    },
    [selectedCompanyUser, user?.id]
  );

  const profileImage = useMemo(
    () => selectedCompanyUser?.userId?.images?.[0]?.profileImage?.filename,
    [selectedCompanyUser?.userId?.images]
  );

  const addNewTab = useCallback(
    (label: string) => {
      console.log("socketEmit", socketEmit, user, selectedConfigurator);
      if (socketEmit && user) {
        const newSection: Partial<IFormSectionType> = {
          label,
          name: label.toLowerCase().replace(/\s+/g, "_"),
          content: []
        };
        console.log("newSection", newSection);
        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          {
            type: SOCKET_EVENTS.ADD_SECTION,
            eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
            createdBy: user.id,
            companyId: selectedCompanyUser?.companyId,
            entityType: "user",
            requestId: uuidv4(),
            formConfiguratorId: selectedConfigurator?._id,
            section: newSection
          },
          (response: AnervaServerResponseProps) => {
            if (response.success && response.data) {
              toast.success("New section added successfully");
            } else {
              toast.error("Failed to add new section");
            }
          }
        );
      }
    },
    [selectedCompanyUser?.companyId, socketEmit, user, selectedConfigurator]
  );
  const handleAddField = useCallback(
    (section: ConfigureTabProps, fieldData: Partial<FormField>) => {
      if (socketEmit && user && selectedConfigurator) {
        const payload = {
          type: SOCKET_EVENTS.ADD_FIELD,
          eventType: SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR,
          createdBy: user.id,
          companyId: selectedCompanyUser?.companyId,
          entityType: "user",
          requestId: uuidv4(),
          formConfiguratorId: selectedConfigurator._id,
          sectionId: section.id,
          formField: fieldData
        };
        socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (response: AnervaServerResponseProps) => {
          if (response.success && response.data) {
            toast.success("New field added successfully");
          } else {
            toast.error("Failed to add new field");
          }
        });
      }
    },
    [socketEmit, user, selectedCompanyUser?.companyId, selectedConfigurator]
  );

  return (
    <div>
      <ToggleButton
        label={FORM_LABELS.CONFIGURE_MODE}
        isChecked={isConfigureMode}
        handleChange={handleConfigureModeChange}
      />
      <CustomizableTabs
        tabs={tabs}
        activeTab={activeTab}
        isConfigureMode={isConfigureMode}
        inputFieldsData={inputFieldsData}
        initialValues={formikValues}
        onTabChange={handleTabChange}
        addNewTab={addNewTab}
        handleFieldChange={handleFieldChange}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
        handleRemoveField={handleRemoveField}
        validationSchema={getFormikValidationSchema([...tabs.map((item) => item.content)].flat())}
        moveField={moveField}
        onFormSubmit={onFormSubmit}
        onFormValuesChange={onFormValuesChange}
        enableNotepad={true}
        handleSaveNote={(values) => {
          if (selectedCompanyUser) {
            handleSaveNote(socketEmit, values, selectedCompanyUser, user?.id ?? "");
          }
        }}
        notesHistory={
          selectedCompanyUser?.notes
            ?.filter((note): note is Note & { createdAt: Date | string } => note.createdAt != null)
            .sort((a, b) => {
              const dateA = new Date(a.createdAt);
              const dateB = new Date(b.createdAt);
              return dateB.getTime() - dateA.getTime();
            }) || []
        }
        setInputFieldsData={setInputFieldsData}
        handleImage={handleImage}
        profileImage={profileImage}
        onAddField={handleAddField}
      />
    </div>
  );
};

export default CustomizableEditCompanyUser;
