import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { SOCKET_EVENTS } from "../../../utils/constants";
import {
  AnervaServerResponseProps,
  EventData,
  KickOutParticipantData,
  SelectedCaseProps,
  User
} from "../../../utils/types";

/**
 * Fetches the trial team for the selected case using socket communication.
 *
 * @param selectedCase - The selected case details.
 * @param user - The user performing the action.
 * @param fetchedRef - A ref to prevent duplicate fetch calls.
 * @param socketEmit - Function to emit socket events.
 */
export const fetchTrialTeam = (
  selectedCase: SelectedCaseProps,
  user: User,
  fetchedRef: React.MutableRefObject<boolean>,
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void
): void => {
  if (selectedCase && "id" in selectedCase && selectedCase.id) {
    if (fetchedRef.current) return; // Prevent duplicate calls
    const payload = {
      type: SOCKET_EVENTS.FETCH_PARTICIPANT_TRIAL,
      eventType: SOCKET_EVENTS.MANAGE_PARTICIPANT,
      userId: user?.id,
      caseId: selectedCase?.id,
      requestId: uuidv4()
    };

    if (socketEmit) {
      socketEmit(
        SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
        payload,
        () => {
          fetchedRef.current = true; // Mark fetch as completed
        },
        (error) => {
          console.error("Failed to fetch trial team:", error.message);
        }
      );
    }
  }
};

/**
 * Kicks out a participant from the case trial team.
 *
 * @param data - Data about the participant to be removed.
 * @param userId - The ID of the user performing the action.
 * @param socketEmit - Function to emit socket events.
 */
export const kickOutParticipant = (
  data: KickOutParticipantData,
  userId: string | undefined,
  socketEmit: <T extends EventData = EventData>(
    eventName: string,
    data: T,
    onSuccess?: (data: AnervaServerResponseProps) => void,
    onFailure?: (data: AnervaServerResponseProps) => void
  ) => void
): void => {
  const caseParticipantId =
    Array.isArray(data.caseParticipants) && data.caseParticipants.length > 0 ? data.caseParticipants[0].id : undefined;

  if (!caseParticipantId) {
    toast.error("Invalid participant data. Unable to kick out.");
    return;
  }

  const payload = {
    caseParticipantId,
    userId,
    type: SOCKET_EVENTS.KICK_OUT_CASE_TRIAL_PARTICIPANT,
    eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
    requestId: uuidv4()
  };

  if (socketEmit) {
    socketEmit(
      SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
      payload,
      ({ success, message }) => {
        if (success) {
          toast.success(message);
        }
      },
      ({ message }) => {
        toast.error(message);
      }
    );
  }
};
