import { ThunkDispatch } from "@reduxjs/toolkit";
import { getProfileImage } from ".";
import { allAccounts, setTotalAccounts } from "../../redux/slices/accountsSlice";
import { allCases, setTotalCases } from "../../redux/slices/casesSlices";
import { allCompanies } from "../../redux/slices/companiesSlices";
import { allCompanyUsers } from "../../redux/slices/companyUsersSlice";
import { setAllConfigurator } from "../../redux/slices/formConfiguratorSlice";
import {
  fetchParticipantsWithCaseCheck,
  setAlternativeJurors,
  setDismissedJurors,
  setJuryBox,
  setTrialTeamParticipants
} from "../../redux/slices/participantsSlice";
import { RootState } from "../../redux/store";
import { SOCKET_EVENTS } from "../constants/socketEvents";
import { AnervaServerResponseProps, AnyAction, Company, Participant, SocketPendingRequestProps } from "../types";

export const handleEventData = (
  dispatch: ThunkDispatch<RootState, unknown, AnyAction> | null = null,
  response: AnervaServerResponseProps
) => {
  if (!response || !dispatch) return;
  const respData = response;
  const { success, eventType, data, type } = respData;
  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_CASE ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_DETAILS ||
      eventType === SOCKET_EVENTS.MANAGE_COURT_DATE) &&
    dispatch
  ) {
    if (data && typeof data === "object" && "cases" in data && Array.isArray(data.cases)) {
      dispatch(allCases(data.cases));
      dispatch(setTotalCases(data?.total || 0));
    }
  }

  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_RATING ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_NOTE ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS ||
      eventType === SOCKET_EVENTS.MANAGE_FIELD_REACTIONS) &&
    (type === SOCKET_EVENTS.FETCH_PARTICIPANT_NON_TRIAL || type === SOCKET_EVENTS.PARTICIPANT_DATA_NON_TRIAL) &&
    dispatch
  ) {
    const participants = data.participants || [];

    dispatch(fetchParticipantsWithCaseCheck(participants));

    // const { caseId: responseCaseId } = data;

    // const candidatesList: Participant[] = [];
    // const expertsList: Participant[] = [];
    // const witnessList: Participant[] = [];
    // if (data && Array.isArray(data?.participants)) {
    //   data?.participants?.map((item: Participant) => {
    //     const participantCaseId = item.caseParticipants?.[0]?.caseId;
    //     if (participantCaseId === responseCaseId) {
    //       if (
    //         item.caseParticipants &&
    //         item.caseParticipants.length > 0 &&
    //         item.caseParticipants[0]?.participantType === MEMBER_TYPES[0]
    //       ) {
    //         witnessList.push(item);
    //       }
    //       if (
    //         item.caseParticipants &&
    //         item.caseParticipants.length > 0 &&
    //         item.caseParticipants[0]?.participantType === MEMBER_TYPES[1]
    //       ) {
    //         expertsList.push(item);
    //       }
    //       if (
    //         item.caseParticipants &&
    //         item.caseParticipants.length > 0 &&
    //         item.caseParticipants[0]?.participantType === MEMBER_TYPES[2]
    //       ) {
    //         candidatesList.push(item);
    //       }
    //     }
    //   });
    // }
    // if (candidatesList.length || expertsList.length || witnessList.length) {
    //   dispatch(fetchCandidates(candidatesList as SelectedParticipantProps[]));
    //   dispatch(fetchExperts(expertsList as SelectedParticipantProps[]));
    //   dispatch(fetchWitnesses(witnessList as SelectedParticipantProps[]));
    // }
  }

  if (
    success === true &&
    (eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT ||
      eventType === SOCKET_EVENTS.MANAGE_PARTICIPANT_DETAILS) &&
    (type === SOCKET_EVENTS.FETCH_PARTICIPANT_TRIAL || type === SOCKET_EVENTS.PARTICIPANT_DATA_TRIAL) &&
    dispatch
  ) {
    const participants = data.participants.map((participant: Participant) => ({
      ...participant,
      firstName: participant?.firstName || "-",
      lastName: participant?.lastName || "-",
      email: participant?.email || "-",
      phoneNumber: participant?.phoneNumber || "-",
      city: participant?.city || "-",
      seatNumber: participant?.caseParticipants?.[0]?.seatNumber
        ? Number(participant.caseParticipants[0].seatNumber)
        : undefined,
      actions: "actions", // Placeholder
      caseParticipants: participant?.caseParticipants ? [participant.caseParticipants[0]] : [],
      jurorId: participant?.caseParticipants?.[0]?.jurorId ?? undefined,
      isDismissed: participant?.caseParticipants?.[0]?.isDismissed ?? false,
      isAlternative: participant?.caseParticipants?.[0]?.isAlternative ?? false,
      image: getProfileImage(participant)
    }));

    const trialTeam = participants.filter((p) => p.seatNumber === undefined && !p.isDismissed && !p.isAlternative);
    const juryBox = participants
      .filter((p) => p.seatNumber !== undefined && !p.isDismissed && !p.isAlternative)
      .sort((a, b) => a.seatNumber! - b.seatNumber!);
    const dismissedJurors = participants.filter((p) => p.isDismissed && !p.isAlternative);
    const alternativeJurors = participants.filter((p) => !p.isDismissed && p.isAlternative);

    dispatch(setTrialTeamParticipants(trialTeam));
    dispatch(setJuryBox(juryBox));
    dispatch(setDismissedJurors(dismissedJurors));
    dispatch(setAlternativeJurors(alternativeJurors));
  }

  if (success === true && eventType === SOCKET_EVENTS.MANAGE_COMPANIES) {
    if (Array.isArray(data)) {
      dispatch(allCompanies(data as Company[]));
    }
  }

  if (
    (success === true && eventType === SOCKET_EVENTS.MANAGE_COMPANY_USERS) ||
    eventType === SOCKET_EVENTS.MANAGE_NOTE ||
    eventType === SOCKET_EVENTS.MANAGE_USER_DETAILS
  ) {
    // Handle company users
    if (Array.isArray(data)) {
      dispatch(allCompanyUsers(data));
    }
  }
  if (success === true && eventType === SOCKET_EVENTS.MANAGE_ACCOUNTING) {
    // Handle company users
    if (Array.isArray(data.accounts)) {
      dispatch(allAccounts(data.accounts));
    }
    dispatch(setTotalAccounts(data?.total || 0));
  }
  if (success === true && eventType === SOCKET_EVENTS.MANAGE_FORM_CONFIGURATOR) {
    // Handle company users
    if (Array.isArray(data.formConfigurator)) {
      dispatch(setAllConfigurator(data.formConfigurator));
    }
  }
};

export function handlePendingRequests(
  pendingRequests: SocketPendingRequestProps[],
  data: AnervaServerResponseProps
): SocketPendingRequestProps[] {
  if (!pendingRequests.length || typeof data !== "object" || data === null) {
    return pendingRequests;
  }

  const { requestId } = data;
  if (!requestId) {
    return pendingRequests;
  }

  const matchingRequestIndex = pendingRequests.findIndex((request) => request.requestId === requestId);

  if (matchingRequestIndex === -1) {
    return pendingRequests;
  }

  const { onSuccess, onFailure } = pendingRequests[matchingRequestIndex];

  if (onSuccess && data.success) {
    onSuccess(data);
  }

  if (onFailure && !data.success) {
    onFailure(data);
  }

  const updatedRequests = [...pendingRequests];
  updatedRequests.splice(matchingRequestIndex, 1);
  return updatedRequests;
}
// export function handlePendingRequests(
//   pendingRequests: SocketPendingRequestProps[],
//   data: AnervaServerResponseProps
// ): SocketPendingRequestProps[] {
//   if (pendingRequests.length === 0 || typeof data !== "object" || data === null) {
//     return pendingRequests;
//   }

//   const responseData = data;
//   if (!responseData.requestId) {
//     return pendingRequests;
//   }

//   const matchingRequestIndex = pendingRequests.findIndex(
//     (request) => request.requestId?.toString() === responseData.requestId?.toString()
//   );
//   if (matchingRequestIndex === -1) {
//     return pendingRequests;
//   }

//   const { onSuccess, onFailure } = pendingRequests[matchingRequestIndex];
//   if (onSuccess && responseData.success) {
//     onSuccess(responseData);
//   }

//   if (onFailure && !responseData.success) {
//     onFailure(responseData);
//   }
//   const requests: SocketPendingRequestProps[] = pendingRequests;
//   requests.splice(matchingRequestIndex, 1);
//   // Create a new array without the handled request
//   return requests;
// }

export function handleErrorResponse(
  pendingRequests: SocketPendingRequestProps[],
  data: AnervaServerResponseProps
): SocketPendingRequestProps[] {
  if (pendingRequests.length === 0 || typeof data !== "object" || data === null) {
    return pendingRequests;
  }

  const responseData = data;
  if (!responseData.requestId || responseData.success) {
    return pendingRequests;
  }

  const matchingRequestIndex = pendingRequests.findIndex(
    (request) => request.requestId?.toString() === responseData.requestId?.toString()
  );

  if (matchingRequestIndex === -1) {
    return pendingRequests;
  }

  const { onFailure } = pendingRequests[matchingRequestIndex];

  if (onFailure) {
    onFailure(responseData);
  }
  const requests: SocketPendingRequestProps[] = pendingRequests;
  requests.splice(matchingRequestIndex, 1);
  return requests;
}
