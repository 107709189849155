import { Dispatch } from "@reduxjs/toolkit";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { AiSearch } from "../../assets/jsxIcons";
import { ActionButton, BreadCrumbs } from "../../components";
import JuryProfileGenerationModal from "../../components/JuryProfileGenerationModal";
import NavigationWrapper from "../../components/NavigationWrapper";
import EditCandidate from "../../components/Pages/ManageCase/EditCandidate";
import { setActiveTab } from "../../redux/slices/manageCaseTabSlice";
import { RootState } from "../../redux/store";
import { AppDispatch } from "../../redux/types";
import { BUTTON_TITLE, CASE_TABS, editTitleMap } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { SOCKET_EVENTS } from "../../utils/constants/socketEvents";
import useSocket from "../../utils/hooks/sockets";

import "./styles.scss";

const EditNewClient: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const pathSegment = location.pathname.split("/").pop();
  const title = editTitleMap[pathSegment as keyof typeof editTitleMap] || "";
  const route = useLocation();
  const personData = route.state;
  const { socket, socketEmit } = useSocket();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();
  const { selectedParticipant } = useSelector((state: RootState) => state.participants);

  const addMemberAsTeam = useCallback(() => {
    const payload = {
      userId: personData?.participantData?.userId?.id,
      type: SOCKET_EVENTS.ADD_CASE_TRIAL_PARTICIPANT,
      eventType: SOCKET_EVENTS.MANAGE_CASE_PARTICIPANT,
      caseParticipantId: personData?.participantData?.caseParticipants[0]?.id,
      requestId: uuidv4()
    };
    if (socket) {
      socketEmit(SOCKET_EVENTS.ANERVA_SERVER_REQUEST, payload, (data) => {
        if (data.requestId === payload.requestId) {
          navigate(routes.MANAGE_CASE);
          dispatch(setActiveTab(CASE_TABS.TRIAL_TEAM));
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personData, socketEmit, socket]);

  return (
    <div className='d-flex flex-column'>
      <BreadCrumbs
        breadcrumbItems={[
          {
            text: "Manage Case",
            url: routes.MANAGE_CASE
          },
          { text: "Case Participants", url: location.pathname }
        ]}
      />
      <NavigationWrapper
        title={`${title} ${personData?.participantData?.firstName || ""} ${personData?.participantData?.lastName || ""}`}
        headerChild={
          <div className='header-child-container'>
            <ActionButton
              className={"bg-primary-light"}
              onClick={addMemberAsTeam}
              title={BUTTON_TITLE.ADD_AS_A_TEAM}
              icon={<i className='fa-regular fa-user-plus'></i>}
            />
            <ActionButton
              className={"bg-blue-light"}
              onClick={() => {
                setShowModal(true);
              }}
              title={BUTTON_TITLE.AI_PROFILE_GENERATE}
              icon={<AiSearch className='fill-white h-px-20 w-px-20' />}
            />
          </div>
        }
      >
        <div className='scrollable-container'>
          <EditCandidate data={{ personData: selectedParticipant || personData }} />
        </div>
        <JuryProfileGenerationModal showModal={showModal} onClose={() => setShowModal(false)} size='md' />
      </NavigationWrapper>
    </div>
  );
};

export default EditNewClient;
