import { Person1, Person2 } from "../../assets/images";
import { ConfigureTabProps, Juror, NavigationTab } from "../types";

export const dashboardTabButtons: ConfigureTabProps[] = [
  { id: "1", label: "Upcoming Cases", value: "upcoming", content: [] },
  { id: "2", label: "Pending", value: "pending", content: [] },
  { id: "3", label: "Completed", value: "completed", content: [] },
  { id: "4", label: "Archived", value: "archived", content: [] }
];

export const NEW_CLIENT_TABS: ConfigureTabProps[] = [
  { id: "1", content: [], label: "Basic", value: "basic" },
  { id: "2", content: [], label: "Demographics", value: "demographics" },
  { id: "3", content: [], label: "Legal & other Records", value: "legal" },
  { id: "4", content: [], label: "Social Media & Online Presence", value: "socialMedia" },
  {
    value: "personalPreferences",
    label: "Personal Preferences",
    id: "5",
    content: []
  },
  {
    value: "politicalAndSocial",
    label: "Political & Social",
    id: "6",
    content: []
  },
  {
    value: "financial",
    label: "Financial",
    id: "7",
    content: []
  },
  {
    value: "communicationPreferences",
    label: "Communication Preferences",
    id: "8",
    content: []
  }
];

export const trialTeamTabButtons: ConfigureTabProps[] = [
  { id: "1", label: "Trial Team", value: "trialTeam", content: [] },
  { id: "2", label: "Jury Box", value: "juryBox", content: [] }
  // { id: 3, label: "Trial Team Statistics", value: "trialTeamStatistics" }
];

export const manageCaseTabButtons: ConfigureTabProps[] = [
  { id: "1", label: "Details", value: "caseDetails", content: [] },
  { id: "2", label: "Court Dates", value: "courtDates", content: [] }
];

export const providerTabs: ConfigureTabProps[] = [
  { id: "1", label: "Participants", value: "participants", content: [] },
  { id: "2", label: "Cases", value: "cases", content: [] }
];

export const dashboardCards = [
  {
    id: 1,
    title: "Open Cases",
    count: 1,
    iconClassName: "fa-regular fa-briefcase dashboard-icon color-primary-light",
    className: "color-primary-light"
  },
  {
    id: 2,
    title: "Open Tasks",
    count: 0,
    iconClassName: "fa-regular fa-thumbtack dashboard-icon color-blue-light",
    className: "color-blue-light"
  },
  {
    id: 3,
    title: "Daily Tasks",
    count: 0,
    iconClassName: "fa-regular fa-list-check dashboard-icon color-yellow",
    className: "color-yellow"
  },
  {
    id: 4,
    title: "Unread Notes",
    count: 0,
    iconClassName: "fa-regular fa-calendar-lines dashboard-icon color-pink",
    className: "color-pink"
  }
];

export const NavigationTabs: NavigationTab[] = [
  { id: "caseDetails", label: "Case Details", status: "completed", onClick: () => {} },
  { id: "participants", label: "Participants", status: "current", onClick: () => {} },
  { id: "trialTeam", label: "Trial Team", status: "upcoming", onClick: () => {} },
  { id: "caseEvidence", label: "Evidence Documents", status: "upcoming", onClick: () => {} },
  { id: "documents", label: "Court Documents", status: "upcoming", onClick: () => {} }
];

export const caseDetailsTabs: NavigationTab[] = [
  { id: "caseDetails", label: "Case Details", status: "completed", onClick: () => {} },
  { id: "courtDates", label: "Court Details", status: "current", onClick: () => {} }
];

export const calendarSummaryCards = [
  { id: 1, title: "Daily Tasks", count: 2, icon: "fa-solid fa-list-check", className: "color-green" },
  { id: 2, title: "Open Tasks", count: 3, icon: "fa-regular fa-thumbtack", className: "color-blue-light" },
  { id: 3, title: "Urgent Tasks", count: 5, icon: "fa-regular fa-briefcase", className: "color-yellow" },
  { id: 4, title: "Overdue Tasks", count: 1, icon: "fa-regular fa-calendar-lines", className: "color-pink" }
];

export const dummyEventsData = [
  {
    title: "Event 1",
    caseName: "Case 1",
    start: "2024-08-20T13:00:00",
    end: "2024-08-20T14:00:00",
    date: "2024-08-20"
  },
  { title: "Event 2", date: "2024-08-16" }
];

export const createEventFields = [
  { label: "Task Title", name: "title", placeholder: "Enter task title" },
  { label: "Case Name", name: "caseName", placeholder: "Enter case name" },
  { label: "Date", name: "date", placeholder: "Enter date", type: "date" }
];

export const trailStatisticsCards = [
  { id: 1, title: "Highly Satisfied", count: 0, icon: "fa-regular fa-face-smile", className: "color-primary-light" },
  { id: 2, title: "Lowly Satisfied", count: 0, icon: "fa-regular fa-face-meh", className: "color-orange" },
  { id: 3, title: "Jurors Dismissed", count: 0, icon: "fa-regular fa-xmark", className: "color-red" },
  {
    id: 4,
    title: "Alternate Jurors",
    count: 0,
    icon: "fa-regular fa-calendar-lines dashbaord-icon",
    className: "color-blue-light"
  }
];

export const donutChartData = [
  {
    label: "Label 1",
    value: 55,
    color: "color-primary-light"
  },
  {
    label: "Label 2",
    value: 15,
    color: "color-yellow"
  },
  {
    label: "Label 3",
    value: 80,
    color: "color-pink"
  }
];

export const barChartData = [
  { label: "January", value: 20, color: "rgba(75,192,192,1)" },
  { label: "February", value: 30, color: "rgba(153,102,255,1)" },
  { label: "March", value: 10, color: "rgba(255,159,64,1)" },
  { label: "March", value: 5, color: "rgba(255,159,64,1)" }
];

export const navCards = [
  {
    id: 1,
    title: "Personal Data",
    iconClass: "fa-regular fa-address-card color-primary-light",
    className: "border-left-primary-light"
  },
  {
    id: 2,
    title: "Juror Evaluation",
    iconClass: "fa-regular fa-hat-cowboy color-pink",
    className: "border-left-pink"
  },
  {
    id: 3,
    title: "Juror Profile",
    iconClass: "",
    className: "border-left-blue-light"
  }
];

export const personalDataPageTabs = [
  {
    value: "Basic",
    label: "Basic",
    id: "1",
    content: []
  },
  {
    value: "Demographics",
    label: "Demographics",
    id: "2",
    content: []
  },
  {
    value: "Legal Records",
    label: "Legal Records",
    id: "3",
    content: []
  },
  {
    value: "SM & Online Presence",
    label: "SM & Online Presence",
    id: "4",
    content: []
  },
  {
    value: "Personal Preferences",
    label: "Personal Preferences",
    id: "5",
    content: []
  },
  {
    value: "Political & Social",
    label: "Political & Social",
    id: "6",
    content: []
  },
  {
    value: "Financial",
    label: "Financial",
    id: "7",
    content: []
  },
  {
    value: "Communication Preferences",
    label: "Communication Preferences",
    id: "8",
    content: []
  }
];

export const jurorData: Juror[] = [
  {
    index: 1,
    name: "Emily Johnson",
    id: 8596,
    notes: 3,
    image: Person1,
    icon: ["fa-light fa-face-smile"]
  },
  {
    index: 2,
    name: "John Doe",
    id: 8597,
    notes: 3,
    image: Person2,
    icon: ["fa-light fa-face-smile"]
  },
  {
    index: 3,
    name: "Emily Johnson",
    id: 8598,
    notes: 3,
    image: Person1,
    icon: ["fa-light fa-face-smile", "fa-light fa-face-smile"]
  },
  {
    index: 4,
    name: "John Doe",
    id: 8599,
    notes: 3,
    image: Person2,
    icon: ["fa-light fa-face-smile"]
  }
];

export const dismissedJurorData: Juror[] = [
  {
    index: 1,
    name: "Emily Johnson",
    id: 8596,
    notes: 3,
    image: Person1,
    icon: ["fa-light fa-face-frown-slight", "fa-solid fa-plus"]
  },
  {
    index: 2,
    name: "John Doe",
    id: 8597,
    notes: 3,
    image: Person2,
    icon: ["fa-light fa-face-frown-slight", "fa-solid fa-plus"]
  },
  {
    index: 3,
    name: "Emily Johnson",
    id: 8598,
    notes: 3,
    image: Person1,
    icon: ["fa-light fa-face-frown-slight", "fa-solid fa-plus"]
  },
  {
    index: 4,
    name: "John Doe",
    id: 8599,
    notes: 3,
    image: Person2,
    icon: ["fa-light fa-face-frown-slight", "fa-solid fa-plus"]
  }
];

export const jurorEvaluationTabsData: ConfigureTabProps[] = [
  { id: "1", label: "Basic Indicators", value: "basicIndicators", content: [] },
  { id: "2", label: "Body Language", value: "bodyLanguage", content: [] },
  { id: "3", label: "Emotional Intelligence", value: "emotionalIntelligence", content: [] },
  { id: "4", label: "Communication Skills", value: "communicationSkills", content: [] }
];

export const jurorProfileTabsData: ConfigureTabProps[] = [
  { id: "1", label: "Strengths", value: "strengths", content: [] },
  { id: "2", label: "Weakness", value: "weakness", content: [] },
  { id: "3", label: "Potential Biases", value: "potentialBiases", content: [] }
];
