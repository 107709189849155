import { Form, useFormikContext } from "formik";
import React, { useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { mapSelectedCaseToFormValues, updateCaseDetails } from "./helper";
import { RootState } from "../../../../redux/store";
import {
  BUTTON_TITLE,
  CaseStatuses,
  FORM_LABELS,
  FORM_NAMES,
  FORM_PLACEHOLDERS,
  STRINGS
} from "../../../../utils/constants";
import useSocket from "../../../../utils/hooks/sockets";
import { CreateNewCaseProps, FieldData, NewCaseFormValues, SocketEmitFunction } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import SelectComponent from "../../../SelectComponent";
import TextInput from "../../../TextInput";

const NewCaseForm: React.FC<CreateNewCaseProps> = ({ caseData }) => {
  const { socketEmit } = useSocket();
  const { values, errors, setFieldValue, handleChange, handleSubmit, resetForm, setValues, isValid } =
    useFormikContext<NewCaseFormValues>();
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const { user, companyInfo } = useSelector((state: RootState) => state.users);

  const navigate: NavigateFunction = useNavigate();

  useEffect(() => {
    if (selectedCase) {
      const newFormValues = mapSelectedCaseToFormValues(selectedCase, values);
      setValues(newFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCase, setValues]);

  const handleSaveClick = useCallback(() => {
    if (!isValid) {
      toast.error(STRINGS.FILL_REQUIRED_FIELDS);
      console.log({ errors });
      return;
    }

    // Extract `details` fields from `values`
    const detailFields =
      (selectedCase?.caseDetails && selectedCase?.caseDetails[0]?.details?.map((detail: FieldData) => detail.field)) ||
      [];

    // Create a new object with only non-detail fields
    const filteredValues = Object.keys(values)
      .filter((key) => ![...detailFields, "courtDates", "caseDocuments", "userId"].includes(key)) // Exclude detail fields
      .reduce(
        (acc, key) => {
          acc[key] = values[key];
          return acc;
        },
        {} as Record<string, string>
      );
    // Map the `details` fields back with updated values
    const updatedDetails =
      selectedCase?.caseDetails &&
      selectedCase?.caseDetails[0]?.details?.map((detail: FieldData) => ({
        ...detail,
        value: values[detail?.field] || "" // Updated dynamic values
      }));
    // Create the final updated case data
    const updatedCaseData = {
      ...((selectedCase?.caseDetails && selectedCase?.caseDetails[0]) || []),
      ...filteredValues,
      details: updatedDetails
    };
    // Uncomment this to send the data
    updateCaseDetails(
      socketEmit as SocketEmitFunction,
      updatedCaseData,
      user?.id || "",
      caseData?.id,
      companyInfo?._id
    );
    handleSubmit();
  }, [
    isValid,
    selectedCase?.caseDetails,
    values,
    socketEmit,
    user?.id,
    caseData?.id,
    companyInfo?._id,
    handleSubmit,
    errors
  ]);

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CASE_NAME}
          name={FORM_NAMES.CASE_NAME}
          errorMessage={errors?.caseName}
          value={values?.caseName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.COURT_CASE_NUMBER}
          name={FORM_NAMES.COURT_CASE_NUMBER}
          errorMessage={errors?.courtCaseNumber}
          value={values?.courtCaseNumber}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.CLIENT_NAME}
          name={FORM_NAMES.CLIENT_NAME}
          errorMessage={errors?.clientName}
          value={values?.clientName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <SelectComponent
          label={FORM_LABELS.CASE_STATUS}
          name={FORM_NAMES.CASE_STATUS}
          errorMessage={errors?.caseStatus}
          items={CaseStatuses}
          value={values?.caseStatus}
          onChange={(e) => setFieldValue(FORM_NAMES.CASE_STATUS, e.target.value)}
        />
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.COURT_JURISDICTION}
          name={FORM_NAMES.COURT_JURISDICTION}
          errorMessage={errors?.courtJurisdiction}
          value={values?.courtJurisdiction}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.CASE_TYPE}
          name={FORM_NAMES.CASE_TYPE}
          errorMessage={errors?.caseType}
          value={values?.caseType}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <TextInput
          label={FORM_LABELS.DEFENDANT_NAME}
          name={FORM_NAMES.DEFENDANT_NAME}
          errorMessage={errors?.defendantName}
          value={values?.defendantName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
        <TextInput
          label={FORM_LABELS.OPPOSING_COUNSEL_NAME}
          name={FORM_NAMES.OPPOSING_COUNSELS_NAME}
          errorMessage={errors?.opposingCounselsName}
          value={values?.opposingCounselsName}
          placeholder={FORM_PLACEHOLDERS.DEFAULT}
          onChange={handleChange}
        />
      </div>

      {selectedCase?.caseDetails &&
        selectedCase?.caseDetails[0]?.details?.map((detail: FieldData, index: number) => {
          // Check if the current index is even, to ensure the first input in a pair is rendered
          if (index % 2 === 0) {
            return (
              <div key={detail.field} className='d-flex flex-row gap-4 sm-flex-wrap'>
                <TextInput
                  label={detail.label}
                  name={detail.field}
                  errorMessage={errors?.[detail?.field]}
                  value={values?.[detail.field]}
                  placeholder={FORM_PLACEHOLDERS.DEFAULT}
                  onChange={handleChange}
                />
                {/* Only render the next input field if it exists */}
                {selectedCase?.caseDetails && selectedCase?.caseDetails[0]?.details[index + 1] && (
                  <TextInput
                    label={selectedCase?.caseDetails[0]?.details[index + 1].label}
                    name={selectedCase?.caseDetails[0]?.details[index + 1].field}
                    errorMessage={
                      errors?.[selectedCase?.caseDetails && selectedCase?.caseDetails[0]?.details[index + 1].field]
                    }
                    value={
                      values?.[selectedCase?.caseDetails && selectedCase?.caseDetails[0]?.details[index + 1].field]
                    }
                    placeholder={FORM_PLACEHOLDERS.DEFAULT}
                    onChange={handleChange}
                  />
                )}
              </div>
            );
          }
          return null; // Skip rendering for odd indices
        })}

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <ActionButton title={BUTTON_TITLE.SAVE} className='save-button' onClick={handleSaveClick} />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>
    </Form>
  );
};

export default NewCaseForm;
