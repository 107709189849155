// src/components/CustomizableTabs.tsx
import { Dispatch } from "@reduxjs/toolkit";
import { Formik, FormikValues } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Location, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../redux/types";
import { BUTTON_TITLE, STRINGS } from "../../utils/constants";
import { handleNavigation } from "../../utils/helpers";
import { ConfigureTabProps, CustomizableTabsProps, FormField } from "../../utils/types";
import ActionButton from "../ActionButton";
import { AddFieldValueModal } from "../AddFieldValueModal";
import ConfirmationModal from "../ConfirmationModal";
import CustomizableTabSection from "../CustomizableTabSection";
import FormConfigurator from "../FormConfigurator";
import InfoCard from "../InfoCard";
import Notepad from "../Notepad";
import TabButtons from "../TabButtons";
import "./styles.CustomizableTabs.scss";

const CustomizableTabs: React.FC<CustomizableTabsProps> = ({
  tabs,
  activeTab,
  isConfigureMode,
  inputFieldsData,
  onTabChange,
  addNewTab,
  handleFieldChange,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  moveField,
  initialValues,
  validationSchema,
  onFormSubmit,
  handleSaveNote,
  enableNotepad,
  notesHistory,
  onFormValuesChange,
  setInputFieldsData,
  profileImage,
  handleReactions,
  resetTabValues,
  handleImage,
  onAddField,
  isReacted
}) => {
  const [isConfirmationModal, setIsConfirmationModal] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [formikValues, setFormikValues] = useState<FormikValues>({});
  const [initialFormValuesSet, setInitialFormValuesSet] = useState(false);
  const location: Location = useLocation();
  const navigate: NavigateFunction = useNavigate();
  const dispatch: Dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (!initialFormValuesSet && inputFieldsData.length > 0) {
      let initialFormValues = {};
      (inputFieldsData as FormField[]).forEach((item) => {
        initialFormValues = { ...initialFormValues, [item.name]: item.value };
      });
      setFormikValues(initialFormValues);
      setInitialFormValuesSet(true);
    }
  }, [inputFieldsData, initialFormValuesSet]);

  const toggleModal = useCallback(() => {
    setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
  }, []);

  const handleAddNewTab = useCallback(() => {
    toggleModal();
  }, [toggleModal]);

  const handleNewTabLabel = useCallback(
    (label: string) => {
      addNewTab(label);
      toggleModal();
    },
    [addNewTab, toggleModal]
  );

  const handleCancel = useCallback(
    (resetForm: () => void) => {
      if (resetTabValues) {
        resetTabValues();
      }
      resetForm();
      setIsConfirmationModal(false);
      const from = location.state?.from;
      if (from) {
        handleNavigation(from, navigate, dispatch, false, location.pathname || "");
      }
    },
    [dispatch, location, navigate, resetTabValues]
  );

  return (
    <div className='w-100 '>
      <div className='customizeTab-d-flex w-100 flex-row align-items-center justify-content-between'>
        <div className='tab-buttons-outer-container'>
          <TabButtons
            tabs={tabs as ConfigureTabProps[]}
            activeTab={activeTab as ConfigureTabProps}
            onTabChange={onTabChange}
          />
        </div>
        {isConfigureMode && <ActionButton onClick={handleAddNewTab} title='+ Add Section' disabled={false} />}
      </div>
      {tabs?.length > 0 ? (
        <div className='customizeTab-d-flex flex-row py-3'>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              console.log("Form Submitted:", values);
              resetForm();
            }}
          >
            {({ resetForm }) => (
              <div className={"w-100 scrollable-container"}>
                {tabs.map(
                  (tab, index: number) =>
                    activeTab?.value === tab.value && (
                      <CustomizableTabSection
                        key={`${tab.id}_${index}_${tab.label}`}
                        isConfigureMode={isConfigureMode}
                        handleFieldChange={handleFieldChange}
                        tab={tab}
                        onMoveDown={onMoveDown}
                        onMoveUp={onMoveUp}
                        moveField={moveField}
                        handleRemoveField={handleRemoveField}
                        initialValues={initialValues}
                        onFormValuesChange={onFormValuesChange}
                        validationSchema={validationSchema}
                        formConfigurator={
                          isConfigureMode && (
                            <FormConfigurator
                              moveField={moveField}
                              inputFields={inputFieldsData}
                              setInputFields={setInputFieldsData} // Pass setInputFieldsData
                              onAddField={(field: Partial<FormField>) => onAddField?.(tab, field)}
                            />
                          )
                        }
                        profileImage={profileImage}
                        handleReactions={handleReactions}
                        handleImage={handleImage}
                        isReacted={isReacted}
                      />
                    )
                )}
                <div className='d-flex flex-row gap-4 sm-flex-wrap py-3'>
                  <ActionButton
                    title={isConfigureMode ? BUTTON_TITLE.SAVE_PAGE_CONFIGURATION : BUTTON_TITLE.SAVE}
                    className='bg-primary-light'
                    onClick={() => {
                      onFormSubmit(tabs, formikValues); // Use Formik's values here
                      resetForm();
                      if (resetTabValues) {
                        resetTabValues();
                      }
                    }}
                  />
                  <ActionButton
                    title={BUTTON_TITLE.CANCEL}
                    className='bg-red'
                    onClick={() => {
                      setIsConfirmationModal(true);
                    }}
                  />
                </div>
                {isConfirmationModal && (
                  <ConfirmationModal
                    onCancel={() => setIsConfirmationModal(false)}
                    message={STRINGS.ARE_YOUR_SURE_CANCEL}
                    onConfirm={() => handleCancel(resetForm)}
                  />
                )}
              </div>
            )}
          </Formik>

          {!isConfigureMode && enableNotepad && (
            <div className='col-4 ms-2'>
              <Notepad id='1234' title='Notepad' handleSaveNote={handleSaveNote} notesHistory={notesHistory || []} />
            </div>
          )}
        </div>
      ) : (
        <InfoCard />
      )}
      {isModalOpen && (
        <AddFieldValueModal
          fieldLabel='Add Section'
          fieldPlaceholder='Section Name'
          isOpen={isModalOpen}
          toggle={toggleModal}
          onSubmit={handleNewTabLabel}
        />
      )}
    </div>
  );
};

export default CustomizableTabs;
