import { useDrop } from "react-dnd";
import { CustomizableForm } from "./CustomizableForm";
import { ConfigureTabProps, CustomizableTabSectionProps, FormField } from "../../utils/types";
import WrapperComponent from "../WrapperComponent";

const CustomizableTabSection = ({
  tab,
  handleFieldChange,
  isConfigureMode,
  onMoveDown,
  onMoveUp,
  handleRemoveField,
  formConfigurator,
  moveField,
  onFormValuesChange,
  profileImage,
  handleReactions,
  handleImage,
  isReacted
}: CustomizableTabSectionProps) => {
  const [, drop] = useDrop({
    accept: "Field",
    drop: (item: FormField) => {
      handleFieldDrop(item);
    },
    canDrop: () => isConfigureMode || true
  });

  const handleFieldDrop = (field: FormField) => {
    if (Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)) {
      console.log(
        "Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name) : ",
        Array.isArray(tab.content) && tab.content.some((existingField) => existingField.name === field.name)
      );
    } else {
      const updatedTab: ConfigureTabProps = {
        ...tab,
        content: Array.isArray(tab.content) ? [...tab.content, field] : [field]
      };
      handleFieldChange(updatedTab);
    }
  };

  return (
    <div className='h-100 w-100 d-flex user-select-none'>
      <WrapperComponent key={`${tab.value}_${tab.label}`} title={tab.label}>
        <div className='w-100 d-flex flex-row'>
          <div ref={drop} className='w-100 row'>
            <CustomizableForm
              isConfigureMode={isConfigureMode}
              tab={tab}
              moveField={moveField}
              handleRemoveField={handleRemoveField}
              onFormValuesChange={onFormValuesChange}
              onMoveDown={onMoveDown}
              onMoveUp={onMoveUp}
              profileImage={profileImage}
              handleReactions={handleReactions}
              handleImage={handleImage || (() => {})}
              isReacted={isReacted}
            />
          </div>
          {formConfigurator}
        </div>
      </WrapperComponent>
    </div>
  );
};

export default CustomizableTabSection;
