import { Form, useFormikContext } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { fetchDateValue } from "./helper";
import { RootState } from "../../../../redux/store";
import { BUTTON_TITLE, FORM_LABELS, FORM_NAMES, FORM_PLACEHOLDERS } from "../../../../utils/constants";
import { SOCKET_EVENTS } from "../../../../utils/constants/socketEvents";
import { toCamelCase } from "../../../../utils/helpers";
import useSocket from "../../../../utils/hooks/sockets";
import { CreateNewCaseProps, dateField, NewCaseFormValues } from "../../../../utils/types";
import { ActionButton } from "../../../ActionButton";
import { AddFieldValueModal } from "../../../AddFieldValueModal";
import AddMore from "../../../AddMoreButton";
import DateInput from "../../../DateInput";

import "./styles.scss";

const CourtDates: React.FC<CreateNewCaseProps> = ({ caseData }) => {
  const [dateFields, setDateFields] = useState<dateField[]>([]);
  const { socketEmit } = useSocket();
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const user = useSelector((state: RootState) => state.users.user);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { values, setValues, errors, setFieldValue, handleSubmit, resetForm } = useFormikContext<NewCaseFormValues>();
  const navigate: NavigateFunction = useNavigate();

  const toggleModal = useCallback(() => setIsModalOpen((prev) => !prev), []);
  const handleAddSocialMedia = useCallback(
    (label: string) => {
      const fieldName = toCamelCase(label);

      if (
        dateFields.some((field) => field.name === fieldName) ||
        [FORM_NAMES.TRIAL_START_DATE, FORM_NAMES.FILING_DATE, FORM_NAMES.DISCOVERY_DEADLINE].includes(fieldName)
      ) {
        toast.error(`${label} already exists`);
        toggleModal();
        return;
      }

      const newField: dateField = {
        name: fieldName,
        label,
        type: "date"
      };

      setDateFields((prev) => [...prev, newField]);
      toggleModal();
    },
    [toggleModal, dateFields]
  );

  // const handleRemoveField = useCallback(
  //   (name: string) => {
  //     setDateFields((prev) => prev.filter((field) => field.name !== name));
  //     setFieldValue(name, "");
  //   },
  //   [setFieldValue]
  // );

  useEffect(() => {
    const courtDate =
      (selectedCase?.courtDates && selectedCase?.courtDates?.length > 0 && selectedCase?.courtDates?.[0]) ?? null;
    if (courtDate) {
      setValues((prevValues) => ({
        ...prevValues,
        trialStartDate: courtDate.trialStartDate ? new Date(courtDate.trialStartDate) : null,
        filingDate: courtDate.filingDate ? new Date(courtDate.filingDate) : null,
        discoveryDeadline: courtDate.discoveryDeadline ? new Date(courtDate.discoveryDeadline) : null,
        ...(courtDate.customDates
          ? Object.fromEntries(courtDate.customDates.map((field: dateField) => [field.name, field.value]))
          : {})
      }));

      setDateFields(courtDate.customDates ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCase]);

  const updateCourtDates = (
    caseId: string | undefined,
    userId: string,
    type: string,
    trialStartDate: Date | null,
    filingDate: Date | null,
    discoveryDeadline: Date | null,
    customFields: dateField[]
  ) => {
    const fields = customFields.map((field) => ({
      ...field,
      value: values[field.name as keyof NewCaseFormValues]
    }));
    const courtDates = {
      caseId,
      userId,
      type,
      trialStartDate,
      filingDate,
      discoveryDeadline,
      customDates: fields,
      eventType: SOCKET_EVENTS.MANAGE_COURT_DATE,
      requestId: uuidv4()
    };
    try {
      if (socketEmit) {
        socketEmit(
          SOCKET_EVENTS.ANERVA_SERVER_REQUEST,
          courtDates,
          ({ success, message }) => {
            if (success == true) toast.success(message);
          },
          ({ success, message }) => {
            if (success === false) toast.error(message);
          }
        );
      }
    } catch (error) {
      if (error instanceof Error) console.log("updateCourtDate error: ", error?.message);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.TRIAL_START_DATE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.trialStartDate}
            parentClassName='w-50'
            value={values?.trialStartDate}
            onDateChange={(date) => setFieldValue(FORM_NAMES.TRIAL_START_DATE, date)}
            // minDate={new Date()}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.FILING_DATE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.filingDate}
            parentClassName='w-50'
            value={values?.filingDate}
            onDateChange={(date) => setFieldValue(FORM_NAMES.FILING_DATE, date)}
            // minDate={new Date()}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='w-100'>
          <DateInput
            label={FORM_LABELS.DISCOVERY_DEADLINE}
            placeholder={FORM_PLACEHOLDERS.DATE}
            errorMessage={errors?.discoveryDeadline}
            parentClassName='w-50'
            value={values?.discoveryDeadline}
            onDateChange={(date) => setFieldValue(FORM_NAMES.DISCOVERY_DEADLINE, date)}
            // minDate={new Date()}
          />
        </div>
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap'>
        <div className='d-flex flex-column w-100 '>
          {dateFields.map(({ name, label }: dateField) => (
            <div key={name} className='w-100 d-flex flex-row align-items-center'>
              <DateInput
                label={label}
                placeholder={FORM_PLACEHOLDERS.DATE}
                errorMessage={(errors[name as keyof typeof errors] as string) || undefined}
                parentClassName='w-50'
                value={fetchDateValue(values as unknown as Record<string, unknown>, name)}
                onDateChange={(date) => setFieldValue(name, date)}
                // minDate={new Date()}
              />
              {/* {!rest?.isPacer && (
                <div className='trash-container' onClick={() => handleRemoveField(name)}>
                  <i className='fa-regular fa-trash'></i>
                </div>
              )} */}
            </div>
          ))}
        </div>
      </div>
      <div className='d-flex flex-row gap-4 sm-flex-wrap py-2 justify-content-start'>
        <AddMore title={BUTTON_TITLE.ADD_MORE} onClick={toggleModal} />
      </div>

      <div className='d-flex flex-row gap-4 sm-flex-wrap pt-2'>
        <ActionButton
          title={BUTTON_TITLE.SAVE}
          className='save-button'
          onClick={() => {
            updateCourtDates(
              caseData?.id,
              user?.id || "",
              SOCKET_EVENTS.UPDATE_COURT_DATE,
              values?.trialStartDate || null,
              values?.filingDate || null,
              values?.discoveryDeadline || null,
              dateFields
            );
          }}
        />
        <ActionButton
          title={BUTTON_TITLE.CANCEL}
          onClick={() => {
            navigate(-1);
            resetForm();
          }}
          className='cancel-button'
        />
      </div>

      <AddFieldValueModal
        fieldLabel='Add Date Field'
        fieldPlaceholder='Date Field Name'
        isOpen={isModalOpen}
        toggle={toggleModal}
        onSubmit={handleAddSocialMedia}
      />
    </Form>
  );
};

export default CourtDates;
