import { useSelector } from "react-redux";
import { BreadCrumbs } from "../../components";
import DetailView from "../../components/DetailView";
import NavigationWrapper from "../../components/NavigationWrapper";
import WrapperComponent from "../../components/WrapperComponent";
import { RootState } from "../../redux/types";
import { TITLES } from "../../utils/constants";
import routes from "../../utils/constants/routes";
import { transformCaseDetails } from "../../utils/helpers";
import { TabDataField } from "../../utils/types";

const CaseDetails = () => {
  const { selectedCase } = useSelector((state: RootState) => state.cases);
  const caseDetails = transformCaseDetails(selectedCase?.caseDetails?.[0]?.details as TabDataField[]);
  return (
    <div className='d-flex flex-column w-100'>
      <BreadCrumbs
        breadcrumbItems={[
          { text: "Cases", url: routes.CASES_LIST },
          { text: "Case Details", url: location.pathname }
        ]}
      />
      <NavigationWrapper title={`${TITLES.VIEW_CASE_DETAILS} ${selectedCase?.caseName || ""}`} goBack={true}>
        <WrapperComponent>
          <DetailView data={caseDetails} />
        </WrapperComponent>
      </NavigationWrapper>
    </div>
  );
};

export default CaseDetails;
